.statehistories-history {
    margin-top: 20px;
}

.statehistories-history-entry {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
    padding: 10px 0;
}

.statehistories-history-entry:after {
    position: absolute;
    left: 19px;
    bottom: -30px;
    content: '';
    height: 30px;
    width: 1px;
    background-color: var(--color-gray);
}

.statehistories-history-entry:last-child {
    margin-bottom: 0;
}

.statehistories-history-entry:last-child:after {
    height: 0;
}

.statehistories-history-entry-initials {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-background_light);
    border-radius: 6px;
    color: var(--color-text_secondary);
    font-weight: bold;
    margin-right: 10px;
}

.statehistories-history-entry-icon {
    width: 20px;
    height: 20px;
    background-color: var(--color-background_light);
    border-radius: 6px;
    padding: 10px;
    margin-right: 10px;
}

.statehistories-history-entry-right {
}

.statehistories-history-entry-state {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
}

.statehistories-history-entry-state-badge {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 6px;
}

.statehistories-history-entry-state-text {
    font-size: 14px;
    font-weight: bold;
}

.statehistories-history-entry-meta {
    display: flex;
    font-size: 14px;
    color: var(--color-text_secondary);
    margin-top: 4px;
}

.statehistories-history-entry-meta-date {
    margin-left: 4px;
}

.statehistories-popup-title {
    font-weight: bold;
}

.statehistories-popup-dropdown {
    margin-top: 20px;
    margin-bottom: 20px;
}

.statehistories-popup-actions {
    border-top: 1px solid var(--color-border);
    padding-top: 20px;
    margin-left: -20px;
    margin-right: -20px;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
}
