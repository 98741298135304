.toggleswitch {

}

.toggleswitch__disabled {
    opacity: 0.5;
}

.toggleswitch-inner {
    display: flex;
    align-items: center;
}

.toggleswitch-inner-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.toggleswitch-label {
    margin-left: 10px;
}

.toggleswitch-label {
    color: var(--color-text_secondary)
}

.toggleswitch-label-small {
    font-size: 14px;
}

.toggleswitch-switch {
    border-radius: 100px;
    width: 40px;
    padding: 4px;
    height: 18px;
}

.toggleswitch-switch-small {
    height: 12px;
    width: 30px;
}

.toggleswitch-switch:hover {
    cursor: pointer;
    transition: background-color 0.3s;
}

.toggleswitch__disabled .toggleswitch-switch:hover {
    cursor: not-allowed;
}

.toggleswitch-switch-on {
    background-color: var(--color-save);
}

.toggleswitch-switch-off {
    background-color: var(--color-gray);
}

.toggleswitch-slider {
    position: relative;
    width: 100%;
    height: 18px;
}

.toggleswitch-switch-lever {
    position: absolute;
    top: 0;
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 100%;
    transition: left 0.3s, background-color 0.3s;
}

.toggleswitch-switch-lever-small {
    width: 12px;
    height: 12px;
}

.toggleswitch-switch-lever-on {
    left: calc(100% - 18px);
}

.toggleswitch-switch-lever-small.toggleswitch-switch-lever-on {
    left: calc(100% - 12px);
}

.toggleswitch-switch-lever-off {
    left: 0;
}

.toggleswitch-switch-lever-loading {
    background-color: transparent;
    border: 3px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
