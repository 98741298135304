.richtext {

}

.richtext-editor {
  margin-top: 20px;
}

.richtext-actions {
  margin-top: 20px;
}