.productsearch {
}

.productsearch-results {
    position: absolute;
    border-radius: 6px;
    background-color: var(--color-foreground);
    box-shadow: var(--shadow-wide);
    border: 1px solid var(--color-border);
    z-index: 999;
}

.productsearch-result {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--color-border);
    padding: 10px;
}

.productsearch-result:hover {
    background-color: var(--color-background_light);
    cursor: pointer;
}

.productsearch-result-cover {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: 1px solid var(--color-border);
    margin-right: 10px;
    background-color: var(--color-foreground);
}

.productsearch-result-cover-placeholder {
    width: 40px;
    height: 40px;
}

.productsearch-result-title {
    font-weight: bold;
}

.productsearch-result-ean {
    margin-left: 10px;
    background-color: var(--color-background_light);
    padding: 2px 4px;
    font-family: 'Inconsolata', monospace;
    color: var(--color-text_secondary);
    border-radius: 4px;
}

.productsearch-result-information {
    margin-left: 10px;
}

.productsearch-result-displayText {
    background-color: inherit;
}

.productsearch-result-displayText-normal {
    white-space: pre;
    display: inline;
}

.productsearch-result-displayText-highlighted {
    white-space: pre;
    background-color: var(--color-textHighlight);
    display: inline;
    border-radius: 2px;
}

.productsearch-note {
    margin-bottom: 10px;
    color: var(--color-text_secondary);
}
