.breadcrumbs {
  display: flex;
  font-size: 14px;
  color: var(--color-gray);
}

.breadcrumb {
  margin-right: 6px;
}

.breadcrumb:hover {
  cursor: pointer;
}

.breadcrumbs-spacer {
  margin-right: 6px;
}
