.properties {
    position: relative;
    margin-left: -20px;
    margin-right: -20px;
}

.properties-actions {
    padding: 20px 20px 0;
    border-top: 1px solid var(--color-border);
}

.properties-title {
    padding: 0 20px;
}

.properties-inherited .properties-property:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--color-inherited);
    opacity: 0.15;
    z-index: 1;
}

.properties-popup-title {
    font-weight: bold;
}

.properties-popup-searchbar {
    margin-top: 20px;
    margin-bottom: 20px;
}

.properties-popup-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
