.stage {
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
    transition: width 0.5s;
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 1200px) {
    .stage {
        padding: 20px;
    }
}

.stage-bottom {
    height: 100px;
}

@media only screen and (min-width: 1200px) {
    .stage-bottom {
        display: none;
    }
}

.stage-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: scroll;
    animation: stageFadeIn;
    animation-duration: 0.75s;
}

@keyframes stageFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.stage-full {
    width: 100%;
}

.stage-header {
    margin-bottom: 20px;
}

.stage-header-top {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
}

.stage-header-left {
    display: flex;
    box-sizing: border-box;
    width: 100%;
}

.stage-header-left-button {
    margin-right: 10px;
}

.stage-header-left-top {
    display: flex;
}

.stage-header-secondary-actions {
    margin-top: -10px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 1200px) {
    .stage-header-maximizeBtn {
        display: none !important;
    }
}

.stage-header-secondary-action {
    display: inline-flex;
    align-items: center;
}

.stage-header-secondary-action:hover {
    cursor: pointer;
}

.stage-header-secondary-action-icon {
    width: 10px;
    height: 10px;
    margin-right: 6px;
}

.stage-header-secondary-action-text {
    font-size: 12px;
    font-weight: bold;
    color: var(--color-text_tertiary)
}

.stage-header-meta {
    display: flex;
    width: 80%;
    align-items: center;
}

@media only screen and (max-width: 1200px) {

    .stage-header-left {
        width: 94%;
    }

    .stage-header-meta {
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
    }
}

.stage-header-meta-spinner {
    margin-right: 20px;
}

.stage-header-meta-title {
    font-size: 20px;
    font-weight: bold;
    white-space: nowrap;
    color: var(--color-text_primary);
    margin-right: 20px;
    line-height: 40px;
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.stage-header-meta-badges {
    display: flex;
    align-items: center;
    margin-left: -5px;
    margin-top: -5px;
    height: 100%;
    padding-top: 6px;
}

@media only screen and (max-width: 1200px) {
    flex-wrap: wrap;
}

.stage-header-meta-badge {
    margin: 5px;
    display: flex;
    align-items: center;
}

.stage-header-meta-addInfo {
    font-size: 14px;
    margin-left: 10px;
    color: var(--color-text_secondary);
}

.stage-header-actions {
    display: flex;
    align-items: center;
}

.stageColumns {
    display: flex;
}

.stageColumns-column {
    padding: 0 10px;
}

.stageColumns-column:first-child {
    padding-left: 0;
}

.stageColumns-column:last-child {
    padding-right: 0;
}

.stageColumns-column-one {
    width: 30%;
}

.stageColumns-column-two {
    width: 70%;
}

.mobile-stage-header-actions {
    display: none;
}

@media only screen and (max-width: 1200px) {
    .mobile-stage-header-actions {
        display: block;
        flex: 0 0 20px;
        padding: 10px 0;
    }

    .stage-header-actions {
        display: none;
    }

    .mobile-stage-header-actions-popup {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .mobile-stage-header-actions-popup > div {
        margin-top: 10px;
    }

    .mobile-stage-header-actions-popup > div:first-child {
        margin-top: 0;
    }

    .stageColumns {
        flex-direction: column;
    }

    .stageColumns-column {
        padding: 0;
    }

    .stageColumns-column-one {
        width: 100%;
    }

    .stageColumns-column-two {
        width: 100%;
    }
}
