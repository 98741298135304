.loyaltyRank {

}

.loyaltyRank-media {
    margin-bottom: 20px;
}

.loyaltyRank-media-asset {
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    border: 1px solid var(--color-border);
    border-radius: 6px;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.loyaltyRank-media-asset-img {

}

.loyaltyRank-media-languageSwitcher {
    margin-bottom: 20px;
}

.loyaltyRank-media-dropzone {
    margin-bottom: 20px;
}