.columnHeaderConfig {
    border: 1px solid var(--color-border);
    border-radius: 6px;
    display: flex;
    flex-grow: 1;
}

.columnHeaderConfig-list {
    flex: 0 0 50%;
    border-right: 1px solid var(--color-border);
    display: flex;
    flex-direction: column;
}

.columnHeaderConfig-list-header {
    background-color: var(--color-background_light);
    display: flex;
    padding: 10px;
    border-radius: 6px 0 0;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-border);
    align-items: center;
}

.columnHeaderConfig-list-header-column {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
}

.columnHeaderConfig-list-header-column-title {
    font-weight: bold;
    color: var(--color-text_secondary);
}

.columnHeaderConfig-list-items-wrapper {
    position: relative;
    height: 100%;
}

.columnHeaderConfig-list-items {
    position: absolute;
    z-index: 1;
    inset: 0;
    background-color: var(--color-background_light);
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 0 6px;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.columnHeaderConfig-info {
    display: flex;
    flex-direction: column;
    flex: 0 0 50%;
    width: 50%;
}

.columnHeaderConfig-info-config {
    flex: 0 0 calc(80% - 20px);
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

.columnHeaderConfig-info-config-title {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 20px;
}

.columnHeaderConfig-info-config-text {
    margin-top: 20px;
}

.columnHeaderConfig-info-config-preview {
    margin-top: 40px;
}

.columnHeaderConfig-info-config-preview-title {
    font-weight: bold;
    margin-bottom: 10px;
}

.columnHeaderConfig-info-config-preview-values {
    display: flex;
}

.columnHeaderConfig-info-config-preview-value {
    background-color: var(--color-background_light);
    margin-right: 10px;
    padding: 6px 10px;
    border-radius: 4px;
}

.columnHeaderConfig-info-config-preview-value a {
    color: var(--color-select)
}

.columnHeaderConfig-info-config-preview-value_invalid {
    color: var(--color-danger);
    background-color: rgba(234, 66, 17, 0.1);
}

.columnHeaderConfig-info-examples {
    flex: 0 0 20%;
    padding: 20px;
    border-top: 1px solid var(--color-border);
    box-sizing: border-box;
}

.columnHeaderConfig-info-examples-title {
    color: var(--color-text_primary);
    font-weight: bold;
    margin-bottom: 20px;
}

.columnHeaderConfig-info-examples-values {
    display: flex;
    gap: 10px;
    width: 100%;
    overflow: auto;
    flex-wrap: wrap;
}

.columnHeaderConfig-info-examples-value {
    background-color: var(--color-background_light);
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 14px;
    color: var(--color-text_secondary);
    white-space: nowrap;
}

.columnHeaderConfig-info-config-contentType {
    display: flex;
    margin-bottom: 10px;
}

.columnHeaderConfig-info-config-contentType-icon {

}

.columnHeaderConfig-info-config-contentType-name {
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
    color: var(--color-text_tertiary)
}

.columnHeaderConfig-info-examples-values-empty {
    color: var(--color-text_tertiary)
}