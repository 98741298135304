.shippingDetail {

}

.shippingDetail-positions {
    margin-top: 30px;
    margin-left: -20px;
    margin-right: -20px;
    padding: 20px;
}

.shippingDetail-actions {
    display: flex;
    justify-content: flex-end;
    margin-left: -20px;
    margin-right: -20px;
    padding: 0 20px;
}
