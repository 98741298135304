.propertiesList {

}

.propertiesList-property {
    position: relative;
    display: flex;
    border-top: 1px solid var(--color-border);
    padding: 5px 20px;
}

.propertiesList-property:last-child {
    margin-bottom: 0;
}

.propertiesList-property-name {
    width: 50%;
    display: flex;
    align-items: center;
    color: var(--color-text_secondary);
}

.propertiesList-property-name-badge {
    margin-left: 10px;
}

.propertiesList-property-values {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 50%;
}

.propertiesList-property-value {
    padding: 4px 10px;
    background-color: var(--color-background);
    border-radius: 4px;
    font-size: 16px;
    color: var(--color-text_secondary);
    margin: 5px;
}

.propertiesList-property-noValue {
    color: var(--color-text_tertiary);
    padding: 10px 5px;
}

.propertiesList-property-name-title {
    display: flex;
}

.propertiesList-property-name-title-unit {
    margin-left: 10px;
    font-size: 14px;
    color: var(--color-text_tertiary);
    align-self: flex-end;
}