.categoryPropertyMatrix {

}

.categoryPropertyMatrix-actions {
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.categoryPropertyMatrix table {
    border-collapse: collapse;
    border: 1px solid var(--color-border);
}

.categoryPropertyMatrix th {
    white-space: nowrap;
    padding: 4px 8px;
    text-align: left;
    background-color: var(--color-background);
}

.categoryPropertyMatrix td {
    background-color: var(--color-background);
    padding: 0.5px;
}

.categoryPropertyMatrix-table {
    overflow: scroll;
    max-height: 70vh;
}

.categoryPropertyMatrix-table-head {
    font-weight: bold;
}

.categoryPropertyMatrix-table-cell-sticky {
    position: sticky;
    left: 0;
    z-index: 1;
}

.categoryPropertyMatrix-table-cell-content {
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 4px 8px;
    text-align: left;
}