.brandContactAddress {

}

.brandContactAddress-head {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.brandContactAddress-head-meta {
    display: flex;
    gap: 20px;
}

.brandContactAddress-head-meta-title {

}

.brandContactAddress-head-meta-item {
    color: var(--color-text_secondary)
}

.brandContactAddress-content {
    padding: 20px;
}