.filebrowser {

}

.filebrowser-nav {
    display: flex;
}

.filebrowser-nav-element {
    margin-right: 10px;
    margin-bottom: 10px;
}

.filebrowser-spacer {
    border-top: 1px solid var(--color-border);
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-top: 20px;
}

.filebrowser-stage {
    padding-top: 15px;
    padding-bottom: 15px;
    height: calc(100vh - 460px);
    overflow: auto;
    overflow-x: hidden;
}

.filebrowser-stage-itemActions {
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 10px;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--color-background_light);
    border-radius: 6px 6px 0px 0px;
    border: 1px solid var(--color-border);
}

.filebrowser-stage-folders {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
}

.filebrowser-stage-folders-actions {
}

.filebrowser-stage-folder-wrapper {
    position: relative;
    margin: 5px;
    width: calc(16.66% - 10px);
    aspect-ratio: 1 / 1;
    box-sizing: border-box;
    background-color: var(--color-stage);
    border-radius: 6px;
}

.filebrowser-stage-folder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid var(--color-border);
    border-radius: 6px;
}

.filebrowser-stage-folder-content {
    width: 100%;
    height: 100%;
    background-color: var(--color-stage);
    border-radius: 6px;
}

.filebrowser-stage-asset {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid var(--color-border);
    border-radius: 6px;
}

.filebrowser-stage-folder:hover {
    cursor: pointer;
}

.filebrowser-stage-folder-icon {
    width: 50px;
}

.filebrowser-stage-folder-name {
    font-size: 14px;
    color: var(--color-text_secondary);
    text-align: center;
}

.filebrowser-stage-folder-actions {
    position: absolute;
    top: 0px;
    right: 12px;
}

.filebrowser-stage-assets {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-left: -5px;
    margin-right: -5px;
}

.filebrowser-stage-asset-wrapper {
    position: relative;
    margin: 5px;
    width: calc(16.66% - 10px);
    aspect-ratio: 1 / 1;
    box-sizing: border-box;
}

.filebrowser-stage-asset {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid var(--color-border);
    border-radius: 6px;
}

.filebrowser-stage-asset-content {
    width: 100%;
    height: 100%;
    background-color: var(--color-stage);
    border-radius: 6px;
}

.filebrowser-stage-asset-content-img {
    border-radius: 6px 6px 0px 0px;
    width: 100%;
    height: 80%;
    object-fit: cover;
}

.filebrowser-stage-asset-content-icon-wrapper {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filebrowser-stage-asset-content-icon {
    width: 100%;
    height: 70%;
}

.filebrowser-stage-asset-content-name {
    padding-left: 8px;
    padding-top: 2px;
    font-size: 12px;
    color: var(--color-text_secondary);
    white-space: nowrap;
    overflow: hidden;
}

.filebrowser-stage-asset-actions {
    position: absolute;
    top: 0px;
    right: 12px;
}

.filebrowser-newFolderPopup-actions {
    border-top: 1px solid var(--color-border);
    padding-top: 20px;
    margin-top: 20px;
    margin-left: -20px;
    margin-right: -20px;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
}

.filebrowser-moveFolderPopup-hierarchy {
    margin-bottom: 20px;
}

.filebrowser-moveFolderPopup-hierarchy-element {
}

.filebrowser-moveFolderPopup-hierarchy-element-item {
    padding-left: 10px;
    border-radius: 6px;
    display: flex;
    height: 40px;
    align-items: center;
}

.filebrowser-moveFolderPopup-hierarchy-element-item:hover {
    background-color: var(--color-background_light);
    cursor: pointer;
}

.filebrowser-moveFolderPopup-hierarchy-element-item-arrow {
    width: 10px;
    margin-right: 10px;
}

.filebrowser-moveFolderPopup-hierarchy-element-item-check {
    margin-right: 10px;
}

.filebrowser-moveFolderPopup-hierarchy-element-item-name {
}

.filebrowser-moveFolderPopup-hierarchy-element-children {
    margin-left: 14px;
    padding-left: 10px;
    border-left: 1px solid var(--color-border);
}
