.adyenWizard {

}

.adyenWizard-startButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}