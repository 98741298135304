.animalSpeciesEditor {
    margin-bottom: 20px;
}

.animalSpeciesEditor-actions {
    margin-top: 20px;
}

.animalSpeciesEditor-speciesSection {
    margin-bottom: 20px;
}

.animalSpeciesEditor-speciesSection:last-child {
    margin-bottom: 0;
}

.animalSpeciesEditor-speciesSection-title {
    color: var(--color-text_secondary);
    margin-bottom: 10px;
}

.animalSpeciesEditor-speciesSection-options {
    display: flex;
}

.animalSpeciesEditor-speciesSection-option {
    padding: 6px 10px;
    border-radius: 4px;
    background-color: var(--color-tertiary);
    margin-right: 10px;
}

.animalSpeciesEditor-speciesSection-option-selected {
    color: var(--color-success);
    border: 2px solid var(--color-success);
}

.animalSpeciesEditor-speciesSection-option:hover {
    cursor: pointer;
    background-color: var(--color-tertiary_hover);
}

.animalSpeciesEditor-speciesSection-option:active {
    cursor: pointer;
    background-color: var(--color-tertiary_active);
}

.animalSpeciesEditor-speciesSection-option:last-child {
    margin-right: 0;
}