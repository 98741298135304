.areaChart {
    font-size: 12px;
}

.areaChart-chart {
    position: relative;
}

.recharts-responsive-container {
    position: relative;
    z-index: 3;
}

.recharts-cartesian-axis-line {
    stroke: var(--color-border) !important;
    stroke-width: 2px;
}

.recharts-cartesian-axis-tick-value {
    color: var(--color-text_tertiary) !important;
    font-size: 12px;
    font-weight: bold;
    fill: var(--color-text_tertiary) !important;
}

.recharts-default-tooltip {
    background-color: var(--color-foreground) !important;
    border: 1px solid var(--color-border) !important;
    border-radius: 6px;
    box-shadow: var(--shadow-wide);
    z-index: 4;
}

.recharts-tooltip-label {
    color: var(--color-text_primary) !important;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 4px !important;
}