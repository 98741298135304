.additionalinformation-elements {
  margin-bottom: 20px;
}

.additionalinformation-elements-inherited {
  position: relative;
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px;
  padding-bottom: 10px;
}

.additionalinformation-elements-inherited::after {
  position: absolute;
  content: ' ';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-inherited);
  opacity: 0.2;
}

.additionalinformation-element {
  margin-bottom: 10px;
}

.additionalinformation-actions {
  border-top: 1px solid var(--color-border);
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px;
  padding-bottom: 0;
}

.additionalinformation-element-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.additionalinformation-element-head-title {
  font-weight: bold;
}

.additionalinformation-element-head-actions {
}
