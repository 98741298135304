.informationbox {
    position: relative;
    border-radius: 6px;
    box-sizing: border-box;
    background-color: rgba(13, 124, 206, 0.20);
    width: 100%;
    display: flex;
    padding: 20px;
}

.informationbox-warning {
    background-color: rgba(255, 164, 106, 0.20);
}

.informationbox-error {
    background-color: rgba(234, 66, 17, 0.20);
    color: var(--color-danger);
}

.informationbox-success {
    background-color: rgba(35, 178, 85, 0.20);
    color: var(--color-success);
}

.informationbox-marker {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    border-radius: 10px 0 0 10px;
    background-color: var(--color-blue);
}

.informationbox-marker-warning {
    background-color: #ffa46a;
}

.informationbox-marker-error {
    background-color: var(--color-danger);
}

.informationbox-marker-success {
    background-color: var(--color-success);
}

.informationbox-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
}

.informationbox-text {
    line-height: 22px;
    opacity: 0.8;
    margin-right: 24px;
}

.informationbox-paragraph {
    margin: 0;
    margin-top: 10px;
    color: var(--color-text_secondary)
}

.informationbox-close {
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 0.3;
    width: 12px;
}

.informationbox-close:hover {
    cursor: pointer;
}

.informationbox-icon {
    display: flex;
    align-items: center;
    margin-right: 14px;
}

.informationbox-icon-img {
    width: 24px;
    height: 24px;
}
