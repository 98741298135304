.reference {

}

.reference-element {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.reference-element:last-child {
    margin: 0;
}

.reference-element-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.reference-monospaced {
    padding: 4px 4px;
    margin-right: 10px;
}

.reference-linked {
    background-color: var(--color-inherited_background);
    color: var(--color-inherited);
}