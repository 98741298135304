.returns {
}

.returns-actions {
  border-top: 1px solid var(--color-border);
  margin-top: -1px;
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px;
  padding-bottom: 0;
  display: flex;
  justify-content: flex-end;
}
