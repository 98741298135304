.jtce-stocks {
    display: flex;
}

.jtce-stock {
    display: flex;
    font-size: 14px;
    background-color: var(--color-background_light);
    margin-right: 6px;
    border-radius: 4px;
    padding: 0 8px;
    border: 1px solid var(--color-border);
    align-items: center;
}

.jtce-stock:hover {
    cursor: pointer;
}

.jtce-stock__selected {
    outline: 2px solid var(--color-blue);
    border-color: transparent;
}

.jtce-stock-el {
    padding: 2px;
}

.jtce-stock-el-availability {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    margin-right: 4px;
}

.jtce-stock-el-warehouse {
    width: 16px;
    height: 16px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 4px;
    background-color: var(--color-yellow);
    margin-left: 2px;
    color: white;
}

.jtce-stock-el-priority span {
    font-size: 10px;
    font-weight: bold;
}