.inpettoClientBasedPlugin {

}

.inpettoClientBasedPlugin-gif {
    width: 100%;
    height: 240px;
    background-color: var(--color-background);
}

.inpettoClientBasedPlugin-downloads {
    margin-top: 20px;
}