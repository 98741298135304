.knowledgebase {

}

.knowledgebase-sidebarWrapper {
    position: sticky;
    top: 20px;
}

.knowledgebase-sidebar {
    padding: 10px;
}

.knowledgebase-sidebar-item {
    padding: 10px;
    color: var(--color-text_primary);
    border-radius: 6px;
}

.knowledgebase-sidebar-item:hover {
    cursor: pointer;
    background-color: var(--color-background_light);
}

.knowledgebase-sidebar-item_selected {
    font-weight: bold;
    background-color: var(--color-background_light);
    color: var(--color-primary)
}

.knowledgebase-groups {
}

.knowledgebase-group {
    margin-bottom: 40px;
}

.knowledgebase-group:last-child {
    margin-bottom: 0;
}

.knowledgebase-group-title {
    font-weight: bold;
    margin-bottom: 20px;
}

.knowledgebase-infoItems {
    position: relative;
    width: 100%;
}

.knowledgebase-infoItems-inline {
    display: flex;
    align-items: flex-start;
    margin: -10px;
    flex: 0 0 100%;
    flex-direction: var(--layout-responsiveFlexDirection);
}

.knowledgebase-infoItems-inline > .knowledgebase-infoItems-item {
    margin: 10px;
    flex-direction: column;
    min-width: calc(33.33% - 14px)
}

.knowledgebase-infoItems-inline .knowledgebase-infoItems-item-media {
    padding: 0;
    margin: 20px 0 0;
}

.knowledgebase-infoItems-inline > .knowledgebase-infoItems-item:first-child {
    margin-top: 10px;
}

.knowledgebase-infoItems-item {
    display: flex;
    background-color: var(--color-hint-messageBackground);
    border-radius: 6px;
    padding: 20px;
    margin-top: 10px;
    box-sizing: border-box;
}

.knowledgebase-infoItems-item:first-child {
    margin-top: 0;
}

.knowledgebase-infoItems-column {
    flex: 50%;
}

.knowledgebase-infoItems-item-title {
    color: var(--color-text_primary);
    font-weight: bold;
    margin-bottom: 10px;
}

.knowledgebase-infoItems-item-text {
    color: var(--color-text_primary);
}

.knowledgebase-infoItems-item-media {
    width: 100%;
    min-height: 200px;
    padding: 0 20px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin-left: 20px;
}

.knowledgebase-infoItems-item-media-img {
    width: 100%;
    max-height: 240px;
    display: block;
    border-radius: 6px;
    object-fit: cover;
}

.knowledgebase-infoItems-item-media-img:hover {
    cursor: zoom-in;
}

.knowledgebase-infoItems-mediaViewer-img {
    width: 100%;
}