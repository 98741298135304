.barTooltip {
    background-color: var(--color-foreground);
    border: 1px solid var(--color-border);
    border-radius: 6px;
    padding: 10px;
    display: flex;
    align-items: center;
    box-shadow: var(--shadow-wide);
}

.barTooltip-cover {
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 6px;
    border: 1px solid var(--color-border);
    margin-right: 10px;
}

.barTooltip-cover-placeholder {
    box-sizing: border-box;
    padding: 20px;
}

.barTooltip-meta {
}

.barTooltip-meta-title {
    font-weight: bold;
}

.barTooltip-meta-element {
    font-size: 14px;
    display: block;
    color: var(--color-text_secondary)
}

.barTooltip-meta-element span {
    color: var(--color-text_primary);
    font-weight: bold;
}

.barTooltip-meta-element-badge {
    margin-bottom: 10px;
}