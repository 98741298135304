.hoverable {
    position: relative;
}

.hoverable-pop {
    position: fixed;
    z-index: 999;
    animation: popIn 0.3s;
    pointer-events: none;
}

.hoverable-pop-stretchToLeft {
    right: 0;
}

@keyframes popIn {
    0% {
        opacity: 0;
        margin-top: 10px;
    }
    100% {
        opacity: 1;
        margin-top: 0;
    }
}