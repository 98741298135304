.newReturnAnnouncement-shippingDetails-positions {

}

.newReturnAnnouncement-retractable {
    margin-bottom: 10px;
}

.newReturnAnnouncement-reasons {
}

.newReturnAnnouncement-reason {
    border-top: 1px solid var(--color-border);
    padding: 20px 20px 0;
    margin-bottom: 20px;
}

.newReturnAnnouncement-reason:last-child {
    border-bottom: none;
}

.newReturnAnnouncement-reasons-actions {
    border-top: 1px solid var(--color-border);
    padding: 20px;
    margin-bottom: 0;
}

.newReturnAnnouncement-reason-top {
    justify-content: space-between;
    align-items: flex-end;
}

.newReturnAnnouncement-actions {
    margin-top: 20px;
}
