.policymatrix-wrapper {
    position: relative;
    max-height: 500px;
    overflow: scroll;
    margin-bottom: 20px;
    border: 1px solid var(--color-border);
    border-radius: 6px;
}

.policymatrix {
    width: 100%;
    border-spacing: 0;
    margin-bottom: 20px;
}

.policymatrix-header {
    position: sticky;
    top: 0;
    width: 100%;
    background-color: var(--color-background_light);
    height: 60px;
    box-shadow: 0 1px var(--color-border);
}

.policymatrix-header-switch {
    font-weight: normal;
}

.policymatrix th {
    text-align: center;
    min-width: 80px;
}

.policymatrix-group {
    margin-bottom: 10px;
    height: 40px;
    vertical-align: bottom;
    border-top: 1px solid black;
}

.policymatrix-group-title {
    white-space: nowrap;
    font-weight: bold;
    padding-bottom: 10px;
    padding-top: 20px;
}

.policymatrix-set {
    line-height: 28px;
    color: var(--color-text_secondary)
}

.policymatrix-set-odd {
    background-color: var(--color-background_light);
}

.policymatrix-set-even {
}

.policymatrix-set-technicalName {
    margin-left: 10px;
}

.policymatrix-set-title {
    white-space: nowrap;
    padding-right: 20px;
    height: 24px;
}

.policymatrix-permission {
}

.policymatrix-permission-check {
    display: flex;
    align-items: center;
    justify-content: center;
}
