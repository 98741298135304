.productGroupMappingEdit {
    max-height: 70vh;
    overflow-y: scroll;
}

.productGroupMappingEdit-heading {
    margin-bottom: 10px;
    color: var(--color-text_secondary)
}

.productGroupMappingEdit-propertyBrowser {
    margin-bottom: 20px;
}