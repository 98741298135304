.returnedit {
}

.returnedit-actions {
  border-top: 1px solid var(--color-border);
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px;
  padding-bottom: 0;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
