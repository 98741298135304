.headerConfigSettings {

}

.headerConfigSettings-menu {
    background-color: var(--color-foreground);
    border-radius: 6px;
    box-shadow: var(--shadow-wide);
    padding: 10px;
    max-height: 60vh;
    overflow-y: scroll;
    position: relative;
}