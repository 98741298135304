.connectionTestResults {

}

.connectionTestResult {
    margin-top: 10px;
}

.connectionTestResult-name {

}

.connectionTestResult-status {
    display: flex;
    align-items: center;
    gap: 10px;
}

.connectionTestResult-status-bar {
    width: 100%;
    height: 6px;
    border-radius: 6px;
    background-color: var(--color-background_light);
}

.connectionTestResult-status-bar-fill {
    animation: barFill ease-out 0.5s forwards;
    height: 100%;
    border-radius: 6px;
    width: 0;
}

@keyframes barFill {
    from {
        width: 0;
    }
    to {
        width: 100%
    }
}

.connectionTestResult-status-bar-fill__ok {
    background-color: var(--color-success);
}

.connectionTestResult-status-bar-fill__warning {
    background-color: var(--color-warning);
}

.connectionTestResult-status-bar-fill__error {
    background-color: var(--color-danger);
}

.connectionTestResult-status-badge {
    width: 90px;
    padding: 4px 6px;
    text-align: center;
    border-radius: 6px;
    font-weight: bold;
    font-size: 14px;
    animation: fadeIn 0.5s forwards;
    opacity: 0;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.connectionTestResult-status-badge__ok {
    background-color: var(--color-success);
}

.connectionTestResult-status-badge__warning {
    background-color: var(--color-warning);
}

.connectionTestResult-status-badge__error {
    background-color: var(--color-danger);
}

.connectionTestResult-error {
    animation: appearIn 0.5s forwards;
    opacity: 0;
    height: 0;
}

.connectionTestResult-error-message {
    margin-top: 10px;
    background-color: var(--color-background_light);
    padding: 10px;
    border-radius: 6px;
}

@keyframes appearIn {
    from {
        opacity: 0;
        height: 0;
    }
    to {
        opacity: 1;
        height: auto;
    }
}

.connectionTestResults-versions {
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
    gap: 10px;
    margin-top: 20px;
}

.connectionTestResults-version {

}