.recurringCustomers {

}

.recurringCustomers-topBar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.recurringCustomers-topBar-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.recurringCustomers-topBar-info {
    background-color: var(--color-background_light);
    color: var(--color-text_secondary);
    padding: 10px;
    border-radius: 6px;
    margin-right: 10px;
    font-size: var(--size-text_small);
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 1200px) {
    .recurringCustomers-topBar-info {
        margin-bottom: 10px;
    }

    .recurringCustomers-topBar-group {
        margin-bottom: 10px;
    }
}

.recurringCustomers-settings {

}