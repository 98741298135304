.jtce-analyticConstituents {
}

.jtce-analyticConstituents-list {
    margin-bottom: -20px;
    max-height: 60vh;
    overflow-y: scroll;
    margin-left: -20px;
    margin-right: -20px;
    padding: 0 20px;
}

.jtce-analyticConstituents-list-item {

}