.productMappingTuple {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid var(--color-border);
    justify-content: space-between;
}

.productMappingTuple:last-child {
    border-bottom: none;
}

.productMappingTuple-productWrapper {
    position: relative;
    height: 60px;
    width: calc(50% - 48px);
}

.productMappingTuple-check {
    width: 30px;
}

.productMappingTuple-socket {
    width: calc(50% - 48px);
    border: 1px solid var(--color-border);
    height: 60px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: var(--color-background);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: var(--color-text_secondary);
}

.productMappingTuple-socket__empty {

}

.productMappingTuple-socket__create {
    border-color: var(--color-select);
    color: var(--color-select);
    background-color: rgba(0, 149, 255, 0.1);
}

.productMappingTuple-matchStatusIcon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    padding: 4px;
    border-radius: 4px;
    margin: 0 20px;
}

.productMappingTuple-matchStatusButton {
    display: none;
    width: 28px;
    height: 28px;
    padding: 6px;
    flex-shrink: 0;
    border-radius: 4px;
    margin: 0 20px;
}

.productMappingTuple:hover .productMappingTuple-matchStatusIcon__proposal {
    display: none;
}

.productMappingTuple:hover .productMappingTuple-matchStatusButton {
    display: block;
}

.productMappingTuple-matchStatusIcon__linked {
    fill: var(--color-success);
    background-color: rgba(35, 178, 85, 0.1);
}

.productMappingTuple-matchStatusIcon__unlinked {
    fill: var(--color-text_tertiary);
    background-color: var(--color-background)
}

.productMappingTuple-matchStatusIcon__create {
    fill: var(--color-select);
    background-color: rgba(0, 149, 255, 0.1);
}

.productMappingTuple-matchStatusIcon__update {
    fill: var(--color-select);
    background-color: rgba(0, 149, 255, 0.1);
}

.productMappingTuple-matchStatusIcon__proposal {
    fill: var(--color-inherited);
    background-color: rgba(142, 130, 239, 0.1);
}

.productMappingTuple_groupBracket {
    background-color: rgba(142, 130, 239, 0.5);
    width: 6px;
    position: absolute;
    z-index: 999;
    left: -7px;
}

.productMappingTuple_groupBracket__start {
    border-radius: 6px 0 0 0;
    top: 10px;
    bottom: -1px;
    height: calc(100% - 9px);
    background-color: var(--color-inherited);
}

.productMappingTuple_groupBracket__middle {
    top: 0;
    bottom: -1px;
}

.productMappingTuple_groupBracket__end {
    border-radius: 0 0 0 6px;
    bottom: 10px;
    height: calc(100% - 10px);
}