.petProfiles {

}

.petProfiles-filters {
    margin-bottom: 20px;
}

.petProfiles-filter {
    display: inline-flex;
    color: var(--color-text_secondary);
    font-size: 14px;
    font-weight: bold;
    background-color: var(--color-background_light);
    padding: 4px 10px;
    border-radius: 100px;
    align-items: center;
}

.petProfiles-filter-icon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.petProfiles-filter-icon-close {
    width: 10px;
    height: 10px;
    margin-left: 10px;
}

.petProfiles-tabs {
    margin-top: -20px;
}

.petProfiles-barChart {
    margin-top: 20px;
}