.recurringCustomersBarPopup {

}

.recurringCustomersBarPopup-element {

}

.recurringCustomersBarPopup-element-badge {
    margin-bottom: 10px;
}

.recurringCustomersBarPopup-element > span {
    font-weight: bold;
}

.recurringCustomersBarPopup-emails {
    margin-top: 10px;
    max-height: 60vh;
    overflow: scroll;
    border: 1px solid var(--color-border);
    border-radius: 6px;
    padding: 4px 10px;
}

.recurringCustomersBarPopup-email {
    font-size: 14px;
    color: var(--color-text_secondary);
}