.jtce-json {
}

.jtce-json textarea {
    border: 1px solid var(--color-border);
    border-radius: 6px;
    padding: 10px;
}

.jtce-json textarea:focus {
    outline: none;
}

.jtce-json-error {

}