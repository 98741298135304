.headerConfigLoader {

}

.headerConfigLoader-configs {
    border-radius: 6px;
    overflow: hidden;
    background-color: var(--color-background_light);
    padding: 10px;
    border: 1px solid var(--color-border);
}

.headerConfigLoader-config {
    display: flex;
    align-items: center;
    border: 1px solid var(--color-border);
    border-radius: 6px;
    padding: 10px 14px;
    background-color: var(--color-foreground);
    margin-bottom: 10px;
    justify-content: space-between;
    cursor: pointer;
    transition: background-color 0.1s;
}

.headerConfigLoader-config:hover {
    background-color: var(--color-background);
}

.headerConfigLoader-config:last-child {
    margin-bottom: 0;
}

.headerConfigLoader-config-info {
    display: flex;
    align-items: center;
}

.headerConfigLoader-config-info-createdAt {

}

.headerConfigLoader-config-info-name {
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 10px;
}

.headerConfigLoader-config-actions {
    display: flex;
    align-items: center;
}

.headerConfigLoader-popupActions {
    margin-top: 20px;
}