.analyticconstituents {
}

.analyticconstituents-inherited {
    position: relative;
    margin-left: -20px;
    margin-right: -20px;
    padding: 20px;
    padding-bottom: 10px;
}

.analyticconstituents-inherited::after {
    position: absolute;
    content: ' ';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-inherited);
    opacity: 0.2;
}

.analyticconstituent {
    display: flex;
    border-bottom: 1px solid var(--color-border);
    margin-left: -20px;
    margin-right: -20px;
    padding: 20px;
}

.analyticconstituent:last-child {
    border-bottom: none;
}

.analyticconstituent-input {
    flex: 1;
    margin-right: 10px;
}

.analyticconstituent-input:last-child {
    margin: 0;
}

.analyticconstituents-actions {
    display: flex;
    justify-content: space-between;
    margin-left: -20px;
    margin-right: -20px;
    padding: 20px;
    padding-bottom: 0;
    border-top: 1px solid var(--color-border);
    margin-top: -1px;
}

.analyticconstituent-delete {
    align-self: flex-end;
}
