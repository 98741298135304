.jsontable {
    position: relative;
}

.jsontable * {
    user-select: none;
}

.jsontable table, .jsontable table th, .jsontable table td {
    border-collapse: separate !important;
    border-spacing: 0 !important;
    border-left: none !important;
}

.jsontable-topActions {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 3;
}

.jsontable-topActions-group {
    display: flex;
    align-items: center;
}

.jsontable-topAction {
    margin-right: 10px;
    height: 40px;
}

.jsontable-topAction-label {
    font-size: 14px;
    background-color: var(--color-background);
    border-radius: 6px;
    color: var(--color-text_secondary);
    height: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;
}

.jsontable-topActions-action {
    padding: 10px;
}

.jsontable-table-th {
    background-color: var(--color-foreground);
    z-index: 1;
}

.jsontable-table-th__sticky {
    top: 0;
    z-index: 4;
    position: sticky !important;
}

.jsontable-table-td {
    background-color: var(--color-foreground);
    z-index: 1;
}

.jsontable-table-td__sticky {
    top: 0;
    z-index: 3;
    position: sticky !important;
}

.jsontable-table-columnIndex {
    min-width: 100px;
    width: 100px;
    position: sticky !important;
    border-left: none !important;
    left: 0;
    z-index: 3;
}

.jsontable-table-columnIndex-inner {
    height: 100%;
    border-left: 1px solid var(--color-border) !important;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.jsontable-table-columnIndex-clearButton {
    min-width: 0;
    width: 20px;
    height: 20px !important;
    padding: 4px;
    border-radius: 4px;
}

.jsontable-headerTable {
    position: sticky;
    top: 0;
    z-index: 4;
    border: none;
}

.jsontable-table-wrapper {
    position: relative;
    overflow-x: scroll;
    overflow-y: scroll;
}

.jsontable-table {
    display: inline-block;
    position: relative;
    border: none;
}

.jsontable-table-headerRow {
    border: none !important;
}

.jsontable-table tr {
    position: absolute;
}

.jsontable-table td {
    border: none;
    border-right: 1px solid var(--color-border);
    border-bottom: 1px solid var(--color-border);
    overflow: hidden;
}

.jsontable-table-cellIndex {
    min-width: 100px;
    max-width: 100px;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 1;
}

.jsontable-table-cellIndex-groupBracket:before {
    content: '';
    position: absolute;
    width: 6px;
    height: calc(100%);
    left: 0;
    z-index: 2;
    background-color: rgba(142, 130, 239, 0.5);
}

.jsontable-table-cellIndex-groupBracket__start:before {
    border-radius: 0 6px 0 0;
    top: 10px;
    background-color: var(--color-inherited);
}

.jsontable-table-cellIndex-groupBracket__end:before {
    border-radius: 0 0 6px 0;
    bottom: 10px;
}

.cell-index-number {
    display: flex;
    justify-content: center;
}

.jsontable-table-cellIndex-variantIcon {
    display: block;
    width: 8px;
    margin-right: 4px;
}

.jsontable-table-cellIndex-actions {
    display: none;
    position: absolute;
    top: 7px;
    left: 1px;
    width: 40px;
    height: 100%;
    background-color: var(--color-background_light);
}

.jsontable-table-cellIndex:hover {
    z-index: 10;
}

.jsontable-table-cellIndex:hover > .jsontable-table-cellIndex-actions {
    display: block;
}

.jsontable-header-cell {
    position: relative;
    display: flex;
    text-align: left;
    padding: 10px;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: var(--color-background_light);
    overflow: hidden;
    color: var(--color-text_secondary)
}

.jsontable-header-cell-fullTitle {
    display: none;
    position: fixed;
    top: 400px;
    left: 400px;
    align-items: center;
    z-index: 99999999;
    overflow: visible;
    animation: fadeIn 0.5s 1s 1 normal forwards;
    opacity: 0;
    padding: 4px 10px;
    background-color: var(--color-background_light);
    box-shadow: var(--shadow-minimal);
    border-radius: 6px;
    border: 1px solid var(--color-border);
}

.jsontable-header-cell:hover > .jsontable-header-cell-fullTitle {
    display: block;
}

.jsontable-table-headerRow > th {
    position: relative;
}

.jsontable-header-sizeHandle {
    position: absolute;
    top: 0;
    right: 0;
    width: 4px;
    height: 100%;
    transition: background-color 0.5s;
}

.jsontable-header-sizeHandle:hover {
    cursor: col-resize;
    background-color: var(--color-text_tertiary);
}

.jsontable-header-required {
    width: 6px;
    height: 6px;
    background-color: var(--color-danger);
    border-radius: 6px;
    margin-left: 6px;
    margin-top: -8px;
}

.jsontable-addRowButton {
    position: sticky;
    left: 0;
    height: 30px;
    width: 74px;
}

.jsontable-addRowButton .button-icon {
    width: 12px
}

.jsontable-header-cell-icon {
    min-width: 12px;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    margin-left: 4px;
    flex-shrink: 0;
}

.jsontable-cell {
    height: 100%;
    position: relative;
    max-width: 600px;
}

.jsontable-cell_disabled {
    background-color: rgba(0, 0, 0, 0.075);
    color: var(--color-text_tertiary)
}

.jsontable-cell_selected,
.jsontable-cell:focus {
    background-color: rgba(0, 149, 255, 0.1);
    outline: none;
}

.jsontable-cell-content {
    position: relative;
    padding: 10px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    transition: background-color 0.1s;
}

.jsontable-cell-content-validation {
    position: absolute;
    right: 10px;
    height: 26px;
    width: 26px;
}

.jsontable-cell-content-validation-icon {
    height: 26px;
    width: 26px;
    fill: var(--color-blue);
    background-color: var(--color-foreground);
    padding: 6px;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: var(--shadow-wide)
}

.jsontable-cell-content__error {
    background-color: rgba(234, 66, 17, 0.2);
}

.jsontable-cell-content__warning {
    background-color: rgba(255, 190, 0, 0.15);
}

.jsontable-cell-content__completed {
    background-color: rgba(35, 178, 85, 0.1);
}

.jsontable-cell-content__missing {
    background-color: rgba(234, 66, 17, 0.2);
}

.jsontable-input-wrapper {
    height: 100%;
}

.jsontable-input-wrapper > span {
    display: block;
    height: 0;
    visibility: hidden;
    white-space: nowrap;
    font-size: 14px;
    padding: 0 12px;
}

.jsontable-input-editor-wrapper {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    animation: backgroundFadeIn 0.3s;
}

.jsontable-bottomActions {
    display: flex;
    justify-content: space-between;
}

@keyframes backgroundFadeIn {
    from {
        background-color: rgba(0, 0, 0, 0);
    }

    to {
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.jsontable-input-editor {
    display: block;
    z-index: 999;
    background-color: var(--color-foreground);
    border-radius: 4px;
    padding: 10px;
    box-shadow: var(--shadow-wide);
    border: 1px solid var(--color-border);
    max-width: 90vw;
    animation: moveIn 0.3s;
}

@keyframes moveIn {
    from {
        margin-top: 50px;
    }

    to {
        margin-top: 0;
    }
}

.jtce-title {
    font-weight: bold;
    margin-bottom: 20px;
}

.jtce-actions {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.jtce-action {
    margin-bottom: 20px;
    width: 100%;
}

.jtce-action:last-child {
    margin-bottom: 0;
}

/* CUSTOM CELLS */

.jtce-bubble {
    margin-right: 6px;
    cursor: pointer;
    border-radius: 100px;
    padding: 0 10px;
    font-size: 14px;
    white-space: nowrap;
    border: 1px solid var(--color-border);
    background-color: var(--color-background_light);
    display: flex;
    align-items: center;
}

.jtce-bubble__selected {
    outline: 2px solid var(--color-blue);
    border-color: transparent;
}

.jtce-bubble:last-child {
    margin-right: 0;
}

.jtce-bubble-icon {
    width: 12px;
    height: 12px;
    margin-right: 6px;
}