.progressBar {
    position: relative;
}

.progressBar-loader {
    text-align: center;
}

.progressBar-title {
    color: var(--color-primary);
    text-align: center;
    margin-bottom: 10px;
}

.progressBar-bar {
    width: 100%;
    height: 10px;
    background-color: rgba(244, 130, 130, 0.25);
    border-radius: 10px;
    overflow: hidden;
}

.progressBar-bar-cardTop {
    position: absolute;
    top: -20px;
    left: -20px;
    width: calc(100% + 40px);
    border-radius: 6px 6px 0 0 !important;
    height: 4px !important;
}

.progressBar-bar-fill {
    height: 100%;
    background-color: var(--color-primary);
    border-radius: 10px;
    transition: width 0.5s, background-color 0.25s;
}

.progressBar-step {
    position: absolute;
    top: -9px;
    z-index: 999;
    height: 20px;
    min-width: 20px;
    background-color: var();
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 4px solid var(--color-foreground);
    color: var(--color-text_secondary);
    font-size: 14px;
    font-weight: bold;
    box-shadow: var(--shadow-minimal);
}

.progressBar-step__done {
    color: white;
}

.progressBar-step-check {
    width: 10px;
    fill: white;
}

.progressBar-detail {
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
    color: var(--color-text_secondary);
}