.columnHeaderConfigProfiles-buttonIcon {
    fill: var(--color-text_tertiary);
}

.columnHeaderConfigProfiles {

}

.columnHeaderConfigProfiles-profiles {

}

.columnHeaderConfigProfiles-profile {
    display: flex;
    align-items: center;
    background-color: var(--color-background_light);
    border: 1px solid var(--color-border);
    border-radius: 6px;
    padding: 10px 20px;
    justify-content: space-between;
    cursor: pointer;
    transition: background-color 0.1s;
    margin-bottom: 10px;
}

.columnHeaderConfigProfiles-profile:hover {
    background-color: var(--color-background);
}

.columnHeaderConfigProfiles-profile-head {
    display: flex;
}

.columnHeaderConfigProfiles-profile-title {
    margin-left: 20px;
}

.columnHeaderConfigProfiles-profile-createdAt {
    margin-left: 20px;
}

.columnHeaderConfigProfiles-profile-actions {
    display: flex;
    gap: 10px;
}

.columnHeaderConfigProfiles-popupText {
    max-width: 400px;
}

.columnHeaderConfigProfiles-button {
    margin-top: 20px;
}