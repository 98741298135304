.barChart {
    width: 100%;
}

.barChart-group {
    display: flex;
    width: 100%;
}

.barChart-yAxis {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--color-text_secondary);
    font-size: 14px;
    margin-right: 6px;
    padding-bottom: 30px;
}

.barChart-yAxis-name {
    font-size: 14px;
    background-color: var(--color-background_light);
    display: flex;
    align-items: center;
    border-radius: 6px;
    color: var(--color-text_secondary);
    font-weight: bold;
    margin-right: 10px;
}

.barChart-yAxis-name > div {
    position: relative;
    transform-origin: 0 0;
    transform: rotate(-90deg);
    white-space: nowrap;
    width: 30px;
}

.barChart-yAxis-name > div > span {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, 0);
    line-height: 30px;
    pointer-events: none;
}

.barChart-yAxis-step {

}

.barChart-xAxis {
    display: flex;
    margin-top: 4px;
}

.barChart-xAxis-name {
    font-size: 14px;
    background-color: var(--color-background_light);
    display: flex;
    align-items: center;
    border-radius: 6px;
    color: var(--color-text_secondary);
    font-weight: bold;
    margin-right: 10px;
    height: 30px;
    justify-content: center;
    margin-top: 10px;
    margin-left: 30px;
}

.barChart-xAxis-step {
    text-align: center;
    color: var(--color-text_secondary);
    font-size: 14px;
    min-width: 40px;
}

.barChart-stage {
    width: 100%;
    overflow: scroll;
}

.barChart-stage-stacks {
    width: 100%;
    display: flex;
    height: 200px;
    border-bottom: 1px solid var(--color-border);
}

@media only screen and (min-width: 1200px) {
    .barChart-stage-stacks {
        height: 400px;
    }
}

.barChart-stage-stacks-stack {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 5px;
    min-width: 40px;
    box-sizing: border-box;
}

.barChart-stage-stacks-stack__row {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
}

.barChart-stage-stacks-stack-bar {
    width: 100%;
    opacity: 0.8;
}

.barChart-stage-stacks-stack-bar:first-child,
.barChart-stage-stacks-stack-bar__hoverable:first-child {
    border-radius: 6px 6px 0 0;
}

.barChart-stage-stacks-stack__row > .barChart-stage-stacks-stack-bar,
.barChart-stage-stacks-stack__row > .barChart-stage-stacks-stack-bar__hoverable {
    border-radius: 6px 6px 0 0;
}

.barChart-stage-stacks-stack-bar__hoverable {
    width: 100%;
    opacity: 0.5;
    transition: opacity 0.25s, height 0.25s;
}

.barChart-stage-stacks-stack-bar__hoverable:hover {
    opacity: 0.8;
    cursor: pointer;
}

.barChart-stage-stacks-stack-bar__highlighted {
    opacity: 0.8 !important;
}