.media {
  width: 100%;
}

.media-galleryLoader {
  display: flex;
}

.media-galleryLoader-title {
  font-size: 14px;
  color: var(--color-primary);
  margin-right: 10px;
}

.media-assets {
  margin: -5px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.media-actions {
  margin-bottom: 20px;
  display: flex;
  animation-name: media-actions;
  animation-duration: 0.3s;
}

@keyframes media-actions {
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: 40px;
  }
}

.media-action {
  margin-right: 10px;
}

.media-assets-dropzone {
  position: relative;
  width: calc(33.33% - 2px);
  height: calc(33.33% - 2px);
  padding: 5px;
  border-radius: 6px;
  box-sizing: border-box;
}

.media-hint {
  font-size: 14px;
  color: var(--color-text_tertiary);
  margin-bottom: 20px;
}
