.productList {
    position: relative;
}

.productList-variantOptionBadges {
    display: flex;
}

.productList-variantOptionBadge {
    display: inline;
    margin-right: 4px;
}

.productList-variantOptionBadge:last-child {
    margin-right: 0;
}

.productList-name {
    display: flex;
    align-items: center;
}

.productList-name-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.productList-listFilter-button {
    font-size: 14px;
    letter-spacing: normal;
}