.shareholders {

}

.shareholders-form {
    max-height: 70vh;
    overflow-y: auto;
    margin-bottom: 20px;
}

.shareholders-tempInfoText {
    max-width: 500px;
}

.shareholders-tempInfos {
    box-sizing: border-box;
}

.shareholders-tempInfo {
    border-top: 1px solid var(--color-border);
    margin-left: -20px;
    margin-right: -20px;
    padding: 10px 20px 0 20px;
    margin-bottom: 10px;
}

.shareholders-tempInfo:last-child {
    margin-bottom: 0;
}

.shareholders-tempInfo-reference {
    margin-bottom: 10px;
}