.salesOverviewSubElement {
    flex: 1 1;
    display: flex;
    justify-content: center;
}

.salesOverviewSubElement-title {
    color: var(--color-text_secondary);
    white-space: nowrap;
}

.salesOverviewSubElement-value {
    display: flex;
    margin-left: 10px;
}

.salesOverviewSubElement-value-amount {
}

.salesOverviewSubElement-value-comparison {
    margin-left: 10px;
    display: flex;
    justify-content: flex-end;
}