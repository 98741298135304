.notifications-bell {
    position: relative;
    width: 24px;
    height: 28px;
}

.notifications-bell-counter {
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--color-alert);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    font-size: 14px;
    border-radius: 100%;
}

.notifications-bell:hover {
    cursor: pointer;
}

.notifications-bell-empty {
    text-align: center;
    padding: 20px;
    color: var(--color-gray);
}

.notifications-inbox-before {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.notifications-inbox {
    position: absolute;
    right: 0;
    background-color: var(--color-foreground);
    box-shadow: var(--shadow-wide);
    border-radius: 6px;
    padding: 10px;
    min-width: 300px;
}

.notifications-inbox-msg {
    padding: 10px;
    border-left: 4px solid;
    border-radius: 6px;
    margin-bottom: 6px;
}

.notifications-inbox-msg:last-child {
    margin-bottom: 0;
}

.notifications {
}
