.integrationShopifySuccess-wrapper {
    flex: 0 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.integrationShopifySuccess {
    background-color: var(--color-foreground);
    border-radius: 6px;
    box-shadow: var(--shadow-minimal);
    padding: 20px;
    height: auto;
    max-width: 40%;
}

.integrationShopifySuccess-img {
    display: block;
    width: 300px;
    margin: 0 auto;
}

.integrationShopifySuccess-title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
    text-align: center;
}

.integrationShopifySuccess-text {
    color: var(--color-text_secondary);
    margin-top: 10px;
}