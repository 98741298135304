.productMappingProduct {
    position: relative;
    height: 60px;
    width: calc(50% - 48px);
    display: flex;
    background-color: var(--color-foreground);
    border: 1px solid var(--color-border);
    border-radius: 6px;
    padding: 10px;
    box-sizing: border-box;
    align-items: center;
    gap: 10px;
    z-index: 2;
    transition: box-shadow 0.2s;
}

.productMappingTuple-productWrapper .productMappingProduct,
.productMappingSocket-search-results .productMappingProduct {
    width: 100%;
}

.productMappingProduct__draggable {
    cursor: pointer;
}

.productMappingProduct__draggable {
    box-shadow: var(--shadow-minimal);
}

.productMappingProduct__dragging {
    box-shadow: var(--shadow-wide);
}

.productMappingProduct-variantIndicator {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(142, 130, 239, 0.2);
    border-radius: 6px;
    font-weight: bold;
    color: var(--color-inherited);
    font-size: 16px;
    padding: 4px;
    padding-top: 1px;
    box-sizing: border-box;
}

.productMappingProduct-img {
    width: 40px;
    height: 40px;
    display: block;
    box-sizing: border-box;
    object-fit: contain;
    border-radius: 6px;
    border: 1px solid var(--color-border);
    flex-shrink: 0;
}

.productMappingProduct-img__placeholder {
    padding: 6px;
}

.productMappingProduct-title {
    flex: 2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.productMappingProduct-eanSku {
    flex: 1;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;
    box-sizing: border-box;
}

.global-monospaced-contrast {
}

.productMappingProduct-description {
    flex: 1;
    font-size: 14px;
    color: var(--color-text_secondary);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.productMappingProduct-actions {
    background-color: var(--color-background_light);
    height: calc(100% + 20px);
    margin-top: -10px;
    margin-bottom: -10px;
    margin-right: -10px;
    border-radius: 0 6px 6px 0;
    display: flex;
    align-items: center;
    padding: 0 10px;
}

.productMappingProduct__dragging {
    z-index: 999;
    box-shadow: var(--shadow-wide);
}

.productMappingProduct-drag-icon {
    width: 8px;
    fill: var(--color-text_tertiary);
}

.productMappingProduct-trash-icon {
    width: 10px;
    fill: var(--color-text_tertiary);
    padding: 6px;
    background-color: var(--color-background_light);
    border-radius: 4px;
    transition: background-color 0.2s, fill 0.2s;
}

.productMappingProduct-trash-icon:hover {
    fill: var(--color-text_secondary);
    background-color: var(--color-background);
}

.productMappingProduct:hover .productMappingProduct-trash-icon {
    display: block;
}
