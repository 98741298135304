.shipping {
    position: relative;
}

.shipping-deliveryTime {
    position: relative;
    border-bottom: 1px solid var(--color-border);
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 20px;
    padding: 0 20px 20px;
}

.shipping-deliveryTime-inherited:before {
    position: absolute;
    content: ' ';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--color-inherited);
    opacity: 0.15;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.shipping-deliveryTime-inputGroup {
    position: relative;
    display: flex;
    margin-bottom: 20px;
}

.shipping-deliveryTime-inputGroup:last-child {
    margin-bottom: 0;
}

.shipping-actions {
    display: flex;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: -1px;
}

.shipping-actions-text {
    margin-left: 20px;
    color: var(--color-gray);
    font-size: 14px;
}

.shipping-deliveryTime-inputGroup-inputDelete {
    position: absolute;
    right: 0;
    bottom: 0;
}

.shipping-deliveryTime-inputGroup-inputDelete-icon {
    width: 20px;
    height: 20px;
}
