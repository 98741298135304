.categoryPropertyMatrixRow {

}

.categoryPropertyMatrixRow-title {
    position: sticky;
    left: 0;
    z-index: 1;
}

.categoryPropertyMatrixRow-groups {
}

.categoryPropertyMatrixRow-group {
    white-space: nowrap;
}

.categoryPropertyMatrixRow-title-indicator {
    height: 14px;
    width: 14px;
    fill: var(--color-text_secondary);
    margin-right: 6px;
}

.categoryPropertyMatrixRow-groups-loader {
    animation: loader 1s linear infinite;
}

@keyframes loader {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}