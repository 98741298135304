.positions {
}

.position {
    background-color: var(--color-background_light);
    padding: 10px;
    border-radius: 6px;
    width: 140px;
    box-sizing: border-box;
}

.position-img {
    display: block;
    height: 120px;
    width: 120px;
    padding: 20px;
    box-sizing: border-box;
    object-fit: contain;
    border-radius: 6px;
    border: 1px solid var(--color-border);
    background-color: white;
    margin-bottom: 10px;
}

.position-name {
    font-size: 14px;
    font-weight: bold;
}

.position-qty {
    font-size: 14px;
    color: var(--color-text_secondary);
}

.position-bundle {
    height: 28px;
}

.position-bundle-icon {
    width: 16px;
    height: 16px;
    fill: white;
    padding: 6px;
    background-color: var(--color-inherited);
    border-radius: 4px;
}
