.roles {
}

.roles-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.roles-list-role {
    display: flex;
    align-items: center;
    border: 1px solid var(--color-border);
    border-radius: 6px;
    padding: 10px 20px;
    box-shadow: var(--shadow-minimal);
    margin-right: 10px;
    margin-bottom: 10px;
}

.roles-list-role:hover {
    cursor: pointer;
}

.roles-list-role-children {
    margin-left: 10px;
}

.roles-popup-title {
    font-weight: bold;
    margin-bottom: 20px;
}

.roles-popup-buttons {
    display: flex;
    justify-content: flex-end;
}

.roles-retractable {
    margin-bottom: 10px;
}

.roles-retractable:last-child {
    margin-bottom: 0;
}

.roles-retractable-content {
    padding-left: 10px;
}

.roles-actions {
    margin-top: 20px;
}
