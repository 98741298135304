.sessionHistories {
}

.sessionHistories-list {
    overflow: hidden;
    margin-bottom: 20px;
    background-color: var(--color-background_light);
    padding: 10px;
    border: 1px solid var(--color-border);
    border-radius: 6px;
}

.sessionHistory {
    border: 1px solid var(--color-border);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.1s;
    background-color: var(--color-foreground);
    border-radius: 6px;
    margin-bottom: 10px;
}

.sessionHistory:last-child {
    margin-bottom: 0;
}

.sessionHistory-info {
    display: flex;
}

.sessionHistory-info-name {
    margin-left: 10px;
    max-width: 400px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.sessionHistory:hover {
    cursor: pointer;
    background-color: var(--color-background);
}

.sessionHistory-actions {
    display: flex;
    gap: 10px;
}

.sessionHistories-popup-actions {
    margin-top: 20px;
}

.sessionHistory-entries {
    border-radius: 6px;
    overflow: hidden;
    background-color: var(--color-background_light);
    padding: 10px;
    border: 1px solid var(--color-border);
}

.sessionHistory-entry-info {
    display: flex;
}

.sessionHistory-entry-info-title {
    margin-left: 10px;
}

.sessionHistory-entry-info-comment {
    max-width: 400px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 10px;
    color: var(--color-text_secondary);
}

.sessionHistory-entry-info-comment-pop {
    background-color: var(--color-foreground);
    border: 1px solid var(--color-border);
    border-radius: 6px;
    padding: 10px;
    box-shadow: var(--shadow-wide);
}

.sessionHistory-entry-actions {
    display: flex;
    gap: 10px;
}