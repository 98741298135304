.categoryhierarchy-element-identifier {
    font-size: 14px;
    margin-left: 10px;
}

.categoryhierarchy-element-actions {
    display: none;
    position: absolute;
    top: 6px;
    right: 6px;
}

.categoryhierarchy-element-item:hover .categoryhierarchy-element-actions {
    display: flex;
}

.categoryhierarchy-element-actions-button {
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background-color: white;
}

.global-hierarchy-element-item-badge-hoverPopup {
    display: none;
    position: absolute;
    background-color: var(--color-foreground);
    padding: 20px;
    border-radius: 6px;
    box-shadow: var(--shadow-wide);
    border: 1px solid var(--color-border);
    z-index: 1;
}

.global-hierarchy-element-item-badge:hover > .global-hierarchy-element-item-badge-hoverPopup {
    display: block;
}

.categoryhierarchy-element-actions-buttonWithText {
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background-color: white;
    font-size: 14px;
    font-weight: bold;
    padding: 0 10px;
    margin-right: 20px;
}

.categoryhierarchy-element-actions-button:hover,
.categoryhierarchy-element-actions-buttonWithText:hover {
    cursor: pointer;
}

.categoryhierarchy-element-actions-button-icon {
    width: 12px;
    height: 12px;
}

.categoryhierarchy-actions {
    margin-top: 20px;
    border-top: 1px solid var(--color-border);
    margin-left: -20px;
    margin-right: -20px;
    padding: 20px 20px 0;
}