.areaChartYAxis {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    border-left: 2px solid var(--color-border);
    z-index: 2;
}

.areaChartYAxis-tick {
    background-color: var(--color-foreground);
    padding-left: 6px;
    color: var(--color-text_tertiary);
    font-size: 12px;
    font-weight: bold;
}