.jtce-additionalInformation {
    padding: 10px;
    min-width: 50vw;
    margin: -10px;
    box-sizing: border-box;
}

.jtce-additionalInformation-items {
    max-height: 80vh;
    overflow-y: scroll;
    margin: -20px;
    padding: 20px;
}