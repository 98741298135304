.propertyGroupEdit {

}

.propertyGroupEdit-dangerPopup-message {
    margin-bottom: 20px;
}

.propertyGroupEdit-list {
    max-height: 60vh;
    display: block;
    overflow: scroll;
    margin-left: -20px;
    margin-right: -20px;
    padding: 0 20px;
}

.propertyGroupEdit-list-asset {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 6px;
}

.propertyGroupEdit-list-asset-img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    border: 1px solid var(--color-border);
    box-sizing: border-box;
}

.propertyGroupEdit-list-asset-actions {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    justify-content: center;
    align-items: center;
}

.propertyGroupEdit-list-asset-actions {
    display: flex;
}

.propertyGroupEdit-actions {
    position: relative;
    z-index: 1;
}