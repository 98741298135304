.manufacturerconditions-conditions {
}

.manufacturerconditions-condition {
    display: flex;
    border-bottom: 1px solid var(--color-border);
    margin: 0 -20px;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.manufacturerconditions-condition:first-child {
    padding-top: 0;
}

.manufacturerconditions-condition:last-child {
    border: none;
}

.manufacturerconditions-actions {
    margin-top: 20px;
}

.manufacturerconditions-popup-actions {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid var(--color-border);
    margin: 0 -20px;
    padding: 20px 20px 0;
}

.manufacturerconditions-empty {
    margin-bottom: 20px;
}

.manufacturerconditions-conditions-indent {
    margin-left: 20px;
}
