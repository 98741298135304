.dogtag {
    padding: 10px 20px;
    background-color: var(--color-input_background);
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
    box-shadow: var(--shadow-minimal);
    border: 1px solid var(--color-border);
}

.dogtag:hover {
    cursor: pointer;
}

.dogtag-profileImage {
    margin-left: -10px;
}

.dogtag-meta {
}

.dogtag-meta-name {
    display: flex;
}

.dogtag-meta-name-item {
    margin-right: 6px;
}

.dogtag-meta-name-item:last-child {
    margin-right: 0;
}

.dogtag-meta-tags {
    display: flex;
}

.dogtag-meta-tag {
    margin-right: 4px;
    font-size: 14px;
}

.dogtag-children {
    margin-left: 20px;
}
