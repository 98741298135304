.refundSummary {

}

.refundSummary-titleValue {

}

.refundSummary-statusHistories {
    display: flex;
}