.mockShop {
    top: 20px;
    position: sticky;
    width: 100%;
    color: #222;
}

.mockShop-preview-desktop {
    width: 100%;
    height: 50%;
    max-width: 100%;
    max-height: 500px;
    background-color: white;
    border: 2px solid var(--color-border);
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    padding: 0 60px;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
}

.mockShop-preview-mobile {
    width: 240px;
    height: 480px;
    border: 2px solid var(--color-border);
    border-radius: 20px;
    transition: transform 0.5s;
    background-color: white;
    box-shadow: var(--shadow-wide);
    overflow-x: hidden;
    overflow-y: auto;
}

.mockShop-preview-mobile::-webkit-scrollbar,
.mockShop-preview-desktop::-webkit-scrollbar {
    display: none;
}

.mockShop-d-hero {
    display: flex;
}

.mockShop-d-hero-content {
    flex: 0 0 50%;
}

.mockShop-d-hero-content-logo {
    width: 40px;
}

.mockShop-d-hero-content-brandName {
    font-size: 28px;
    font-weight: bold;
}

.mockShop-d-hero-content-claim {
    font-weight: 200;
    font-size: 12px;
}

.mockShop-d-hero-content-description {
    font-size: 9px;
    padding-right: 20px;
    margin-top: 10px;
}

.mockShop-d-hero-content-description p {
    margin: 0;
}

.mockShop-d-products {
    padding: 0 !important;
    margin-top: 20px;
}

.mockShop-d-product {
    width: 19% !important;
}

.mockShop-d-hero-image {
    flex: 0 0 50%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mockShop-d-hero-image-img {
    width: 100%;
    border-radius: 10px;
    z-index: 1;
}

.mockShop-d-hero-image-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 80px;
    border-radius: 0 0 0 10px;
}

.mockShop-m-phonebar {
    display: flex;
    height: 20px;
    align-items: center;
    background-color: white;
    border-radius: 20px 20px 0 0;
}

.mockShop-m-phonebar-clock {
    flex: 0.25;
    font-size: 12px;
    font-weight: bold;
    box-sizing: border-box;
    padding: 10px 10px 0;
    text-align: center;
}

.mockShop-m-phonebar-nodge {
    flex: 0.5;
    background-color: var(--color-background);
    height: 100%;
    border-radius: 0 0 10px 10px;
}

.mockShop-m-phonebar-signal {
    flex: 0.25;
    box-sizing: border-box;
    padding: 10px 10px 0;
    text-align: center;
}

.mockShop-m-phonebar-signal-img {
    width: 18px;
}

.mockShop-topbar {
    height: 40px;
    background-color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
}

@media only screen and (max-width: 1200px) {
    .mockShop-topbar {
        padding: 0;
    }
}

.mockShop-topbar-els {
    display: flex;
    gap: 4px;
}

.mockShop-topbar-search {
    background-color: #EDEDEDFF;
    width: 180px;
    height: 14px;
    border-radius: 6px;
}

.mockShop-topbar-el {
    background-color: #EDEDEDFF;
    width: 20px;
    height: 14px;
    border-radius: 6px;
}

.mockShop-topbar-logo {
    width: 70px;
}

/* MOBILE */

.mockShop-m-hero {
    width: 100%;
    padding: 10px;
}

.mockShop-m-hero-image {
    position: relative;
}

.mockShop-m-hero-image-img {
    position: relative;
    width: calc(100% - 20px);
    border-radius: 6px;
    z-index: 1;
    margin-top: 10px;
}

.mockShop-m-hero-image-background {
    position: absolute;
    top: 0;
    left: -10px;
    width: 100%;
    height: 70%;
}

.mockShop-m-hero-content {
    flex: 0 0 50%;
    margin-top: 10px;
}

.mockShop-m-hero-content-logo {
    width: 40px;
}

.mockShop-m-hero-content-brandName {
    font-size: 28px;
    font-weight: bold;
}

.mockShop-m-hero-content-claim {
    font-weight: 200;
    font-size: 12px;
}

.mockShop-m-hero-content-description {
    font-size: 9px;
    padding-right: 20px;
    margin-top: 10px;
}

.mockShop-m-hero-content-description p {
    margin: 0;
}

.mockShop-m-products {
    padding: 10px;
}

.mockShop-m-products-els {
    display: flex;
    gap: 6px;
}

.mockShop-m-products-el {
    background-color: #EDEDEDFF;
    width: 40px;
    height: 20px;
    border-radius: 6px;
}

.mockShop-m-products-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
}

.mockShop-d-products-grid {

}

.mockShop-m-product {
    width: 48%;
    height: 140px;
    border-radius: 6px;
    padding: 10px;
    box-sizing: border-box;
    background-color: #EDEDEDFF;
    margin-bottom: 10px;
}