.searchbar-wrapper {
    position: relative;
    width: auto;
}

.searchbar {
    min-width: 240px;
    width: 100%;
    padding: 0 0 0 40px;
    margin: 0 0 0 0;
    box-sizing: border-box;
    border: 1px solid var(--color-border);
    border-radius: 6px;
    background-color: var(--color-background_light);
    font-size: 16px;
    font-family: 'Sofia Pro', sans-serif;
    color: var(--color-text_primary);
    transition: border-color 0.5s, color 0.5s;
}

.searchbar__error {
    border-color: var(--color-danger);
    border-width: 2px;
}

@keyframes bounce {
    0% {
        text-indent: 0;
    }
    25% {
        text-indent: 10px;
    }
    50% {
        text-indent: 0;
    }
    75% {
        text-indent: 10px;
    }
    100% {
        text-indent: 0;
    }
}

input.searchbar__error {
    animation: bounce 0.5s linear;
    color: var(--color-danger);
}

.searchbar input {
    width: 100%;
}

::-webkit-input-placeholder {
    color: var(--color-text_tertiary);
}

::-moz-placeholder {
    color: var(--color-text_tertiary);
}

::placeholder {
    color: var(--color-text_tertiary);
}

.searchbar:focus {
    outline: none;
}

.searchbar-icon {
    position: absolute;
    height: 20px;
    left: 10px;
    top: 10px;
}

.searchbar-actions {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
}

.searchbar-action {
    margin-right: 4px;
}

.searchbar-action:last-child {
    margin: 0;
}

.searchbar-action-icon {
    width: 12px;
    height: 12px;
}
