.roleNameMapper-name {
    display: inline-flex;
    align-items: center;
}

.roleNameMapper-background {
    padding: 2px 6px;
    border-radius: 6px;
}

.roleNameMapper-admin {
    color: var(--color-success);
}

.roleNameMapper-admin-background {
    background-color: rgba(35, 178, 85, 0.1);
}

.roleNameMapper-productOwner {
    color: var(--color-danger);
}

.roleNameMapper-productOwner-background {
    background-color: rgba(234, 66, 17, 0.1);
}

.roleNameMapper-icon {
    width: 14px;
    margin-right: 6px;
}