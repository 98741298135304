.bundlePositionEdit {

}

.bundlePositionEdit-sectionTitle {
    font-weight: bold;
    border-top: 1px solid var(--color-border);
    margin: 0 -20px;
    padding: 20px;
}

.bundlePositionEdit-prices {
    max-height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 0 -20px;
    padding: 0 20px;
}