.productGroupMappings {

}

.productGroupMappings-actions {
    margin-top: 20px;
}

.productGroupMappings-groups {
    display: flex;
}

.productGroupMappings-groups-names {
    background-color: var(--color-foreground);
    box-shadow: var(--shadow-wide);
    border-radius: 6px;
    z-index: 1;
    padding: 10px 16px;
}

.productGroupMappings-groups-name {
    display: flex;
    margin-right: 6px;
    font-size: 14px;
    margin-bottom: 2px;
}