.recurringCustomersFiltering {

}

.recurringCustomersFiltering-emails {
    max-height: 60vh;
    overflow: scroll;
    border: 1px solid var(--color-border);
    border-radius: 6px;
    padding: 4px 10px;
    margin-bottom: 20px;
}

.recurringCustomersFiltering-email {
    font-size: 14px;
    color: var(--color-text_secondary);
}