.tagWithHeaders {
    background-color: var(--color-background_light);
    border-radius: 6px;
    margin-right: 10px;
}

.tagWithHeaders-headers {
    display: flex;
    background-color: var(--color-hintBackground);
    padding: 5px 10px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 0 0 6px 6px;
    font-size: 14px;
    color: var(--color-blue);
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
    border-top: 2px solid var(--color-blue);
}

.tagWithHeaders-header {

}

.tagWithHeaders-header-count {
    background-color: var(--color-blue);
    color: white;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin-left: 6px;
    font-size: 12px;
    font-weight: bold;
    margin-right: -2px;
}

.tagWithHeaders-header-count:hover {
    cursor: pointer;
}

.tagWithHeaders-headers-pop {
    background-color: var(--color-foreground);
    padding: 10px;
    border-radius: 6px;
    box-shadow: var(--shadow-wide);
    color: var(--color-text_primary);
    font-weight: normal;
    border: 1px solid var(--color-border);
}

.tagWithHeaders-tag {
    padding: 5px 10px;
    display: flex;
    align-items: center;
}

.tagWithHeaders-tag-icon {
    width: 14px;
    height: 14px;
    margin-right: 6px;
}