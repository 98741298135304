.input-cell {
    height: 100%;
    transition: background-color 0.1s;
}

.input-cell__completed {
    background-color: rgba(35, 178, 85, 0.1);
}

.input-cell__missing {
    background-color: rgba(234, 66, 17, 0.2);
}

.input-cell-input {
    border: none;
    padding: 10px;
    font-size: 14px;
    width: calc(100% - 12px);
    height: 39px;
    box-sizing: border-box;
    background-color: transparent;
    color: inherit;
    font-family: "Sofia Pro", sans-serif;
    pointer-events: none;
    margin: 6px;
    border-radius: 6px;
}

.input-cell-input:focus {
    outline: none;
    background-color: var(--color-foreground);
}

.input-cell-input__error {

}