.purchaseorderstate {

}

.purchaseorderstate-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.purchaseorderstate-badge {
  margin-bottom: 10px;
}

.purchaseorderstate-description {
  color: var(--color-text_secondary)
}

.purchaseorderstate-actions {
  margin-top: 20px;
}