.statemanagement {
}

.statemanagement-actions > div:first-child {
    margin-bottom: 10px;
}

.statemanagement-popupActions {
    margin-top: -1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.statemanagement-parentActiveVersion {
    margin-bottom: 20px;
    display: inline-block;
}

.statemanagement-parentActiveVersion:hover {
    cursor: pointer;
}

.statemanagement-parentActiveVersion-title {
    margin-bottom: 10px;
    color: var(--color-text_secondary);
    display: flex;
}

.statemanagement-parentActiveVersion-dogtag {
    display: flex;
    padding: 10px;
    border: 1px solid var(--color-border);
    box-shadow: var(--shadow-minimal);
    border-radius: 6px;
    align-items: center;
}

.statemanagement-parentActiveVersion-dogtag-indicator {
    position: relative;
    width: 14px;
    height: 14px;
    background-color: var(--color-success);
    border-radius: 100%;
    margin-left: 10px;
}

.statemanagement-parentActiveVersion-dogtag-info {
    margin-left: 20px;
}

.statemanagement-parentActiveVersion-dogtag-info-title {
    font-weight: bold;
}

.statemanagement-parentActiveVersion-dogtag-info-info {
    font-size: 14px;
    color: var(--color-text_secondary);
}

.statemanagement-parentActiveVersion-dogtag-link {
    width: 16px;
    height: 16px;
    margin-left: 20px;
    margin-right: 10px;
}

.statemanagement-progressbar-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 999;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.statemanagement-progressbar {
    width: 100%;
    padding-right: 20px;
    background-color: var(--color-foreground);
    border-radius: 6px;
    animation: swoopIn 0.5s;
}

@keyframes swoopIn {
    from {
        opacity: 0;
        margin-top: 20px;
    }
    to {
        opacity: 1;
        margin-top: 0;
    }
}

.statemanagement-progressbar-bar {
    height: 8px;
    background-color: var(--color-background);
    border-radius: 8px;
}

.statemanagement-progressbar-bar-progress {
    height: 8px;
    background-color: var(--color-blue);
    border-radius: 8px;
    transition: width 1s;
}

.statemanagement-popupActions-toggle {
    margin-bottom: 10px;
}
