.bulletpoints {
}

.bulletpoints-entries {
  margin-top: 20px;
  margin-bottom: 20px;
}

.bulletpoints-entry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.bulletpoints-entry-number {
  font-size: 18px;
  margin-right: 10px;
  width: 40px;
}

.bulletpoints-entry-delete {
  margin-left: 10px;
}

.bulletpoints-entry-input {
  width: 100%;
}

.bulletpoints-actions {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--color-border);
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px 20px 0px 20px;
}