.jtce-richText {
}

.jtce-richText-tabs {
    margin-left: -20px;
    margin-right: -20px;
    max-height: 60vh;
    overflow-y: scroll;
}

.jtce-richText-input {
    padding: 20px 20px 0;
}