.orderList {
}

.orderList-tab {
    margin-top: 20px;
}

.orderList-customerName {
    display: flex;
    align-items: center;
}

.orderList-customerName-icon {
    margin-left: 10px;
    height: 14px;
    width: 14px;
}

.orderList-manufacturerInfo {
    display: flex;
    align-items: center;
}

.orderList-manufacturerInfo-name {
    font-size: 16px;
    width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.orderList-manufacturerInfo-portrait {
    width: 40px;
    height: 40px;
    border: 1px solid var(--color-border);
    border-radius: 100%;
    margin-right: 6px;
    background-color: white;
}

.list-table-tr__compact .orderList-manufacturerInfo-portrait {
    width: 30px;
    height: 30px;
}
