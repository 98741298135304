.supportInfo {
}

.supportInfo-block {
    margin-bottom: 20px;
}

.supportInfo-block:last-child {
    margin-bottom: 0;
}

.supportInfo-block-title {
    font-weight: bold;
    margin-bottom: 10px;
}

.supportInfo-block-description {
}

.supportInfo-block-text {
    margin-top: 10px;
    color: var(--color-text_secondary);
}

.supportInfo-block-phone {
    display: flex;
    align-items: center;
}

.supportInfo-block-phone-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.supportInfo-block-call {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.supportInfo-block-call-img {
    width: 100px;
    margin-right: 20px;
}
