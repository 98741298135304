.notificationSubscriptions {

}

.notificationSubscriptions-text {
    margin-bottom: 20px;
}

.notificationSubscriptions-notifications {
    margin-bottom: 20px;
}

.notificationSubscriptions-notification {
    margin-bottom: 10px;
}

.notificationSubscriptions-notification:last-child {
    margin-bottom: 0;
}