.memberedit {
}

.memberedit-roles-wrapper {
    margin-bottom: 20px;
}

.memberedit-roles-title {
    margin-bottom: 10px;
}

.memberedit-roles {
    display: flex;
}

.memberedit-roles-list {
    display: flex;
}

.memberedit-roles-list-role {
    padding: 10px 20px;
    background-color: var(--color-background);
    border-radius: 4px;
    margin-right: 10px;
    display: flex;
}

.memberedit-roles-list-role-remove {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
}

.memberedit-roles-list-role-remove:hover {
    cursor: pointer;
}

.memberedit-roles-list-role-remove-img {
    width: 10px;
    height: 10px;
}
