.shopifyProductImport {

}

.shopifyProductImport-actions {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.shopifyProductImport-dataProcessingImg {
    width: 240px;
    display: block;
    margin: 0 auto 20px;
}