.notification {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 999;
    border-radius: 6px;
    border: 1px solid var(--color-border);
    background-color: var(--color-foreground);
    color: white;
    padding: 20px;
    font-size: 14px;
    display: flex;
}

.notification-body {

}

.notification-title {
    font-weight: bold;
    margin-bottom: 10px;
}

.notification-text {
    max-width: 300px;
}

.notification-children {
    margin-top: 10px;
}

.notification-button {
    background-color: rgba(255, 255, 255, 0.15);
    transition: background-color 0.1s;
    border-radius: 4px;
    padding: 4px 10px;
    font-weight: bold;
    display: inline-block;
    cursor: pointer;
}

.notification-button:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

.notification-button:active {
    background-color: rgba(255, 255, 255, 0.2);
}

.notification-checkbox {
    margin-top: 10px;
    margin-bottom: 10px;
}

.notification-checkbox .check-text {
    color: var(--color-foreground) !important;
}

.notification-actions {
    margin-left: 20px;
}

.notification-closeIcon {
    width: 10px;
    height: 10px;
    cursor: pointer;
    align-self: flex-end;
    margin-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.15);
    padding: 6px;
    border-radius: 4px;
    transition: background-color 0.1s;
}

.notification-closeIcon:hover {
    background-color: rgba(255, 255, 255, 0.3);
}