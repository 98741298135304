.fulleditor {

}

@media only screen and (min-width: 1200px) {
    .fulleditor {
        min-width: 500px;
    }
}

.fulleditor-title {
    color: var(--color-text_secondary);
    margin-bottom: 10px;
}

.fulleditor-title {
    color: var(--color-inherited)
}

.tox-tinymce {
    border: 1px solid var(--color-border) !important;
    border-radius: 6px !important;
    font-family: "Sofia Pro", sans-serif !important;
}

.tox-collection__item-label,
.tox-collection__item-label * {
    font-family: "Sofia Pro", sans-serif !important;
}

.tox-menu,
.tox-collection,
.tox-collection--list,
.tox-selected-menu,
.tox-background-menu,
.tox-collection__group,
.tox-collection__item,
.mce-content-body,
.tox-edit-area__iframe {
    font-family: "Sofia Pro", sans-serif !important;
}

.fulleditor-inherited .tox-tinymce {
    border-color: var(--color-inherited) !important;
}

.fulleditor-error .tox-tinymce {
    border: 1px solid var(--color-danger) !important;
    border-radius: 6px !important;
}

.tox-editor-header {
    box-shadow: none !important;
    border-bottom: 1px solid var(--color-border) !important;
}

.tox-editor-header *,
.tox-toolbar-overlord * {
    color: var(--color-text_secondary) !important;
}

.tox .tox-tbtn svg {
    fill: var(--color-text_primary) !important;
}

.tox .tox-tbtn--disabled svg,
.tox .tox-tbtn--disabled:hover svg,
.tox .tox-tbtn:disabled svg,
.tox .tox-tbtn:disabled:hover svg {
    fill: var(--color-text_tertiary) !important;
}

.tox .tox-tbtn:hover,
.tox .tox-mbtn:hover,
.tox .tox-mbtn__select-label:hover {
    cursor: pointer !important;
}

.tox-editor-header, .tox-editor-header div:not(.tox-tbtn__select-chevron) {
    background-color: var(--color-background_light) !important;
}

.tox-promotion, .tox-statusbar__branding {
    display: none;
}

.tox-dialog-wrap__backdrop {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.tox .tox-edit-area__iframe,
.mce-content-body {
    background-color: var(--color-foreground) !important;
    color: var(--color-text_primary) !important;
}

.tox .tox-statusbar {
    background-color: var(--color-background_light) !important;
    border-color: var(--color-border) !important;
}

.tox .tox-statusbar a,
.tox .tox-statusbar__path-item,
.tox .tox-statusbar__wordcount {
    color: var(--color-text_tertiary) !important;
}

.tox .tox-tbtn--bespoke {
    background-color: var(--color-foreground) !important;
}

.tox .tox-menu {
    background-color: var(--color-input_background) !important;
    box-shadow: var(--shadow-wide) !important;
}

.tox .tox-collection__item {
    color: var(--color-text_primary) !important;
}

.tox .tox-collection--list .tox-collection__item--active:not(.tox-collection__item--state-disabled) {
    color: var(--color-text_primary) !important;
}

.tox .tox-collection__item-caret svg {
    fill: var(--color-text_primary) !important;
}

.tox .tox-collection--list .tox-collection__item--active,
.tox .tox-collection--list .tox-collection__item--enabled {
    background-color: var(--color-background_light) !important;
}

.tox-tinymce-aux {
    z-index: 99999 !important;
}
