.card {
    position: relative;
    border-radius: 6px;
    box-shadow: var(--shadow-minimal);
    box-sizing: border-box;
    margin: 0 auto 20px;
    background-color: var(--color-foreground);
    width: 100%;
    display: flex;
    flex-direction: column;
}

.card-productOwnerOverride {

}

.card-safeguard {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-safeguard:before {
    backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, 0.075);
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 1;
    transition: opacity 0.3s;
    transition-delay: 0.3s;
}

.card-safeguard-exit.card-safeguard:before,
.card-safeguard-exit > .card-safeguard-box {
    opacity: 0;
}

.card-safeguard-box {
    background-color: var(--color-foreground);
    border-radius: 6px;
    box-shadow: var(--shadow-wide);
    display: flex;
    align-items: center;
    border: 2px solid var(--color-danger);
    transition: margin-top 0.3s, opacity 0.3s;
    transition-delay: 0.3s;
    z-index: 1;
    opacity: 1;
}

.card-safeguard-exit > .card-safeguard-box {
    margin-top: -60px;
}

.card-safeguard-box-switch {
    padding: 20px;
}

.card-safeguard-box-icon {
    display: block;
    width: 16px;
    height: 60px;
    fill: white;
    background-color: var(--color-danger);
    padding: 6px;
    cursor: pointer;
    z-index: 1000;
}

@media only screen and (max-width: 1200px) {
    .card {
        width: 100% !important;
    }
}

.card-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.card-title > div {
    display: flex;
    align-items: center;
}

.card-title-validation:hover {
    cursor: pointer;
}

.card-title-validation:before {
    content: "";
    position: absolute;
    width: 38px;
    height: 38px;
    top: 0;
    left: 0;
    margin: -10px;
    background-color: rgba(255, 126, 31, 0.1);
    border-radius: 6px;
}


.card-title-validation-icon {
    position: relative;
    width: 18px;
    height: 18px;
    margin-right: 2px;
}

.card-actions {
    position: absolute;
    padding: 20px;
    top: 0;
    right: 0;
}

.card-action {
    color: var(--color-blue);
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.5px;
}

.card-action[aria-disabled=true] {
    color: var(--color-text_tertiary)
}

.card-action:hover {
    cursor: pointer;
}

.card-header {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 0;
}

.card-header-info {
    display: flex;
}

.card-header-info-title {
    font-size: 16px;
    font-weight: bold;
    color: var(--color-text_primary)
}

.card-header-info-badge {
    margin-left: 10px;
}

.card-header-action {
    font-weight: bold;
    font-size: var(--size-text_small);
    color: var(--color-blue);
    letter-spacing: var(--spacing-text_action);
    white-space: nowrap;
    align-self: flex-start;
}

.card-header-action:hover {
    cursor: pointer;
}

.card-content {
    padding: 0 20px 20px;
}

.card-section {
    position: relative;
    padding: 20px;
    border-bottom: 1px solid var(--color-border);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.card-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.card-section-divider {
    height: 1px;
    width: 100%;
    border-top: 1px solid var(--color-border);
    margin-left: -20px;
    margin-right: -20px;
}

.card-section-info-noTitle {
    display: flex;
}

.card-section-info {
    display: flex;
    margin-bottom: 20px;
}

.card-section-info-title {
    font-weight: bold;
}

.card-section-noTop {
    padding: 0 20px 20px;
    border-bottom: 1px solid var(--color-border);
}

.card-section:last-child {
    border-bottom: 0;
}

.inlineCards {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: stretch;
    flex-direction: var(--layout-responsiveFlexDirection);
}

@media only screen and (min-width: 1200px) {
    .inlineCards .card {
        margin: 0 10px !important;
    }

    .inlineCards .card:first-child {
        margin-left: 0 !important;
    }

    .inlineCards .card:last-child {
        margin-right: 0 !important;
    }
}

.card-tabs {
    display: flex;
    min-height: 50px;
    align-items: center;
    border-bottom: 1px solid var(--color-border);
    padding-left: 20px;
    flex-wrap: wrap;
    background-color: var(--color-foreground);
}

.card-tabs-cardStyle {
    border: none;
    background-color: var(--color-foreground);;
    border-radius: 6px;
    box-shadow: var(--shadow-minimal);
    margin-bottom: 20px;
}

.card-tabs-noMargin {
    margin-left: -20px;
    margin-right: -20px;
}

.card-tabs-tab {
    height: 50px;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    color: var(--color-gray);
}

.card-tabs-tab-error {
    color: #e66742;
}

.card-tabs-tab-badge {
    margin-left: 10px;
}

.card-tabs-tab-errorIcon {
    width: 14px;
    height: 14px;
    margin-left: 4px;
}

.card-tabs-tab:hover {
    cursor: pointer;
}

.card-tabs-tab-active {
    font-weight: bold;
    color: var(--color-text_primary);
}

.card-tabs-tab-active-error {
    font-weight: bold;
    color: var(--color-danger);
}

.card-tabs-tab-active:after,
.card-tabs-tab-active-error:after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 4px;
    border-radius: 10px 10px 0 0;
    background-color: var(--color-text_primary);
}

.card-tabs-tab-active-error:after {
    background-color: var(--color-danger);
}

.card-tabs-wrapper {
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -10px;
}

.card-tab-content {
    padding: 20px;
}

.card-tabs-popup-cta {
    margin-bottom: 10px;
}

.helpercard {
    box-shadow: none;
    border-bottom: none;
    background-color: var(--color-hintBackground);
    font-size: 14px;
    color: var(--color-blue);
    padding: 12px;
}

.helpercard-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.helpercard-content-column {
    display: flex;
}

.helpercard-content-button {
    font-size: 14px;
    background-color: var(--color-blue);
    color: white;
    font-weight: bold;
    padding: 4px 10px;
    border-radius: 4px;
}

.helpercard-content-button:hover {
    cursor: pointer;
}

.helpercard-content-dontShowAgain {
    opacity: 0.3;
    width: 10px;
    margin-right: 10px;
}

.helpercard-content-dontShowAgain:hover {
    cursor: pointer;
}
