.teammembers {
}

.teammembers-retractable {
  margin-bottom: 10px;
}

.teammembers-retractable:last-child {
  margin-bottom: 0;
}

.teammembers-actions {
  margin-top: 20px;
}

.teammembers-members {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.teammembers-members-retractale {
  padding-left: 10px;
}

.teammembers-popup-title {
  font-weight: bold;
  margin-bottom: 20px;
}

.teammembers-popup-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
