.rankOverview {

}

.rankOverview-icon-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
}

.rankOverview-icon {
    width: 30%;
    margin: 0 auto;
}

.rankOverview-emptyIcon {
    width: 50%;
    margin: 0 auto;
}

.rankOverview-name {
    text-align: center;
    font-size: 18px;
}

.rankOverview-button {
    margin-top: 20px;
}