.newreturn {
}

.newreturn-quantityInput {
  width: 60px;
}

.newreturn-dropdown {
  width: 180px;
}

.newreturn-text {
  margin-bottom: 20px;
}

.newreturn-actions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.newreturn-actions-two {
  border-top: 1px solid var(--color-border);
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px;
  padding-bottom: 0;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
