.deliveryTimesEditor {
    padding: 10px;
}

.deliveryTimesEditor-shipping {
    max-height: 60vh;
    overflow-y: scroll;
    margin: 0 -20px;
    padding: 0 20px;
    min-width: 400px;
}

.deliveryTimesEditor-shipping-new {
    margin-top: 20px;
}