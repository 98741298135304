.propertyBrowser-wrapper {
    border: 1px solid var(--color-border);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
}

.propertyBrowser-groups {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.propertyBrowser-groups:has(+ .propertyBrowser-values) {
    border-right: 1px solid var(--color-border);
}

.propertyBrowser-groups-search {
    border-bottom: 1px solid var(--color-border);
    padding: 10px;
    background-color: var(--color-background_light);
    border-radius: 6px 0 0 0;
}

.propertyBrowser-actions {
    background-color: var(--color-background_light);
    padding: 10px;
    border-radius: 0 0 6px 6px;
    border-top: 1px solid var(--color-border);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.propertyBrowser {
    display: flex;
    min-width: 200px;
    max-height: 50vh;
}

.propertyBrowser-emptyState {
    width: 100%;
    padding: 20px;
}

.propertyBrowser-containers {
    min-width: 300px;
    overflow-y: scroll;
    overflow-x: visible;
    padding: 10px;
    flex: 4 1;
}

.propertyBrowser-container {
    padding: 10px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    min-width: 220px;
    align-items: center;
}

.propertyBrowser-container-title {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.propertyBrowser-container-title > span {
    margin-left: 10px;
    font-size: var(--size-text_small);
    color: var(--color-text_tertiary);
}

.propertyBrowser-container-title-selector {
    margin-right: 10px;
}

.propertyBrowser-container-badge {
    margin-left: 10px;
}

.propertyBrowser-container:hover {
    cursor: pointer;
}

.propertyBrowser-values {
    position: relative;
    flex: 1;
    padding: 10px;
    width: 400px;
    display: flex;
    flex-direction: column;
}

.propertyBrowser-values-search {
    border-bottom: 1px solid var(--color-border);
    padding: 10px;
    margin: -10px -10px 0;
    background-color: var(--color-background_light);
    border-radius: 0 6px 0 0;
}

.propertyBrowser-values-list {
    overflow: scroll;
    flex: 4;
}

.propertyBrowser-value {
    padding: 10px;
    display: flex;
    align-items: center;
}

.propertyBrowser-values-button {
    position: relative;
    background-color: var(--color-foreground);
}

.propertyBrowser-values-info {
    padding: 20px;
    background-color: var(--color-background_light);
    font-size: 14px;
    border-radius: 6px;
    color: var(--color-text_secondary);
    margin: 10px;
}

.propertyBrowser-value-selector {
    margin-right: 10px;
}

.propertyBrowser-value-title {
}

.properties-popup-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.propertyBrowser-prohibited {
    fill: var(--color-background);
    width: 200px;
    height: 200px;
    margin: 20px auto;
}