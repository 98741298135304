.pricesCell {
    display: flex;
}

.pricesCell-price {
    display: flex;
    font-size: 14px;
    background-color: var(--color-background_light);
    margin-right: 6px;
    border-radius: 4px;
    padding: 0 8px;
    border: 1px solid var(--color-border);
    align-items: center;
}

.pricesCell-price:hover {
    cursor: pointer;
}

.pricesCell-price__selected {
    outline: 2px solid var(--color-blue);
    border-color: transparent;
}

.pricesCell-price-el {
    padding: 2px;
}

.pricesCell-price-el-list {
    color: var(--color-primary);
    font-weight: bold;
}

.pricesCell-price-el-series {
    display: flex;
    font-size: 12px;
    align-items: center;
    background-color: var(--color-yellow);
    color: black;
    font-weight: bold;
    padding: 0 4px;
    box-sizing: border-box;
    border-radius: 4px;
    margin-left: 4px;
}

.pricesCell-price-el-series-arrow {
    display: block;
    width: 10px;
    height: 10px;
    margin: 0 2px;
}