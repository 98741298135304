.refundSummaryStatusHistory {
    margin-right: 20px;
    margin-top: 20px;
}

.refundSummaryStatusHistory-history {
    border-left: 1px solid var(--color-border);
    padding-left: 20px;
}

.refundSummaryStatusHistory-title {
    margin-bottom: 10px;
    font-weight: bold;
}

.refundSummaryStatusHistory-status {
    display: flex;
    margin-bottom: 10px;
}

.refundSummaryStatusHistory-status-name {

}

.refundSummaryStatusHistory-status-date {
    color: var(--color-text_secondary);
    font-size: 14px;
    margin-left: 10px;
}