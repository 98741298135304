.clusterlist {

}

.clusterlist-cluster {
    margin-bottom: 10px;
}

.clusterlist-cluster:last-child {
    margin-bottom: 0;
}

.clusterlist-cluster-head {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.clusterlist-cluster-head-title {
    display: block;
    align-items: center;
    width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.clusterlist-cluster-head-element {
    margin-left: 20px;
    display: flex;
    align-items: center;
}

.clusterlist-cluster-head-hoverText-anchor {
    position: relative;
}

.clusterlist-cluster-head-hoverText .clusterlist-cluster-head-hoverText-content {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.2s;
    overflow: visible;
    background-color: var(--color-foreground);
    color: var(--color-text_primary);
    display: inline-block;
    white-space: nowrap;
}

.clusterlist-cluster-head-hoverText:hover .clusterlist-cluster-head-hoverText-content {
    visibility: visible;
    opacity: 1;
}

.clusterlist-cluster-head-element-group {
    display: flex;
    align-items: center;
}

.clusterlist-cluster-head-element-spacer {
    padding: 0 6px;
}

.clusterlist-cluster-head-element-secondary {
    margin-left: 20px;
    display: flex;
    align-items: center;
    color: var(--color-text_secondary)
}

.clusterlist-cluster-head-element-icon {
    height: 22px;
    width: 22px;
}

.clusterlist-cluster-head-element-iconSmall {
    height: 10px;
    width: 10px;
    margin-left: 4px;
}

.clusterlist-cluster-list {
    padding: 0 20px;
}