.hexColorAsset {

}

.hexColorAsset-info {
    background-color: var(--color-background);
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    border-radius: 6px;
    color: var(--color-text_secondary);
}

.hexColorAsset-container {
    display: flex;
    align-items: center;
}

.hexColorAsset-colorPicker {
    flex: 1;
}

.hexColorAsset-button {
    margin-left: 4px;
}

.popup-colorPickers {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.popup-colorPicker {
    display: flex;
    align-items: flex-end;
    flex: 1;
}

.popup-colorPicker-button {
    margin-left: 4px;
}

.popup-preview {
    padding: 20px 0;
}

.popup-preview canvas {
    border-radius: 6px;
}