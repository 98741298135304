.stringArrayTable tr {
    position: relative !important;
}

.table-wrapper {
    display: flex;
    overflow-x: scroll;
    overflow-y: visible;
}

.table-icon {
    width: 10px;
    height: 10px;
}

.table-addColumn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-border);
    border-radius: 6px;
    box-shadow: var(--shadow-minimal);
    width: 20px;
    margin-left: 10px;
    flex-shrink: 0;
    height: 40px;
}

.table-addRow {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-border);
    border-radius: 6px;
    box-shadow: var(--shadow-minimal);
    height: 20px;
    margin-top: 10px;
    width: 40px;
}

.table-addColumn:hover,
.table-addRow:hover {
    background-color: var(--color-background);
    cursor: pointer;
}

.table, .table th, .table td {
    border-collapse: collapse;
    border: 1px solid var(--color-border);
}

.table th, .table td {
    height: 20px;
    padding: 0;
}

.table th {
    background-color: var(--color-background_light);
}

.table th .table-input {
    font-weight: bold;
    background-color: var(--color-background_light);
}

.table-column-header {
    position: relative;
    display: flex;
    align-items: center;
}

.table-row-options,
.table-column-header-options {
    display: flex;
    width: 14px;
    height: 14px;
    align-items: center;
    justify-content: center;
    padding: 6px;
    border-radius: 6px;
    margin-right: 6px;
}

.table-row-options:hover,
.table-column-header-options:hover {
    cursor: pointer;
    background-color: var(--color-background);
}

.table-row-options-wrapper,
.table-column-header-options-wrapper {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 28px;
    align-items: center;
    background-color: var(--color-background_light);
}

.table-column-header:hover > .table-column-header-options-wrapper {
    display: flex;
}

.cell-index:hover > .table-row-options-wrapper {
    display: flex;
}

.table-input {
    border: none;
    padding: 10px;
    font-size: 14px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: var(--color-input_background);
    color: var(--color-text_primary);
    font-family: "Sofia Pro", sans-serif;
}

.table-input:focus {
    outline: 1px solid var(--color-select);
}

.table-input_error,
.table-input_error:focus {
    outline: 1px solid var(--color-danger);
    background-color: rgba(255, 0, 0, 0.1);
}

.column-menu {
    position: absolute;
    background: var(--color-foreground);
    z-index: 999;
    top: 20px;
    border-radius: 6px;
    box-shadow: var(--shadow-wide);
    overflow: hidden;
}

.column-menu-action {
    font-family: "Sofia Pro", sans-serif;
    color: var(--color-text_primary);
    white-space: nowrap;
    font-weight: normal;
    font-size: 14px;
    padding: 6px 10px;
    border-bottom: 1px solid var(--color-border);
}

.column-menu-action:last-child {
    border-bottom: none;
}

.column-menu-action:hover {
    cursor: pointer;
    background-color: var(--color-background_light);
}

.column-menu-action-danger {
    color: var(--color-danger)
}

.cell-index {
    position: relative;
    width: 80px;
    text-align: center;
    font-size: 14px;
    color: var(--color-text_tertiary);
    background-color: var(--color-background_light);
}

.cell-index-content {
    display: flex;
    align-items: center;
    border-left: 1px solid var(--color-border);
    height: 100%;
}

.cell-index-content-mode {
    font-family: "Sofia Pro", sans-serif;
    font-size: 14px;
    font-weight: bold;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-left: 10px;
}

.cell-index-content-mode-create {
    color: var(--color-success);
}

.cell-index-content-mode-update {
    color: #ff9200;
}

.cell-index-content-check {
    margin-left: 0px;
}

.cell-index-content-number {
    border-radius: 4px;
    width: 20px;
    padding: 4px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px
}