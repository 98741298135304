.jsonTableSearch {

}

.jsonTableSearch-searchableHeaders {
    margin-bottom: 20px;
    width: 100%;
}

.jsonTableSearch-searchableHeader {

}

.jsonTableSearch-searchableHeader-remove {

}

.jsonTableSearch-setting {
    margin-top: 20px;
}