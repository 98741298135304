.jtce-media {
    height: 100%;
    display: flex;
}

.jtce-media-asset {
    width: 30px;
    height: 30px;
    object-fit: contain;
    background-color: var(--color-foreground);
    margin-right: 4px;
    border-radius: 4px;
    border: 1px solid var(--color-border);
    box-sizing: border-box;
}

.jtce-media-asset__selected {
    outline: 2px solid var(--color-blue);
    border-color: transparent;
}

.jtce-media-asset:last-child {
    margin-right: 0;
}