.purchaseorderPositionImport {

}

.purchaseorderPositionImport-result {
    width: 100%;
    text-align: center;
    color: var(--color-text_secondary);
    margin-bottom: -20px;
    display: flex;
    align-items: center;
}

.purchaseorderPositionImport-actions {
    margin-top: 20px;
    width: 100%;
}

.purchaseorderPositionImport-error {
    color: var(--color-danger);
    font-size: 14px;
    background-color: rgba(234, 66, 17, 0.2);
    margin-bottom: 4px;
    padding: 4px 6px;
    border-radius: 4px;
}

.purchaseorderPositionImport-error:last-child {
    margin-bottom: 0;
}

.purchaseorderPositionImport-error div {
    display: inline;
    margin-right: 10px;
    font-family: 'Inconsolata', monospace;
}

.purchaseorderPositionImport-error span {
    font-family: 'Inconsolata', monospace;
    color: rgb(225, 106, 72);
    padding: 2px 4px;
    border-radius: 4px;
}