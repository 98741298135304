.categories {
}

.categories-title {
    margin-bottom: 10px;
    color: var(--color-text_secondary);
}

.categories-title-inherited {
    margin-bottom: 10px;
    color: var(--color-inherited);
}

.categories-categories {
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.categories-category {
    margin-bottom: 10px;
}

.categories-category-inherited {
    color: var(--color-inherited);
}

.categories-popup-title {
    font-weight: bold;
    margin-bottom: 20px;
}

.categories-actions {
    margin-top: -10px;
}

.categories-popup-actions {
    margin-top: 20px;
    margin-left: -20px;
    margin-right: -20px;
    padding-top: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid var(--color-border);
}
