.inpettoClientBasedSuccess {

}

.inpettoClientBasedSuccess-img {
    width: 200px;
}

.inpettoClientBasedSuccess-message {
    margin-top: 20px;
}