.labeltext {
}

.labeltext-title {
    margin-bottom: 10px;
    color: var(--color-text_secondary);
}

.labeltext-content {
    border-bottom: 1px solid var(--color-border);
    height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    white-space: nowrap;
}
