.jtce-pills {
    display: flex;
    cursor: pointer;
}

.jtce-pill {
    font-size: 14px;
    display: flex;
    white-space: nowrap;
    background-color: var(--color-background_light);
    border-radius: 100px;
    margin-right: 6px;
    align-items: center;
    padding: 0 10px;
    border: 1px solid var(--color-border);
}

.jtce-pill:last-child {
    margin-right: 0;
}

.jtce-pill-capsule {
    font-size: 12px;
    font-weight: bold;
    padding-right: 4px;
    margin-right: 4px;
    color: var(--color-text_secondary);
}