.productMapping {
}

.popup-title {
}

.productMapping-list {
    border-radius: 6px;
    border: 1px solid var(--color-border);
}

.productMapping-list-header {

}

.productMapping-list-header-main {
    position: relative;
    display: flex;
    padding: 10px;
    justify-content: space-between;
}

.productMapping-list-header-main-group {
    display: flex;
    gap: 20px;
    align-items: center;
}

.productMapping-list-header-sub {
    border-top: 1px solid var(--color-border);
    border-bottom: 1px solid var(--color-border);
    display: flex;
    padding: 0 10px;
    height: 40px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
}

.productMapping-list-header-sub-half {
    display: flex;
    justify-content: space-between;
    width: calc(50% - 30px);
    box-sizing: border-box;
}

.productMapping-list-header-sub-half__right {
    padding-left: 30px;
}

.productMapping-list-header-sub-half-title {
    font-size: 16px;
    color: var(--color-text_secondary);
    font-weight: bold;
}

.productMapping-list-header-sub-half-selectAll {
    display: flex;
}

.productMapping-list-header-sub-half-selectAll-count {
    margin-left: 10px;
    font-size: 14px;
    color: var(--color-save)
}

.productMapping-list-tuples-wrapper {
    margin-left: -20px;
    margin-right: -20px;
    padding: 0 20px;
    height: 70vh;
    overflow-y: auto;
    border-radius: 6px;
}

.productMapping-list-tuples {
    background: var(--color-background_light);
    border-radius: 0 0 6px 6px;
}

.productMapping-list-tuples-noResults {
    background-color: var(--color-foreground);
}