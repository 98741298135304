.input-field-wrapper {
    display: flex;
    width: 100%;
    gap: 10px;
}

.input-field {
    position: relative;
    height: 40px;
    border-radius: 6px;
    border: 1px solid var(--color-input_border);
    display: flex;
    align-items: center;
    padding: 0 8px;
    background-color: var(--color-input_background);
    box-sizing: border-box;
    flex: 1;
}

.input-field-hoverable:hover {
    cursor: pointer;
}

.input-field:focus-within > .input-field-focusOutline {
    display: block;
}

.input-field-focusOutline {
    display: none;
    position: absolute;
    top: -2px;
    left: -2px;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    border: 2px solid var(--color-blue);
}

.input-field-prefix {
    height: 100%;
    margin-left: -8px;
    white-space: nowrap;
    border-radius: 6px 0 0 6px;
    padding: 0 10px;
    box-sizing: border-box;
    border-right: 1px solid var(--color-input_border);
    display: flex;
    align-items: center;
    margin-right: 6px;
}

.input-field-inherited,
.input-field-locked {
    color: var(--color-inherited);
}

.input-field-inherited > input::placeholder {
    color: var(--color-inherited);
    opacity: 0.5;
}

.input-field-inactive {
    background-color: var(--color-background);
    color: var(--color-text_tertiary)
}

.input-field-error {
    border-color: var(--color-danger);
    color: var(--color-danger);
    font-size: 14px;
}

.input-field-error-msgs {
    padding: 10px 6px 6px;
    background-color: rgba(234, 66, 17, 0.1);
    margin-top: -6px;
    border-radius: 0 0 6px 6px;
}

.input-field-error-msg {
    color: var(--color-danger);
    font-size: 12px;
}

.textarea {
    margin-bottom: 20px;
}

.input-title,
.textarea-title,
.richtext-title {
    font-size: 16px;
    color: var(--color-text_secondary);
}

.input-title-inherited {
    font-size: 16px;
    color: var(--color-inherited);
}

.input-info {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}

.input-input {
    border-radius: 6px;
    height: 100%;
    width: 100%;
    font-family: 'Sofia Pro', sans-serif;
    font-size: 16px;
    box-sizing: border-box;
    color: var(--color-text_primary);
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
}

.input-input:focus {
    outline: none;
}

.input-justSaved {
    animation: justSaved 0.5s;
}

@keyframes justSaved {
    0% {
        outline: 1px solid var(--color-blue);
        border-color: var(--color-blue);
    }
    10% {
        outline: 1px solid var(--color-success);
        border-color: var(--color-success);
    }
    100% {
        outline: 1px solid transparent;
        border-color: var(--color-border);
    }
}

.input-unit {
    min-width: 50px;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-background_light);
    border-radius: 0 6px 6px 0;
    border-left: 1px solid var(--color-border);
    font-size: 14px;
    color: var(--color-text_secondary);
    height: 100%;
    margin-right: -8px;
}

.input-unit-icon {
    width: 20px;
}

.input-langDropdown {

}

.textarea-input {
    min-height: 140px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid var(--color-input_border);
    font-family: 'Sofia Pro', sans-serif;
    font-size: 16px;
    padding: 10px;
    box-sizing: border-box;
    resize: none;
}

.input-confirmButton {
    width: 14px;
    height: 14px;
    padding: 4px;
    border-radius: 4px;
    fill: var(--color-blue);
    background-color: var(--color-foreground);
    border: 1px solid var(--color-blue);
}

.input-confirmButton:hover {
    cursor: pointer;
    background-color: rgba(13, 124, 206, 0.1);
}

.input-confirmButton:active {
    background-color: rgba(35, 178, 85, 0.1);
    border-color: var(--color-success);
    fill: var(--color-success);
}

