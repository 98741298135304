.columnHeaderMapping {
    position: relative;
    display: flex;
    background-color: var(--color-foreground);
    padding: 10px 20px;
    margin: 5px;
    box-sizing: border-box;
    border-radius: 6px;
    align-items: center;
    box-shadow: var(--shadow-minimal);
    border: 1px solid var(--color-border);
}

.columnHeaderMapping_disabled {
    background-color: var(--color-background);
}

.columnHeaderMapping_locked {
    cursor: not-allowed !important;
}

.columnHeaderMapping_disabled::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 5px;
    z-index: 1;
}

.columnHeaderMapping:hover {
    cursor: pointer;
}

.columnHeaderMapping-icon {
    margin-left: 20px;
    height: 20px;
}

.columnHeaderConfig-contentTypeIcon {
    width: 20px;
    height: 20px;
}

.columnHeaderMapping_selected {
    outline: 2px solid var(--color-select);
}

.columnHeaderMapping-title {
    flex: 1;
    display: flex;
    gap: 10px;
}

.columnHeaderMapping-title-check {
    z-index: 2;
}

.columnHeaderMapping-mapping {
    display: flex;
    flex: 1;
    align-items: center;
}

.columnHeaderMapping-mapping-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    flex-shrink: 0;
}

.columnHeaderMapping-mapping-icon__error {
    fill: var(--color-danger);
}

.columnHeaderMapping-mapping-errors {
    background-color: #ffe6db;
    border: 1px solid var(--color-danger);
    border-radius: 6px;
    padding: 10px;
    box-shadow: var(--shadow-wide);
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 50vh;
    overflow: auto;
}

.columnHeaderMapping-mapping-error {
    /* add properties here */
    background-color: rgba(222, 53, 3, 0.1);
    padding: 10px;
    border-radius: 4px;
    border-left: 4px solid var(--color-danger);
    display: flex;
    align-items: center;
    font-size: 14px;
}

.columnHeaderMapping-mapping-error-messages {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.columnHeaderMapping-mapping-error-message {
    color: var(--color-danger);
    max-width: 20vw;
}

.columnHeaderMapping-mapping-error-count {
    background-color: var(--color-danger);
    color: white;
    font-weight: bold;
    padding: 0 6px;
    border-radius: 20px;
    min-width: 20px;
    text-align: center;
}

.columnHeaderMapping-mapping-dropdown {
    flex: 1;
    max-width: 100%;
}

.columnHeaderMapping-mapping-button {
    width: 50px
}