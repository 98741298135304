.jtce-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.jtce-checkbox-selected {
    background-color: var(--color-select);
    border-color: var(--color-select);
}