.jtce-simpleText {
}

.jtce-simpleText-input {
    margin-bottom: 10px;
}

.jtce-simpleText-input > span {
    display: block;
    height: 0;
    visibility: hidden;
    padding-left: 14px;
}