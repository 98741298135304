.additionalinformationgallery {
  margin-top: 20px;
}

.additionalinformationgallery-dropzone {
  width: calc(33% - 7px);
  height: calc(33% - 7px);
  margin: 5px;
}

.additionalinformationgallery-actions {
  margin-top: 20px;
  border-top: 1px solid var(--color-border);
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px;
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
}

.additionalinformationgallery-assets {
  margin-top: 20px;
}

.additionalinformationgallery-asset-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.additionalinformationgallery-asset {
  display: inline-flex;
  align-items: center;
  border: 1px solid var(--color-border);
  border-radius: 6px;
  background-color: var(--color-background_light);
  padding: 10px;
  margin-right: 10px;
}

.additionalinformationgallery-items {
  display: flex;
  margin: -5px;
  margin-top: 20px;
}

.additionalinformationgallery-item-wrapper {
  width: 300px;
  height: 300px;
}

.additionalinformationgallery-item {
  width: 100%;
  height: 100%;
}