.integrationLogList {

}

.integrationLogList-level {
    display: flex;
    align-items: center;
}

.integrationLogList-level__information {
    color: var(--color-blue);
}

.integrationLogList-level__information > .integrationLogList-level-icon {
    fill: var(--color-blue)
}

.integrationLogList-level__trace {
    color: var(--color-warning);
}

.integrationLogList-level__trace > .integrationLogList-level-icon {
    fill: var(--color-warning);
}

.integrationLogList-level__debug {
    color: var(--color-danger);
}

.integrationLogList-level__debug > .integrationLogList-level-icon {
    color: var(--color-danger);
}

.integrationLogList-level__warning {
    color: var(--color-yellow);
}

.integrationLogList-level__warning > .integrationLogList-level-icon {
    color: var(--color-yellow);
}

.integrationLogList-level__error {
    color: var(--color-danger);
}

.integrationLogList-level__error > .integrationLogList-level-icon {
    color: var(--color-danger);
}

.integrationLogList-level__critical {
    color: var(--color-danger);
}

.integrationLogList-level__critical > .integrationLogList-level-icon {
    color: var(--color-danger);
}

.integrationLogList-level__none {
    color: var(--color-text_tertiary);
}

.integrationLogList-level__none > .integrationLogList-level-icon {
    color: var(--color-text_tertiary);
}

.integrationLogList-level-icon {
    width: 20px;
    margin-right: 10px;
}

.integrationLogList-integration {
    display: flex;
    align-items: center;
    color: var(--color-text_secondary)
}

.integrationLogList-integration-icon {
    width: 20px;
    margin-right: 10px;
}