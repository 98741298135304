.button-wrapper {
    display: flex;
    width: auto;
}

.button {
    box-sizing: border-box;
    padding-left: 14px;
    padding-right: 14px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 6px;
    letter-spacing: var(--spacing-text_action);
    font-size: 16px;
    font-weight: bold;
    justify-content: center;
    transition: background-color 0.1s;
    white-space: nowrap;
    color: var(--color-text_primary);
}

.button-full {
    display: flex;
    width: 100% !important;
    max-width: 100% !important;
}

.button-minimal {
    display: inline-flex;
    width: auto;
}

.button-tiny {
    display: inline-flex;
    font-size: 14px;
    padding: 10px;
    height: 30px;
}

.button:hover {
    cursor: pointer;
}

.button-primary {
    background-color: var(--color-primary);
    color: white;
    box-shadow: var(--shadow-minimal);
}

.button-primary:hover {
    background-color: var(--color-primary_hover);
}

.button-primary:active {
    background-color: var(--color-primary_active);
}

.button-secondary {
    background-color: var(--color-input_background);
    border: 1px solid var(--color-border);
    color: var(--color-text_primary);
    box-shadow: var(--shadow-minimal);
}

.button-secondary-filled {
    background-color: white;
    border: 1px solid var(--color-border);
    color: var(--color-text_primary);
    box-shadow: var(--shadow-minimal);
}

.button-secondary:hover,
.button-secondary-filled:hover {
    background-color: var(--color-background_light);
}

.button-secondary:active,
.button-secondary-filled:active {
    background-color: var(--color-background);
}

.button-save {
    background-color: var(--color-save);
    color: white;
    box-shadow: var(--shadow-minimal);
}

.button-save:hover {
    background-color: var(--color-save_hover);
}

.button-save:active {
    background-color: var(--color-save_active);
}

.button-danger {
    background-color: var(--color-danger);
    color: white;
}

.button-danger:hover {
    background-color: var(--color-danger_hover);
}

.button-danger:active {
    background-color: var(--color-danger_active);
}

.button-secondary-danger {
    color: var(--color-danger);
    background-color: var(--color-input_background);
    border: 1px solid var(--color-border);
    box-shadow: var(--shadow-minimal);
}

.button-secondary-danger:hover {
    background-color: var(--color-background_light);
}

.button-secondary-danger:active {
    background-color: var(--color-background);
}

.button-tertiary {
    background-color: var(--color-tertiary);
    color: white;
}

.button-tertiary:hover {
    background-color: var(--color-tertiary_hover);
}

.button-tertiary:active {
    background-color: var(--color-tertiary_active);
}

.buttonWithIcon {
    min-width: 30px;
    max-width: 40px;
    padding-left: 10px;
    padding-right: 10px;
}

.button-icon {
    width: 20px;
    height: 20px;
    fill: var(--color-text_primary);
}

.button-icon-primary {
    fill: white !important;
}

.button-icon-danger {
    fill: var(--color-danger) !important;
}

.button-icon-success {
    fill: var(--color-success) !important;
}

.button-icon-blue {
    fill: var(--color-blue) !important;
}

.button-icon-secondary {
    fill: var(--color-text_secondary) !important;
}

.button-icon-tertiary {
    fill: var(--color-text_tertiary) !important;
}

.button-icon-alwaysWhite {
    fill: white !important;
}

.button-tertiary .button-icon {
    fill: var(--color-text_tertiary);
}

.button-inactive .button-icon {
    fill: var(--color-text_tertiary);
}

.button-icon-tiny {
    width: 16px;
    height: 16px;
    fill: var(--color-text_primary);
}

.button-icon-text {
    background-color: white;
}

.button-icon-text-cta {
    margin-left: 10px;
}

.button-inactive {
    background-color: var(--color-background);
    color: var(--color-gray);
    border: 1px solid var(--color-background);
    box-shadow: none;
}

.button-inactive:hover {
    background-color: var(--color-background);
    cursor: default;
}

.action {
    display: flex;
    align-items: center;
}

.action:hover {
    cursor: pointer;
}

.action-cta {
    padding-left: 6px;
    font-size: 16px;
    font-weight: bold;
    color: var(--color-text_secondary);
}

.button-spinner,
.button-spinner:after {
    border-radius: 50%;
    width: 14px;
    height: 14px;
}

.button-spinner {
    margin: 0 auto;
    font-size: 10px;
    position: relative;
    border-top: 4px solid rgba(255, 255, 255, 0.2);
    border-right: 4px solid rgba(255, 255, 255, 0.2);
    border-bottom: 4px solid rgba(255, 255, 255, 0.2);
    border-left: 4px solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: spin 1.1s infinite linear;
    animation: spin 1.1s infinite linear;
    width: 10px;
    height: 10px;
}

.button-spinnerBackground {
    background-color: var(--color-background);
}

.button-product-up {
    margin-left: -10px !important;
}

.button-subAction {
    width: 40px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 6px 6px 0;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.button-subAction:hover {
    cursor: pointer;
}

.button-subAction-save {
    background-color: var(--color-save);
}

.button-subAction-save:hover {
    background-color: var(--color-save_hover);
}

.button-subAction-save:active {
    background-color: var(--color-save_active);
}

.button-subAction-secondary {
    border: 1px solid var(--color-border);
    box-shadow: var(--shadow-minimal);
    border-left: none;
}

.button-subAction-secondary:hover {
    background-color: var(--color-background_light);
}

.button-subAction-secondary:active {
    background-color: var(--color-background);
}

.button-subAction-icon {
    width: 10px;
    height: 10px;
    margin-top: 2px;
}

.button-subAction-menu {
    background-color: var(--color-foreground);
    padding: 10px;
    border-radius: 6px;
    box-shadow: var(--shadow-wide);
}

.button-subAction-menu-action {
    padding: 6px 10px;
    white-space: nowrap;
    box-sizing: border-box;
    margin-bottom: 10px;
    border-radius: 6px;
    text-align: center;
    transition: background-color 0.1s;
    font-size: 14px;
}

.button-subAction-menu-action:last-child {
    margin-bottom: 0;
}

.button-subAction-menu-action:hover {
    background-color: var(--color-background_light);
    border-radius: 4px;
}

.button-subAction-menu-action__save {
    color: var(--color-save);
}

.button-subAction-menu-action__primary {
    color: var(--color-primary);
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
