.promotionForm {

}

.promotionForm-discount {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 20px;
    background-color: var(--color-background_light);
    border-radius: 6px;
    padding: 20px;
}

.promotionForm-discount-inputs {
    width: 100%;
}

.promotionForm-discount-inputs-inputGroup {
    margin-bottom: 0;
}

.promotionForm-discount-inputs-products {
    margin-top: 20px;
}

.promotionForm-discount-delete {
    padding: 10px;
    width: 14px;
    background-color: rgba(222, 53, 3, 0.1);
    display: flex;
    align-items: center;
    border-radius: 4px;
    margin-left: 20px;
}

.promotionForm-discount-delete:hover {
    cursor: pointer;
}

.promotionForm-discount-delete-icon {
    width: 14px;
    height: 14px;
}

.promotionForm-productsPopupActions {
    margin-top: 20px;
}