.shippingDetailEdit {

}

.shippingDetailEdit-positions {
    margin-top: 40px;
    border-top: 1px solid var(--color-border);
    margin-left: -20px;
    margin-right: -20px;
    padding: 0 20px;
}

.shippingDetailEdit-quantityInput {
    width: 60px;
}