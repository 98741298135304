.newBundle {

}

.newBundle-tabs {
    margin: 0 -20px;
}

.newBundle-tab {
    padding: 20px;
}

.newBundle-actions {
}