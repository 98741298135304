.productBulkEditor {

}

.productBulkEditor-convertPopup-check {
    margin-bottom: 20px;
}

.productBulkEditor-productListActions {
    margin-top: 20px;
}