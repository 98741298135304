.versions {
}

.versions-empty {
    color: var(--color-text_tertiary);
    text-align: center;
}

.version {
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
}

.version:hover {
    cursor: pointer;
    background-color: var(--color-background_light);
}

.version-version {
    display: flex;
    align-items: center;
}

.version-version-stateIndicator {
    position: relative;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    margin-right: 10px;
}

.version-version-stateIndicator-live {
    background-color: var(--color-success);
}

.version-version-stateIndicator-live:after {
    position: absolute;
    top: -4px;
    left: -4px;
    background-color: var(--color-success);
    height: 20px;
    width: 20px;
    content: '';
    border-radius: 100%;
    opacity: 0.3;
}

.version-version-stateIndicator-offline {
    background-color: var(--color-gray);
}

.version-version-stateIndicator:after {
    position: absolute;
    top: -4px;
}

.version-version-number {
    font-family: 'Inconsolata', monospace;
    font-size: 16px;
    margin-right: 10px;
}

.version-date {
    font-size: 14px;
    color: var(--color-text_secondary);
}

.version-button {
    width: 30px;
    height: 30px;
    border: 1px solid var(--color-border);
    border-radius: 6px;
    padding: 6px;
    box-sizing: border-box;
    box-shadow: var(--shadow-minimal);
    background-color: var(--color-input_background);
}

.versions-actions {
    margin-top: 20px;
}
