.productMappingSocket {
    position: relative;
    width: calc(50% - 48px);
    border: 1px solid var(--color-border);
    height: 60px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: var(--color-background);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: var(--color-text_secondary);
    transition: border 0.2s;
}

.productMappingTuple-productWrapper .productMappingSocket {
    width: 100%;
}

.productMappingSocket__empty {

}

.productMappingSocket__create {
    border-color: var(--color-select);
    color: var(--color-select);
    background-color: rgba(0, 149, 255, 0.1);
}

.productMappingSocket__inBackground {
    position: absolute;
    top: 0;
    z-index: 1;
}

.productMappingSocket__dragging {
    border: 1px dashed;
    border-color: var(--color-select);
    color: var(--color-select);
    background-color: rgba(0, 149, 255, 0.1);
}

.productMappingSocket-icon {
    width: 24px;
    fill: var(--color-select);
}

.productMappingSocket-search {
    opacity: 0;
    position: absolute;
    width: calc(100% - 20px);
    transition: opacity 0.2s;
    z-index: 4;
}

.productMappingSocket:hover .productMappingSocket-search {
    opacity: 1;
    z-index: 5;
}

.productMappingSocket-search-results {
    position: absolute;
    background-color: var(--color-background_light);
    padding: 10px;
    border: 1px solid var(--color-border);
    border-radius: 6px;
    box-shadow: var(--shadow-wide);
    width: 100%;
    box-sizing: border-box;
    max-height: 30vh;
    overflow: scroll;
}

.productMappingSocket-search-results-empty {
    margin-bottom: -10px;
}

.productMappingSocket-search-result {
    margin-bottom: 10px;
    color: var(--color-text_primary);
    box-shadow: none;
    transition: box-shadow 0.2s;
}

.productMappingSocket-search-result:hover {
    cursor: pointer;
    box-shadow: var(--shadow-minimal);
}

.productMappingSocket-search-result:last-child {
    margin-bottom: 0;
}