.contacts {
}

.contacts-members {
  display: flex;
  margin-bottom: 20px;
}

.contacts-popup-title {
  font-weight: bold;
  margin-bottom: 20px;
}

.contacts-popup-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
