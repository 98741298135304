.auth {
    flex: 0 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth-modal {
    background-color: var(--color-foreground);
    padding: 20px;
    min-width: 320px;
    max-width: 400px;
}

.auth-modal-logo {
    border-bottom: 1px solid var(--color-border);
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 20px;
}

.auth-modal-logo-img {
    display: block;
    width: 100px;
    margin: 0 auto 20px;
}

.auth-modal-title {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 20px;
}

.auth-modal-link {
    display: block;
    margin-top: -10px;
}

.auth-modal-button {
    margin-top: 20px;
}
