.sidebar {
    flex-shrink: 0;
    position: relative;
    height: 100%;
    background-color: var(--color-background);
    border-right: 1px solid var(--color-border);
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 200px;
}

.sidebar:before {
    position: fixed;
    top: 0;
    left: 0;
    content: '';
    height: 72px;
    background-color: var(--color-background);
    z-index: 1;
    width: 100px;
}

@media only screen and (max-width: 1200px) {
    .sidebar {
        position: absolute;
        top: 0;
        padding-top: 92px;
        z-index: 10;
        height: 100%;
        transition: left 0.5s;
        box-shadow: var(--shadow-wide)
    }

    .sidebar:before {
        display: none;
    }

    .sidebar-mobileActive {
        left: 0;
    }

    .sidebar-mobileInactive {
        left: -300px;
    }
}

.sidebar-items {
    overflow-y: scroll;
    display: block;
}

.sidebar-item {
    position: relative;
    font-weight: bold;
    font-size: 16px;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    padding-right: 10px;
    box-sizing: border-box;
    border-radius: 6px;
}

.sidebar-item:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.02);
}

.sidebar-item:active {
    background-color: rgba(0, 0, 0, 0.08);
}

.sidebar-item-highlight {
    position: absolute;
    left: -20px;
    content: '';
    width: 6px;
    height: 100%;
    border-radius: 0 6px 6px 0;
}

.sidebar-item-icon {
    position: absolute;
    left: 10px;
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.sidebar-item-notification {
    background-color: var(--color-primary);
    color: white;
    padding: 0 6px;
    min-width: 14px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 18px;
    margin-left: 6px;
}

.sidebar-item-underConstruction {
    position: absolute;
    right: 10px;
    width: 18px;
    height: 18px;
}

.sidebar-settings {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
}

.sidebar-settings:hover {
    cursor: pointer;
}

.sidebar-settings-img {
    width: 20px;
    height: 20px;
    border-radius: 100px;
}

.sidebar-settings-title {
    font-weight: bold;
    color: var(--color-text_secondary);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9;
    animation: fadeIn 0.5s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
