.returnAnnouncement {

}

.returnAnnouncement-title {
    font-weight: bold;
    margin-bottom: 20px;
}

.returnAnnouncement-header {
    display: flex;
    justify-content: space-between;
}

.returnAnnouncement-header-qr {
    height: 256px;
    width: 256px;
    border: 1px solid var(--color-border);
}

.returnAnnouncement-positions {
    margin-top: 20px;
    margin-bottom: 20px;
}

.returnAnnouncement-positions-retractable {
    margin-bottom: 10px;
}

.returnAnnouncement-positions-title {
    margin-bottom: 20px;
    font-weight: bold;
}

.returnAnnouncement-position {
    border-top: 1px solid var(--color-border);
    padding: 20px;
}

.returnAnnouncement-position-section {
    margin-bottom: 20px;
}

.returnAnnouncement-position-section-title {
    font-weight: bold;
    margin-bottom: 20px;
}

.returnAnnouncement-position-reasons {
}

.returnAnnouncement-position-reason {
    margin-bottom: 20px;
}

.returnAnnouncement-position-reason:last-child {
    margin-bottom: 0;
}

.returnAnnouncement-position-actions {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid var(--color-border);
    padding: 20px;
}