.promotionCodes {
}

.promotionCodes-codes {
    max-height: 400px;
    overflow: scroll;
}

.promotionCodes-code {
    display: flex;
    border-bottom: 1px solid var(--color-border);
    margin-left: -20px;
    margin-right: -20px;
    padding: 10px 20px;
    align-items: center;
    justify-content: space-between;
}

.promotionCodes-code-code {
    font-family: 'Inconsolata', monospace;
    border-radius: 6px;
}

.promotionCodes-code-copy {
    width: 20px;
    height: 20px;
    background-color: var(--color-background_light);
    padding: 4px;
    border-radius: 4px;
}

.promotionCodes-code-copy:hover {
    cursor: pointer;
}

.promotionCodes-summary {
    display: inline-block;
    padding: 4px 10px;
    border-radius: 6px;
    background-color: var(--color-background_light);
    color: var(--color-text_secondary);
    margin-bottom: 10px;
}