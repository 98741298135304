.sheetParser {
    flex-grow: 1;
}

.sheetParser-exampleHeaders-title {
    margin-bottom: 20px;
}

.sheetParser-exampleHeaders {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    max-width: 400px;
    gap: 10px;
}

.sheetParser-exampleHeader {
    background-color: var(--color-background_light);
    border-radius: 4px;
    padding: 4px 10px;
    font-size: 14px;
}

.sheetParser-uploadConfirmText {
    margin-bottom: 20px;
    max-width: 400px;
}

.sheetParser-uploadConfirmText span {
    font-weight: bold;
    color: var(--color-text_primary);
    margin: 0 5px;
}

.sheetParser-idKeyMappingText {
    margin-bottom: 20px;
    max-width: 500px;
}