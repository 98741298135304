.dailyBestsellersList {

}

.dailyBestsellersList-dayBar {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
}

.dailyBestsellersList-dayBar-days {
    display: flex;
    overflow: scroll;
}

.dailyBestsellersList-dayBar-days-day {
    color: var(--color-text_primary);
    background-color: var(--color-background);
    border-radius: 6px;
    padding: 4px 10px;
    margin-right: 10px;
}

.dailyBestsellersList-dayBar-days-day:hover {
    cursor: pointer;
}

.dailyBestsellersList-dayBar-days-day__selected {
    background-color: var(--color-text_primary);
    color: var(--color-background_light);
    font-weight: bold;
    box-sizing: border-box;
}

.dailyBestsellersList-dayBar-button {
    height: auto;
    border-radius: 6px;
    background-color: var(--color-background);
    display: flex;
    align-items: center;
    padding: 0 4px;
}

.dailyBestsellersList-dayBar-button:hover {
    cursor: pointer;
    background-color: var(--color-tertiary_hover);
}

.dailyBestsellersList-dayBar-button:active {
    background-color: var(--color-tertiary_active);
}

.dailyBestsellersList-dayBar-button-icon {
    width: 20px;
    height: 16px;
}

.dailyBestsellersList-dayBar-button__back {
    margin-right: 10px;
}

.dailyBestsellersList-dayBar-button__next {
}

.dailyBestsellersList-list {
    margin-top: 20px;
}