.emptyState {
    width: 100%;
    max-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.emptyState-noMargin {
    margin: 0;
}

.emptyState-content {
    text-align: center;
}

.emptyState-content-img {
    width: 120px;
}

.emptyState-content-img-small {
    width: 80px;
    height: 80px;
}

.emptyState-content-msg {
    margin-top: 10px;
    color: var(--color-text_secondary);
    margin-bottom: 20px;
    background-color: var(--color-background_light);
    padding: 4px 10px;
    border-radius: 6px;
    max-width: 660px;
    font-size: 14px;
}

.emptyState-noMargin .emptyState-content-msg {
    margin-top: 0;
    margin-bottom: 10px;
}

.emptyState-content-missingPermissions {
    text-align: left;
    color: var(--color-text_tertiary)
}

.emptyState-content-missingPermissions-permissions {
    margin-top: 10px;
}

.emptyState-content-missingPermissions-permission {

}
