.colorpicker {
    flex: 1;
}

.colorpicker-header {
    display: flex;
}

.colorpicker-header-title {
    color: var(--color-text_secondary);
    margin-bottom: 10px;
}

.colorpicker-picker {
    position: relative;
}

.colorpicker-picker:hover {
    cursor: pointer;
}

.colorpicker-input {
    position: absolute;
    top: 0;
    visibility: hidden;
    padding: 0;
    border: 0;
}

.colorpicker-color-wrapper {
    width: 100%;
    height: 40px;
    min-width: 40px;
    border: 1px solid var(--color-border);
    box-sizing: border-box;
    border-radius: 6px;
    padding: 4px;
}

.colorpicker-color {
    width: 100%;
    height: 100%;
    border-radius: 4px;
}

.colorpicker-spacer {
    width: 60px;
    height: 30px;
}
