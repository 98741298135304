.keyHints {
    display: flex;
    align-items: center;
    color: var(--color-text_tertiary);
    font-size: 12px;
}

.keyHint-content {
    display: flex;
    align-items: center;
}

.keyHint {
    margin-right: 20px;
}

.keyHint:last-child {
    margin-right: 0;
}

.keyHint:hover {
    cursor: pointer;
}

.keyHint-keys {
    display: flex;
}

.keyHint-key {
    display: flex;
}

.keyHint-key span {
    border: 2px solid var(--color-border);
    padding: 0 4px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: bold;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}

.keyHint-key-plus {
    margin: 0 4px;
    font-weight: bold;
}

.keyHint-doubleColon {
    margin: 0 6px;
    font-weight: bold;
}

.keyHint-explanation {

}