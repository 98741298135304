.retractable {
    box-shadow: var(--shadow-minimal);
    border: 1px solid var(--color-border);
    border-radius: 6px;
    background-color: var(--color-foreground);
}

.retractable-head {
    padding: 20px;
    display: flex;
    align-items: center;
    color: var(--color-text_primary)
}

.retractable-head-element {
    margin-left: 20px;
}

.retractable-head-title {
    font-weight: bold;
    white-space: nowrap;
}

.retractable-head-active:hover {
    cursor: pointer;
}

.retractable-head-arrow {
    width: 12px;
    height: 12px;
    margin-right: 20px;
    flex-shrink: 0;
}

.retractable-content-padded {
    padding: 0 20px 20px;
}