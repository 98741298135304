.returnpositionsedit {
}

.returnpositionsedit-position {
  border-top: 1px solid var(--color-border);
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px;
  padding-bottom: 0;
  margin-bottom: 20px;
}

.returnpositionsedit-position-header {
  display: flex;
  margin-bottom: 20px;
}

.returnpositionsedit-position-header-title {
  font-weight: bold;
  margin-right: 10px;
}

.returnpositionsedit-position-header-element {
  margin-right: 20px;
}
