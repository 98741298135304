.animalSpeciesCell {
    display: flex;
    height: 100%;
}

.animalSpeciesCell-info {
    display: flex;
    font-size: 14px;
    background-color: var(--color-background_light);
    margin-right: 6px;
    border-radius: 4px;
    padding: 0 8px;
    border: 1px solid var(--color-border);
    align-items: center;
}

.animalSpeciesCell-info:hover {
    cursor: pointer;
}

.animalSpeciesCell-info__selected {
    outline: 2px solid var(--color-blue);
    border-color: transparent;
}