.payment {
}

.payment-section {
    width: 100%;
    margin-bottom: 30px;
}

.payment-section:last-child {
    margin-bottom: -10px;
}

.payment-section-method {
    display: flex;
    justify-content: space-between;
}
