.shopcustomization {
    display: flex;
}

.shopcustomization-edit {
}

.shopcustomization-edit-color {
    margin-bottom: 20px;
}

.shopcustomization-edit-hero-img {
    display: block;
    min-width: 140px;
    width: 50%;
    border-radius: 6px;
    margin-bottom: 10px;
}

.shopcustomization-preview {
    position: relative;
    width: 55%;
    padding-left: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.shopcustomization-preview-tabselector {
    width: 100%;
    margin-bottom: 20px;
}

@media only screen and (max-width: 1200px) {
    .shopcustomization {
        flex-direction: column;
    }
}
