.stepSelector {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20vh;
}

.stepSelector-box {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stepSelector-box-img {
    width: 70%;
    margin-bottom: 20px;
}

.stepSelector-box-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.stepSelector-box-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.stepSelector-box-option {
    padding: 10px 14px;
    border-radius: 6px;
    text-align: center;
    display: flex;
    align-items: center;
    transition: background-color 0.1s;
    color: var(--color-text_primary);
    background-color: rgba(0, 0, 0, 0.05);
}

.stepSelector-box-option:hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.stepSelector-box-option__edit {
    background-color: rgba(13, 124, 206, 0.1);
    color: var(--color-blue);
}

.stepSelector-box-option__edit:hover {
    background-color: rgba(13, 124, 206, 0.2);
}

.stepSelector-box-option__new {
    background-color: rgba(35, 178, 85, 0.1);
    color: var(--color-success);
}

.stepSelector-box-option__new:hover {
    background-color: rgba(35, 178, 85, 0.2);
}

.stepSelector-box-option__import {
    background-color: rgba(142, 130, 239, 0.1);
    color: var(--color-inherited);
}

.stepSelector-box-option__import:hover {
    background-color: rgba(142, 130, 239, 0.2);
}

.stepSelector-box-option-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.stepSelector-box-option-title {
    white-space: nowrap;
}