.itemActions {
    display: flex;
    align-items: center;
}

.itemActions-icon {
    width: 16px;
    margin: 0 auto;
    padding: 10px 4px;
    border-radius: 6px;
    transition: background-color 0.2s;
}

.itemActions-icon:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
}

.itemActions-icon:active {
    background-color: rgba(0, 0, 0, 0.1);
}

.itemActions-icon-alignment-horizontal {
    transform: rotate(90deg);
}

.itemActions-icon-alignment-vertical {
}
