.salesOverview {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.salesOverview-elements {
    display: flex;
    background-color: var(--color-background_light);
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    gap: 20px;
}

.salesOverview-elements-sub {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: var(--layout-responsiveFlexDirection);
}
