.mobile-topbar-menuBtn {
    width: 24px;
    height: 24px;
    margin-right: 14px;
}

.topbar {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 72px;
    background-color: var(--color-foreground);
    display: flex;
    align-items: center;
    border-radius: 0 0 20px 20px;
    box-shadow: var(--shadow-minimal);
    justify-content: space-between;
}

.topbar-spacer {
    flex-shrink: 0;
    height: 72px;
    width: 100%;
}

.topbar-title {
    flex: 0 33.33%;
    height: 60%;
    padding-left: 20px;
    display: flex;
    align-items: center;
}

.topbar-title-logo {
    height: 70%;
}

.topbar-title-title {
    padding-left: 10px;
    font-size: var(--size-text_large);
    font-weight: bold;
    color: var(--color-primary);
}

.topbar-title-subtitle {
    padding-left: 10px;
    font-size: var(--size-text_normal);
    font-weight: bold;
    color: var(--color-gray);
    display: flex;
    align-items: center;
}

.topbar-search {
    flex: 0 33.33%;
    padding-left: 20px;
}

.topbar-actions {
    flex: 0 33.33%;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    box-sizing: border-box;
}

:root {
    --color-environmentBadge-test: #daefff;
    --color-environmentBadge-staging: #edd5f8;
}

[data-theme='dark'] {
    --color-environmentBadge-test: #3a444d;
    --color-environmentBadge-staging: #493c52;
}

.topbar-environment-test {
    margin-left: 10px;
    background-color: var(--color-environmentBadge-test);
    color: var(--color-blue);
    padding: 3px 10px;
    border-radius: 4px;
    font-weight: bold;
    font-family: 'Inconsolata', monospace;
}

.topbar-environment-staging {
    margin-left: 10px;
    background-color: var(--color-environmentBadge-staging);
    color: #ab41e0;
    padding: 3px 10px;
    border-radius: 4px;
    font-weight: bold;
    font-family: 'Inconsolata', monospace;
}


.topbar-buildVersion {
    font-size: 10px;
    color: var(--color-gray);
    margin-left: 10px;
    margin-top: 4px;
}

@media only screen and (min-width: 1200px) {
    .mobile-topbar-menuBtn {
        display: none;
    }

    .topbar-title-logo {
        height: 80%;
    }
}