.areaChartGrid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: var(--color-foreground);
    background-image: radial-gradient(var(--color-background) 1px, transparent 0);
    background-size: 10px 10px;
    background-position: 6px 6px;
    z-index: 0;
}