.loyaltyPointTransaction {
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 6px;
    background-color: var(--color-background_light);
}

.loyaltyPointTransaction:last-child {
    margin-bottom: 0;
}

.loyaltyPointTransaction-brief {
    display: flex;
    align-items: center;
    color: var(--color-text_secondary);
}

.loyaltyPointTransaction-brief-date {
    padding: 2px 6px;
    margin: 0 6px;
}

.loyaltyPointTransaction-brief-points {
    margin: 0 6px;
    padding: 2px 6px;
    border-radius: 6px;
    font-weight: bold;
}

.loyaltyPointTransaction-brief-points__credit {
    background-color: rgba(35, 178, 85, 0.1);
    color: var(--color-success);
}

.loyaltyPointTransaction-brief-points__redemption {
    background-color: rgba(255, 190, 0, 0.1);
    color: var(--color-yellow);
}

.loyaltyPointTransaction-description {
    font-size: 14px;
}

.loyaltyPointTransaction-actions {
    margin-top: 10px;
    display: flex;
}

.loyaltyPointTransaction-button {
}