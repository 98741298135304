.inpettoClientBasedProductStocks {
    margin-top: -20px;
    padding-left: 20px;
    padding-top: 20px;
    border-left: 1px solid var(--color-border);
    margin-left: 20px;
    display: flex;
}

.inpettoClientBasedProductStocks > .card {
    margin-bottom: 0 !important;
}