.promotionsList {
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -10px;
}

.promotionsList-list {
    padding: 0 20px 20px;
}

.promotionsList-list-code {
    display: flex;
    align-items: center;
}

.promotionsList-list-code-icon {
    width: 16px;
    height: 16px;
    background-color: var(--color-background_light);
    padding: 4px;
    border-radius: 4px;
    margin-left: 10px;
    fill: var(--color-text_secondary);
}

.promotionsList-list-code-icon:hover {
    cursor: pointer;
    background-color: var(--color-background);
}

.promotionsList-list-code-icon:active {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
}