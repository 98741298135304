.datepicker {
}

.datepicker:hover {
    cursor: pointer;
}

.datepicker-header {
    display: flex;
}

.datepicker-picker {
    position: relative;
    display: flex;
    align-items: center;
}

.datepicker-picker-unit {
    height: 40px;
    margin-left: -10px;
    margin-right: 0;
    z-index: 1;
    border: 1px solid var(--color-border);
}

.datepicker-picker-editBtn {
    width: 14px;
    height: 14px;
}

.datepicker-title {
    color: var(--color-text_secondary);
    margin-bottom: 10px;
}

.react-datepicker-popper {
    z-index: 999 !important;
}

.react-datepicker {
    display: flex !important;
    font-family: "Sofia Pro", sans-serif !important;
}

.react-datepicker__time-container {
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--color-border) !important;
}

.react-datepicker__time {
    height: 100% !important;
    background-color: var(--color-foreground) !important;
}

.react-datepicker__time-list-item {
    height: 20px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-datepicker__time-list-item:hover {
    background-color: var(--color-background_light) !important;
}

.react-datepicker__time-list-item--selected {
    background-color: rgba(244, 130, 130, 0.15) !important;
    color: var(--color-primary) !important;
}

.react-datepicker,
.react-datepicker__header {
    color: var(--color-text_primary) !important;
    border-color: var(--color-input_border) !important;
}

.react-datepicker {
    background-color: var(--color-foreground) !important;
}

.react-datepicker__header {
    background-color: var(--color-background_light) !important;
}

.react-datepicker__triangle::after {
    border-bottom-color: var(--color-background_light) !important;
}

.react-datepicker__triangle::before {
    border-bottom-color: var(--color-input_border) !important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name,
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    color: var(--color-text_primary) !important;
}

.react-datepicker__day--selected {
    background-color: rgba(244, 130, 130, 0.15) !important;
    color: var(--color-primary) !important;
    font-weight: bold !important;
}

.react-datepicker__day:hover {
    background-color: var(--color-background_light) !important;
}

.datepicker-picker-wrapper .react-datepicker__input-container input {
    height: 40px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid var(--color-input_border);
    font-family: 'Sofia Pro', sans-serif;
    font-size: 16px;
    padding-left: 10px;
    box-sizing: border-box;
    background-color: var(--color-input_background);
    color: var(--color-text_primary)
}

.datepicker-picker-wrapper-minimal .react-datepicker__input-container input {
    height: 30px;
    width: 160px;
    border-radius: 6px;
    border: 1px solid var(--color-input_border);
    font-family: 'Sofia Pro', sans-serif;
    font-size: 16px;
    padding: 10px 10px;
    box-sizing: border-box;
    text-align: left;
    background-color: var(--color-input_background);
    color: var(--color-text_primary)
}

.react-datepicker__input-container input {
    outline: none;
}

.datepicker-popper {
    position: fixed !important;
    z-index: 1000 !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    height: calc(215px + (1.7rem / 2)) !important;
}