.productGroups {
}

.productGroups-dangerPopup-message {
    margin-bottom: 20px;
}

.productGroups-actions {
    margin-top: 20px;
}

.productGroups-groupForm {
    margin-bottom: 20px;
}

.productGroups-save {
    position: absolute;
    top: 20px;
    right: 20px;
}
