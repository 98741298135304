.errorsOverview {
    width: 100%;
}

.errorsOverview-retractable {
    margin-bottom: 10px;
    max-width: 700px;
}

.errorsOverview-retractable:last-child {
    margin-bottom: 0;
}

.errorsOverview-retractable-head {
    width: 100%;
    overflow: hidden;
    display: flex;
    margin-left: 20px;
    justify-content: space-between;
}

.errorsOverview-retractable-head-message {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 20px;
}

.errorsOverview-retractable-head-icon {
    width: 20px;
    height: 20px;
    justify-self: flex-end;
    flex-shrink: 0;
}

.errorsOverview-error {
    padding: 0 20px 20px;
}

.errorsOverview-error-element {
    margin-bottom: 10px;
}

.errorsOverview-error-element:last-child {
    margin-bottom: 0;
}

.errorsOverview-error-message {
    max-width: 600px;
    color: var(--color-text_secondary)
}

.errorsOverview-error-devInfo {
    color: var(--color-text_tertiary)
}

.errorsOverview-error-code {
    display: inline;
}

