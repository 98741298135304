.manufacturerinitialadmin {
}

.manufacturerinitialadmin-admins {
  display: flex;
  margin-bottom: 20px;
}

.manufacturerinitialadmin-inputGroup {
  display: flex;
}

.manufacturerinitialadmin-inputGroup-input {
  flex: 1;
  margin-right: 10px;
  margin-bottom: 20px;
}

.manufacturerinitialadmin-inputGroup-input:last-child {
  flex: 1;
  margin-right: 0px;
}
