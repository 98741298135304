.loyaltyPointProcessingConfigs {
}

.loyaltyPointProcessingConfigs-configs {
    padding-bottom: 40px;
}

.loyaltyPointProcessingConfigs-batch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loyaltyPointProcessingConfigs-addBetween {
    position: relative;
    width: 1px;
    height: 60px;
    background-color: var(--color-border);
}

.loyaltyPointProcessingConfigs-addBetween-button {
    position: absolute;
    top: calc(50% - 16px);
    left: -18px;
    height: 16px;
    width: 16px;
    border: 1px solid var(--color-border);
    padding: 10px;
    border-radius: 100%;
    background-color: var(--color-foreground);
    transition: box-shadow 0.2s, scale 0.2s;
}

.loyaltyPointProcessingConfigs-addBetween-icon {
    position: absolute;
    top: calc(50% - 20px);
    left: -20px;
    height: 20px;
    width: 20px;
    padding: 10px;
}

.loyaltyPointProcessingConfigs-addBetween-button:hover {
    cursor: pointer;
    box-shadow: var(--shadow-wide);
    scale: 1.1;
}

.loyaltyPointProcessingConfigs-addBetween__last {
    height: 50px;
}

.loyaltyPointProcessingConfigs-addBetween__last > .loyaltyPointProcessingConfigs-addBetween-button {
    bottom: -16px;
    top: initial;
}

.loyaltyPointProcessingConfigs-configs-initialButton {
    width: 100%;
    text-align: center;
}