.pricing {
    position: relative;
}

.pricing-taxTypeIdentifier {
    margin-bottom: 20px;
}

.pricing-prices {
}

.pricing-price {
    position: relative;
    border-bottom: 1px solid var(--color-border);
    margin-left: -20px;
    margin-right: -20px;
    padding: 20px;
}

.pricing-price-inherited:before {
    position: absolute;
    content: ' ';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--color-inherited);
    opacity: 0.15;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.pricing-price-inputGroup {
    position: relative;
    display: flex;
    margin-bottom: 20px;
}

.pricing-price-inputGroup-inputDelete {
    position: absolute;
    right: 20px;
    align-self: flex-end;
}

.pricing-price-inputGroup-inputDelete-icon {
    width: 20px;
    height: 20px;
}

.pricing-price-inputGroup:last-child {
    margin-bottom: 0;
}

.pricing-price-checkText {
    display: flex;
}

.pricing-price-checkText-text {
    margin-left: 10px;
}

.pricing-actions {
    justify-content: space-between;
}

.pricing-newPriceWizard-actions {
    justify-content: space-between;
    margin-top: 20px;
}
