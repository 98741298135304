@font-face {
    font-family: 'Sofia Pro';
    src: local('Sofia Pro'), url('./assets/font/regular.woff');
    font-weight: normal;
}

@font-face {
    font-family: 'Sofia Pro';
    src: local('Sofia Pro'), url('./assets/font/bold.woff');
    font-weight: bold;
}

@font-face {
    font-family: 'Inconsolata';
    src: local('Inconsolata'), url('./assets/font/inconsolata.ttf');
    font-weight: normal;
}

/*---------------------------------------*/
/*------------ GLOBAL VARIABLES ------------*/
/*---------------------------------------*/

:root {
    /*---CTA---*/
    --color-primary: #f48282;
    --color-primary_hover: #e87878;
    --color-primary_active: #d76b6b;
    --color-tertiary: #f1f1f1;
    --color-tertiary_hover: #eaeaea;
    --color-tertiary_active: #e0e0e0;
    --color-danger: #de3503;
    --color-danger_hover: #d03406;
    --color-danger_active: #bd2f06;
    --color-save: #89b277;
    --color-save_hover: #7ca46b;
    --color-save_active: #668d55;
    --color-inherited: rgb(142, 130, 239);
    --color-inherited_background: rgba(142, 130, 239, 0.1);

    --color-test: #c8ff5a;

    /*---TEXT---*/
    --color-text_primary: #000000;
    --color-text_secondary: #6c6963;
    --color-text_tertiary: #afafaf;
    --spacing-text_action: 0.03em;
    --color-textHighlight: #fec723;

    /*---ELEMENTS---*/
    --color-stage: #f5f5f5;
    --color-foreground: #ffffff;
    --color-background: #ededed;
    --color-background_light: #f5f5f5;
    --color-border: #d9d9d9;
    --color-input_border: #d9d9d9;
    --color-input_background: #ffffff;
    --color-hintBackground: #c9e9ff;
    --color-hint-messageBackground: #e6f4ff;
    --color-hint-text: var(--color-text_primary);

    /*---LABELS---*/
    --color-success: #23b255;
    --color-alert: #de3503;
    --color-warning: #ff7e1f;

    /*---GENERAL---*/
    --color-green: #377e62;
    --color-blue: #0d7cce;
    --color-select: #0095ff;
    --color-gray: #afafaf;
    --color-gray_light: #d9d9d9;
    --color-yellow: #ffbe00;

    /*---LAYOUT---*/
    --layout-responsiveFlexDirection: row;
    --size-text_veryLarge: 26px;
    --size-text_large: 22px;
    --size-text_normal: 16px;
    --size-text_small: 14px;
    --size-table_rowHeight: 80px;
    --size-table_imgSize: 60px;
    --size-table_rowHeight_compact: 40px;
    --size-table_imgSize_compact: 30px;

    --shadow-minimal: 0px 1px 2px rgba(0, 0, 0, 0.1);
    --shadow-wide: 0px 6px 20px rgba(0, 0, 0, 0.1);
    --shadow-productOwnerOverride: 0px 6px 20px rgba(222, 53, 3, 0.1);
}

@media only screen and (max-width: 1200px) {
    :root {
        --layout-responsiveFlexDirection: column;
        --size-text_veryLarge: 24px;
        --size-text_large: 20px;
        --size-text_normal: 16px;
        --size-text_small: 12px;
        --size-table_rowHeight: 40px;
        --size-table_imgSize: 40px;
    }
}

[data-theme='dark'] {
    /*---CTA---*/
    --color-danger: #ea4211;
    --color-tertiary: #303030;
    --color-tertiary_hover: #2a2a2a;
    --color-tertiary_active: #232323;

    /*---TEXT---*/
    --color-text_primary: #e8e8e8;
    --color-text_secondary: #ababab;
    --color-text_tertiary: #6e6e6e;
    --color-textHighlight: #a17900;

    /*---ELEMENTS---*/
    --color-stage: #292929;
    --color-foreground: #393939;
    --color-background: #303030;
    --color-background_light: #343434;
    --color-border: #292929;
    --color-input_border: #292929;
    --color-input_background: #404040;
    --color-hintBackground: #3a444d;
    --color-hint-messageBackground: var(--color-blue);
    --color-hint-text: var(--color-text_primary)
}

html,
body {
    width: 100%;
    height: 100%;
    font-family: 'Sofia Pro', sans-serif;
    background-color: var(--color-stage);
    color: var(--color-text_primary);
    caret-color: var(--color-text_primary);
    scrollbar-track-color: red;
    overflow: hidden;
}

a {
    text-decoration: none;
}

/* Custom scrollbar styling

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: rgba(0, 0, 0, 0.1);
    z-index: 999;
    padding: 2px;
}

::-webkit-scrollbar-track {

}

::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.35);
    border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

*/

.rnc__base {
    z-index: 999999999999 !important;
}

.rnc__notification-item--warning {
    background-color: #f1893f !important;
    border-left-color: var(--color-warning) !important;
}

.App {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.global-button-whiteBackground {
    background-color: white !important;
}

.global-textElement {
    line-height: 24px;
    color: var(--color-text_secondary);
    margin-bottom: 20px;
    white-space: wrap;
}

.global-textElement-link {
    color: var(--color-blue);
}

.global-textElement-link a {
    text-decoration: none;
    color: inherit;
}

.global-required {
    margin-left: 4px;
    margin-top: -8px;
    color: var(--color-danger);
    font-size: 20px;
}

.global-selector-unselected {
    width: 14px;
    height: 14px;
    border: 2px solid var(--color-border);
    border-radius: 4px;
    background-color: white;
}

.global-selector-selected {
    width: 14px;
    height: 14px;
    border: 2px solid var(--color-save);
    background-color: var(--color-save);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
}

.global-selector-selected-inherited {
    background-color: var(--color-inherited);
    border-color: var(--color-inherited);
}

.global-selector-selected-disabled {
    width: 14px;
    height: 14px;
    border: 2px solid var(--color-border);
    background-color: var(--color-border);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
}

.global-selector-unselected,
.global-selector-selected {
    cursor: pointer;
}

.global-selector-selected-img {
    width: 12px;
}

.global-statusBadge {
    border-radius: 100px;
    padding: 2px 10px;
    color: white;
    white-space: nowrap;
}

.global-textLink {
    color: var(--color-blue);
    font-size: 16px;
    cursor: pointer;
}

.global-elementMargin {
    margin-bottom: 20px;
}

.global-elementMargin:last-child {
    margin-bottom: 0;
}

.global-icon-inherited {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.global-inherited {
    color: var(--color-inherited);
}

.global-input {
    margin-bottom: 20px;
}

.global-input-title {
    margin-bottom: 10px;
    color: var(--color-text_secondary);
}

.global-inputGroup {
    display: flex;
    margin-bottom: 20px;
}

.global-inputGroup:last-child {
    margin-bottom: 0;
}

.global-inputGroup-input {
    flex: 1;
    margin-right: 10px;
}

.global-inputGroup-inputInline {
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.global-inputGroup-input:last-child {
    margin-right: 0;
}

.global-inputGroup-input-noTitle {
    margin-top: 34px;
    height: 40px;
    display: flex;
    align-items: center;
}

.global-dropdown {
    position: relative;
    border: 1px solid var(--color-border);
    border-radius: 6px;
    font-family: 'Sofia Pro', sans-serif;
    font-size: 14px;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 30px;
    height: 40px;
    width: 100%;
    box-shadow: var(--shadow-minimal);
}

.global-hierarchy-element {
    position: relative;
}

.global-hierarchy-element-item {
    padding-left: 10px;
    border-radius: 6px;
    display: flex;
    height: 40px;
    align-items: center;
}

.global-hierarchy-element-item:hover {
    background-color: var(--color-background_light);
    cursor: pointer;
}

.global-hierarchy-element-item-arrow {
    width: 10px;
    margin-right: 10px;
}

.global-hierarchy-element-item-check {
    margin-right: 10px;
}

.global-hierarchy-element-item-title {
}

.global-hierarchy-element-item-badge {
    display: flex;
    margin-left: 10px;
}

.global-hierarchy-element-children {
    margin-left: 14px;
    padding-left: 10px;
    border-left: 1px solid var(--color-border);
}

.global-cardActions {
    position: relative;
    border-top: 1px solid var(--color-border);
    margin-left: -20px;
    margin-right: -20px;
    padding: 20px 20px 0;
    display: flex;
    justify-content: flex-end;
    background-color: var(--color-foreground);
    align-items: center;
    z-index: 1;
}

.global-cardActions-margin {
    position: relative;
    border-top: 1px solid var(--color-border);
    margin-left: -20px;
    margin-right: -20px;
    padding: 20px 20px 0;
    display: flex;
    justify-content: flex-end;
    background-color: var(--color-foreground);
    align-items: center;
    z-index: 1;
    margin-top: 20px;
}

.global-cardActions-postBorder {
    position: relative;
    border-top: 1px solid var(--color-border);
    margin-left: -20px;
    margin-right: -20px;
    padding: 20px 20px 0;
    display: flex;
    justify-content: flex-end;
    margin-top: -1px;
    background-color: var(--color-foreground);
    align-items: center;
    z-index: 1;
}

.global-cardActions-postBorderWithMargin {
    margin-top: -21px;
}

.global-cardActions-noBorder {
    margin-left: -20px;
    margin-right: -20px;
    padding: 20px 20px 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.global-cardActions-left {
    border-top: 1px solid var(--color-border);
    margin-left: -20px;
    margin-right: -20px;
    padding: 20px 20px 0;
    display: flex;
    align-items: center;
}

.global-cardActions-spaceBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.global-monospaced-contrast {
    font-family: "Inconsolata", monospace;
    background-color: var(--color-background);
    padding: 2px 8px;
    border-radius: 4px;
    color: var(--color-text_secondary)
}

/* LIB OVERRIDES */

.rnc__notification-item--info {
    background-color: var(--color-blue) !important;
    border-left-color: #0a62a4 !important;
}

@media only screen and (max-width: 1200px) {
    .global-inputGroup-inputInline {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .global-inputGroup {
        display: block;
        margin-bottom: 20px;
    }

    .global-inputGroup-input {
        width: 100%;
        margin-bottom: 10px;
    }
}
