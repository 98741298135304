.productValidationList {

}

.productValidationList-errors {
    display: flex;
    align-items: center;
}

.productValidationList-errors-count {
    margin-right: 20px;
}