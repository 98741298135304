.manufacturerlogo-wrapper {
  position: relative;
  width: 200px;
}

.manufacturerlogo {
  width: 200px;
  height: 200px;
  border-radius: 6px;
}

.manufacturerlogo-remove {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 14px;
  height: 14px;
  background-color: white;
  padding: 6px;
  border-radius: 4px;
  box-shadow: var(--shadow-minimal);
}

.manufacturerlogo-remove:hover {
  cursor: pointer;
}
