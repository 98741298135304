.pills {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.pill {
    display: inline-flex;
    color: var(--color-text_secondary);
    background-color: var(--color-background);
    border-radius: 4px;
    padding: 4px 10px;
    align-items: center;
}

.pill-remove {
    width: 10px;
    height: 10px;
    margin-left: 10px;
    fill: var(--color-text_tertiary);
    cursor: pointer;
    padding: 6px;
    border-radius: 2px;
    margin-right: -4px;
    transition: background-color 0.2s;
}

.pill-remove:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.pill-remove:active {
    background-color: rgba(0, 0, 0, 0.075);
}