.measures {
}

.measures-actions {
  display: flex;
  justify-content: flex-end;
  margin-left: -20px;
  margin-right: -20px;
  border-top: 1px solid var(--color-border);
  padding-top: 20px;
  padding-right: 20px;
}
