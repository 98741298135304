.admin-categories {
  padding: 20px;
}

.admin-categories-adminActions {
  margin-bottom: 20px;
}

.admin-categories-dangerPopup-message {
  margin-bottom: 20px;
}

.admin-categories-movePopup-hierarchy {
  margin-bottom: 20px;
}

.admin-categories-localeSwitch {
  margin-bottom: 20px;
  width: 100px;
}