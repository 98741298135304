.admin-tags {
}

.admin-tags-localeSwitch {
  width: 100px;
  margin-bottom: 20px;
}

.admin-tags-adminActions {
  margin-bottom: 20px;
}

.admin-tags-dangerPopup-message {
  margin-bottom: 20px;
}

.admin-tags-actions {
  margin-top: 20px;
}

.admin-tags-groupForm {
}
