.integrationSetup {

}

.integrationSetup-step {

}

.integrationSetup-step-text {
    max-width: 40vw;
}

.integrationSetup-step-url {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
}

.integrationSetup-step-url-url {
    display: inline-block;
    padding: 4px 6px;
}

.integrationSetup-step-url-button {
    margin-left: 10px;
}

.integrationSetup-step-button {
    margin-bottom: 20px;
}

.integrationSetup-step-progressBar {
    margin-bottom: 20px;
}

.integrationSetup-step-gif {
    display: block;
    width: 300px;
    border-radius: 6px;
    border: 2px solid var(--color-border);
    margin: 0 auto 20px;
}

.integrationSetup-step-img {
    display: block;
    width: 300px;
    margin: 0 auto 20px;
}

.integrationSetup-actions {
    margin-top: 20px;
    width: 100%;
}