.integrationCard {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.integrationCard span {
    display: flex;
    align-items: center;
}

.integrationCard-button {
    margin-left: 20px;
}

.integrationCard-icon {
    width: 50px;
    height: 50px;
}