.refunding {
}

.refunding-positions {
    margin-top: 20px;
}

.positions-refundPercentage {
    display: flex;
}

.positions-refundPercentage-element {
    padding: 2px 4px;
    border-radius: 4px;
    color: var(--color-text_secondary);
    font-size: 14px;
    font-weight: bold;
    margin-right: 6px;
    border: 2px solid var(--color-border);
}

.positions-refundPercentage-element:hover {
    cursor: pointer;
}

.refunding-error {
    margin-top: 20px;
    color: var(--color-danger);
}

.refunding-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
