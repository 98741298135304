.propertiesSearch {
}

.propertiesSearch-results-wrapper {
    position: absolute;
    background-color: var(--color-foreground);
    border-radius: 6px;
    box-shadow: var(--shadow-wide);
    border: 1px solid var(--color-border);
    z-index: 999;
}

.propertiesSearch-results {
    max-height: 400px;
    min-width: 300px;
    overflow-y: scroll;
}

.propertiesSearch-results-emptyState {
    margin-top: 40px;
    margin-bottom: 20px;
}

.propertiesSearch-result {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--color-border);
    padding: 10px;
    transition: background-color 0.2s;
}

.propertiesSearch-result:hover {
    cursor: pointer;
    background-color: var(--color-background_light);
}

.propertiesSearch-result:last-child {
    border: none;
}

.propertiesSearch-result-check {
    margin-left: 10px;
}

.propertiesSearch-result-info {
    margin-left: 10px;
    margin-right: 10px;
}

.propertiesSearch-result-info-check {
    margin-right: 10px;
}

.propertiesSearch-result-info-heading {
    font-size: 12px;
    color: var(--color-text_secondary);
}

.propertiesSearch-result-info-name {
}