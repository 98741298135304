.brandprofile {
    margin-top: -20px;
}

.brandprofile-deliveryPaused {
    margin-top: 20px;
}

.brandprofile-content {
}

.brandprofile-tabselector {
    margin-left: -20px;
    margin-right: -20px;
}

.brandprofile-logo {
    margin-top: 20px;
}

.brandprofile-logo-img {
    width: 200px;
    height: 200px;
    border: 1px solid var(--color-border);
    border-radius: 6px;
    object-fit: contain;
}

.brandprofile-logo-dropzone {
    width: 200px;
    height: 200px;
}

.brandprofile-logo-actions {
    width: 200px;
    margin-top: 10px;
}

.brandprofile-meta {
    margin-top: 20px;
}
