.manufactureraddress {
}

.manufactureraddress-inputs {
  display: flex;
}

.manufactureraddress-input {
  padding-right: 10px;
  margin-bottom: 20px;
}

.manufactureraddress-input:last-child {
  padding-right: 0;
}
