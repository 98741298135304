.propertyValidationRuleEdit {
    margin-bottom: 20px;
}

.callableDescription {
    margin-bottom: 20px;
    font-size: 14px;
    color: var(--color-blue);
    background-color: rgba(13, 124, 206, 0.1);
    padding: 16px 10px 10px;
    border-radius: 0 0 6px 6px;
    margin-top: -26px;
}