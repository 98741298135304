.brandShare-text {
    margin-bottom: 20px;
    color: var(--color-text_secondary);
}

.brandShare-stage {
    width: 100%;
    height: 324px;
    display: flex;
}

.brandShare-brands {
}

.brandShare-brand {
    display: flex;
    align-items: center;
}

.brandShare-brand-bubble {
    width: 14px;
    height: 14px;
    border-radius: 20px;
    margin-right: 10px;
}

.brandShare-brand-name {
    white-space: nowrap;
    font-size: 14px;
}