.jtce-properties {
    display: flex;
    position: relative;
}

.jtce-property {
    margin-right: 6px;
    cursor: pointer;
    border-radius: 100px;
    padding: 0 10px;
    font-size: 14px;
    white-space: nowrap;
    border: 1px solid var(--color-border);
    background-color: var(--color-background);
}