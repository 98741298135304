.productProperties {
}

.productProperties-input {
    padding: 6px 0;
}

.productProperties-animalSpeciesBadge {
    margin-right: 6px;
    display: flex;
    align-items: center;
}

.productProperties-actions {
    margin-top: 20px;
}
