.actionsPopout {
    background-color: var(--color-foreground);
    border-radius: 6px;
    box-shadow: var(--shadow-wide);
    z-index: 999999999;
    overflow: hidden;
    padding: 10px;
    max-width: 300px;
    animation: popIn 0.2s;
}

@keyframes popIn {
    from {
        opacity: 0;
        margin-top: 20px;
    }
    to {
        opacity: 1;
        margin-top: 0;
    }
}

.actionsPopout-action-group {
    border-top: 1px solid var(--color-border);
    margin-top: 10px;
    padding-top: 10px;
}

.actionsPopout-action-group:first-child {
    border: 0;
    margin: 0;
    padding: 0;
}

.actionsPopout-action {
    font-size: 14px;
    padding: 6px 10px;
    transition: background-color 0.125s;
    text-align: center;
    white-space: nowrap;
    border-radius: 6px;
    display: flex;
    align-items: center;
}

.actionsPopout-action:last-child {
    border: none;
}

.actionsPopout-action:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
}

.actionsPopout-action:active {
    background-color: rgba(0, 0, 0, 0.1);
}

.actionsPopout-action__save {
    color: var(--color-save);
}

.actionsPopout-action__primary {
    color: var(--color-primary);
}

.actionsPopout-action__blue {
    color: var(--color-blue);
}

.actionsPopout-action__danger {
    color: var(--color-danger);
}

.actionsPopout-action__secondary {
    color: var(--color-text_secondary);
}

.actionsPopout-action-icon {
    width: 14px;
    height: 14px;
    margin-right: 10px;
    fill: var(--color-text_secondary);
    margin-top: -4px;
}

.actionsPopout-action-icon__save > svg {
    fill: var(--color-save);
}

.actionsPopout-action-icon__primary > svg {
    fill: var(--color-primary);
}

.actionsPopout-action-icon__blue > svg {
    fill: var(--color-blue);
}

.actionsPopout-action-icon__danger > svg {
    fill: var(--color-danger);
}

.actionsPopout-action-icon__secondary > svg {
    fill: var(--color-text_secondary);
}