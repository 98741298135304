.valueArray {

}

.valueArray-title {
    display: flex;
    align-items: center;
}

.valueArray-items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 600px;
}

.valueArray-item {
    background-color: var(--color-background_light);
    margin-right: 10px;
    padding: 0 14px;
    border-radius: 6px;
    height: 40px;
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.valueArray-item:last-child {
    margin-right: 0;
}

.valueArray-item-iconDelete {
    width: 12px;
    height: 12px;
    margin-left: 10px;
}

.valueArray-item-iconDelete:hover {
    cursor: pointer;
}

.valueArray-actions {
    margin-top: 10px;
}

.valueArray-emptyState {
    color: var(--color-text_tertiary);
    padding: 10px;
}