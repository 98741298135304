@keyframes popup {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.popup {
    opacity: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    z-index: 10005;
    animation-name: popup;
    animation-duration: 0.3s;
}

@keyframes popup-card {
    from {
        margin-top: 100px;
    }
    to {
        margin-top: 0;
    }
}

.popup-card {
    position: relative;
    background-color: var(--color-foreground);
    border-radius: 6px;
    animation-name: popup-card;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
    z-index: 10007;
}

.popup-card-content {
    padding: 20px;
    height: 100%;
}

.popup-card-content-section {
}

.popup-card-content-section-text {
    margin-bottom: 20px;
}

.popup-card-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 14px;
    z-index: 999;
    padding: 10px;
    cursor: pointer;
    background-color: var(--color-foreground);
    border-radius: 6px;
}

.popup-title,
.popup-card-content-title {
    font-weight: bold;
    margin-bottom: 20px;
    color: var(--color-text_primary)
}

.popup-card-content-actions {
    border-top: 1px solid var(--color-border);
    margin-left: -20px;
    margin-right: -20px;
    padding: 20px 20px 0;
}

.popup-text,
.popup-card-content-text {
    margin-bottom: 20px;
}

.popup-card-helpOverlayActions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.popup-card-helpOverlayActions-dontShowAgain {
    margin-right: 20px;
}

.popup-background {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: red;
    z-index: 10004;
}
