.settingsMenu {
    position: fixed;
    background-color: var(--color-foreground);
    bottom: 20px;
    left: 100px;
    z-index: 999;
    border-radius: 6px;
    box-shadow: var(--shadow-wide);
    border: 1px solid var(--color-border);
    padding: 0 10px;
    min-width: 200px;
    animation: menuAppear 0.3s ease;
}

@keyframes menuAppear {
    from {
        opacity: 0;
        bottom: 0;
    }
    to {
        opacity: 1;
        bottom: 20px;
    }
}

.settingsMenu-section {
    padding: 10px;
    border-bottom: 1px solid var(--color-border);
    margin-left: -10px;
    margin-right: -10px;
}

.settingsMenu-section:last-child {
    border-bottom: none;
}

.settingsMenu-section-user {
    display: flex;
    align-items: center;
    padding: 10px;
}

.settingsMenu-section-user-meta {

}

.settingsMenu-section-user-meta-name {

}

.settingsMenu-section-user-meta-email {
    color: var(--color-text_secondary)
}

.settingsMenu-section-item {
    display: flex;
    color: var(--color-text_primary);
    align-items: center;
    padding: 10px;
    border-radius: 6px;
    transition: background-color 0.15s;
}

.settingsMenu-section-link:hover {
    background-color: var(--color-background_light);
}

.settingsMenu-section-item-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.settingsMenu-section-item-name {

}

.settingsMenu-section-item-name-danger {
    color: var(--color-danger)
}