.loading {
}

.loading-container {
    width: 100%;
    height: 100%;
    min-height: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loading-container-message {
    color: var(--color-primary);
    margin-bottom: 20px;
    animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.loading-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.loading-ripple div {
    position: absolute;
    border: 4px solid var(--color-primary);
    opacity: 1;
    border-radius: 50%;
    animation: loading-ripple 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loading-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes loading-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

.minimalLoading {
    width: 20px;
    height: 20px;
    display: flex;
    flex-wrap: wrap;
}

.minimalLoading-square {
    height: 8px;
    width: 8px;
    margin: 1px;
    box-sizing: border-box;
    display: inline-block;
    border-radius: 2px;
}

.minimalLoading-square-one {
    animation: pulse 2s ease-out infinite;
}

.minimalLoading-square-two {
    animation: pulse 2s ease-out infinite;
    animation-delay: 0.5s;
}

.minimalLoading-square-three {
    animation: pulse 2s ease-out infinite;
    animation-delay: 1s;
}

.minimalLoading-square-four {
    animation: pulse 2s ease-out infinite;
    animation-delay: 1.5s;
}

@keyframes pulse {
    0% {
        background-color: var(--color-primary);
    }
    50% {
        background-color: transparent;
    }
    100% {
        background-color: var(--color-primary);
    }
}

.spinner-wrapper {
    width: 100%;
}

.spinner {
    width: 34px;
    height: 34px;
    padding: 6px;
    box-sizing: border-box;
    border-radius: 6px;
}

.spinner-icon {
    width: 100%;
    height: 100%;
}

.spinner-icon-loading {
    animation: arrow-circle infinite 1.5s linear;
}

@keyframes arrow-circle {
    0% {
        -webkit-transform: rotate(-360deg);
        -ms-transform: rotate(-360deg);
        -o-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
    100% {
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
    }
}
