.stocks {
    position: relative;
}

.stock {
    position: relative;
    border-bottom: 1px solid var(--color-border);
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 20px;
    padding: 0 20px 20px;
}

.stock:first-child {
    margin-top: 0;
}

.stock-inherited:before {
    position: absolute;
    content: ' ';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--color-inherited);
    opacity: 0.15;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.stock-deleteButton {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.stocks-actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.stocks-actions-text {
    margin-left: 20px;
    color: var(--color-gray);
    font-size: 14px;
}

.stock-disclaimer {
    color: var(--color-text_secondary);
    font-size: 14px;
    margin-top: 10px;
}

.stocks-priorityDisclaimer {
    margin-top: 20px;
    color: var(--color-text_secondary)
}

@media only screen and (max-width: 1200px) {

    .stocks-actions {
        display: block;
    }
}
