.customer {
}

.customer-customer {
    display: flex;
    justify-content: space-between;
}

.customer-customer-number {
    display: inline-flex;
    align-items: center;
    margin-top: -1px;
    font-family: 'Inconsolata', monospace;
    background-color: var(--color-stage);
    padding: 2px 4px;
    border-radius: 4px;
    color: var(--color-text_secondary);
}

.customer-email {
    color: var(--color-text_secondary);
}

.customer-cta {
    margin-top: 20px;
}

.customer-cta-loyalty {
    margin-top: 10px;
}