.inpettoClientBasedOrderDeliveryInformationImport {
    margin-top: -20px;
    padding-left: 20px;
    padding-top: 20px;
    border-left: 1px solid var(--color-border);
    margin-left: 20px;
    display: flex;
}

.inpettoClientBasedOrderDeliveryInformationImport-inner {
    border-left: 1px solid var(--color-border);
    height: 100%;
    margin-top: -20px;
    padding-top: 20px;
    padding-left: 20px;
    margin-left: 20px;
    padding-bottom: -20px;
    width: 100%;
}

.inpettoClientBasedOrderDeliveryInformationImport-inner > .card {
    margin-bottom: 0 !important;
}