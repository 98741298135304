.titlevaluelist {

}

.titlevaluelist-inline {
    display: flex;
}

.titlevaluelist-entry {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}

.titlevaluelist-inline > .titlevaluelist-entry {
    margin-bottom: 0;
    margin-right: 20px;
}

.titlevaluelist-entry-title {
    color: var(--color-text_secondary);
    white-space: nowrap;
}

.titlevaluelist-entry-value {
    margin-left: 20px;
    display: flex;
    align-items: center;
}

.titlevaluelist-inline .titlevaluelist-entry-value {
    margin-left: 6px;
}

.titlevaluelist-entry-value-na {
    color: var(--color-text_tertiary)
}

.titlevaluelist-entry-value-hint {
    margin-left: -6px;
}
