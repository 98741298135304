.listPagination {
    border-top: 1px solid var(--color-border);
    margin: -1px -20px 0;
    padding: 20px 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
    .listPagination {
        flex-direction: column;
        align-items: flex-start;
    }

    .listPagination-dropdown {
        margin-bottom: 10px;
    }
}

.listPagination-dropdown {
    display: inline-block;
}

.listPagination-pageSelector {
    display: flex;
    align-items: center;
    box-shadow: var(--shadow-minimal);
    border-radius: 6px;
}

.listPagination-pageSelector-btn {
    width: 10px;
    height: 10px;
    border: 1px solid var(--color-border);
    padding: 10px;
}

.listPagination-pageSelector-btn:first-child {
    border-radius: 6px 0 0 6px;
}

.listPagination-pageSelector-btn:last-child {
    border-radius: 0 6px 6px 0;
}

.listPagination-pageSelector-btn:hover {
    cursor: pointer;
}

.listPagination-pageSelector-btn__inactive {
    background-color: var(--color-background_light);
}

.listPagination-pageSelector-pages {
    display: flex;
    align-items: center;
    margin-right: -1px;
}

.listPagination-pageSelector-page {
    color: var(--color-text_primary);
    border: 1px solid var(--color-border);
    height: 30px;
    line-height: 30px;
    width: 30px;
    text-align: center;
    margin-left: -1px;
    font-size: 14px;
}

.listPagination-pageSelector-page:hover {
    cursor: pointer;
    background-color: var(--color-background_light);
}

.listPagination-pageSelector-page__active {
    font-size: 14px;
    color: var(--color-text_primary);
    background-color: var(--color-background_light);
}