.manufacturerinfo {
}

.manufacturerinfo-inputs {
  display: flex;
}

.manufacturerinfo-input {
  flex: 1;
  padding-right: 10px;
  margin-bottom: 20px;
}

.manufacturerinfo-input:last-child {
  padding-right: 0;
}
