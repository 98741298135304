.receipts {
    margin-left: -20px;
    margin-right: -20px;
}

.receipt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-border);
    padding: 10px 20px;
}

.receipt:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.receipt-title {
    display: flex;
    align-items: center;
}

.receipt-title-name {
}

.receipt-title-number {
    font-size: 16px;
    margin-left: 10px;
    font-family: "Inconsolata", monospace;
    color: var(--color-text_secondary);
}

.receipt-date {
    display: flex;
    font-size: 14px;
    color: var(--color-text_secondary);
}