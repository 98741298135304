.headerSetting {
    padding: 6px 10px;
    border-radius: 6px;
    transition: background-color 0.1s;
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: var(--color-text_primary);
    border: 1px solid var(--color-border);
    margin-top: 6px;
    font-size: 14px;
    z-index: 1;
    background-color: var(--color-foreground);
}

.headerSetting:first-child {
    margin-top: 0;
}

.headerSetting:active {
    cursor: grab !important;
}

.headerSetting > span {
    display: flex;
    align-items: center;
}

.headerSetting:hover {
    cursor: pointer;
    background-color: var(--color-background_light);
}

.headerSetting-dragIcon {
    width: 10px;
    height: 14px;
    margin-right: 10px;
    fill: var(--color-text_tertiary)
}

.headerSetting-toggle {
    margin-right: 10px;
}

.headerSetting-pin {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin-right: 10px;
}

.headerSetting-pin__active > .headerSetting-pin-icon {
    fill: var(--color-text_secondary);
}

.headerSetting-pin-icon {
    width: 12px;
    height: 12px;
    fill: var(--color-text_tertiary)
}