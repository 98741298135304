.shippingaddress {
}

.shippingaddress-element {
  display: flex;
  justify-content: space-between;
}

.shippingaddress-element-title {
  color: var(--color-text_secondary);
  margin-right: 20px;
}
