.areaChartLegend {
    display: flex;
    margin-bottom: 10px;
}

.areaChartLegend-entry {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.areaChartLegend-entry-color {
    width: 8px;
    height: 2px;
    margin-right: 4px;
    border-radius: 4px;
}

.areaChartLegend-entry-label {
    color: var(--color-text_secondary);
}