.menuList {
    background-color: var(--color-background_light);
    border-radius: 6px;
    padding: 10px;
}

.menuList-item {
    background-color: var(--color-foreground);
    padding: 10px;
    border-radius: 6px;
    border: 1px solid var(--color-border);
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.menuList-item:last-child {
    margin-bottom: 0;
}

.menuList-item-checkbox {
    margin-right: 10px;
}

.menuList-item-title {
    font-size: 14px;
    font-weight: bold;
}

.menuList-item-actions {
}

.menuList-addButton {
    margin-top: 10px;
}