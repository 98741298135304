.knowledgebasepopup {
    font-size: 16px;
}

.knowledgebasepopup-cta {
    background-color: var(--color-hintBackground);
    color: var(--color-blue);
    font-size: 14px;
    font-weight: bold;
    border-radius: 100px;
    padding: 0 10px;
    margin-left: 10px;
}

.knowledgebasepopup-cta:hover {
    cursor: pointer;
}