.orderStateOverview {
    display: flex;
    align-items: center;
    flex-direction: var(--layout-responsiveFlexDirection);
}

.orderStateOverview-stacks {
    display: flex;
    width: 100%;
    gap: 20px;
    margin-left: 20px;
    flex-direction: var(--layout-responsiveFlexDirection);
}

.orderStateOverview-stackedBar {
    flex: 1 1;
}

.orderStateOverview-stackedBar-title {
    margin-bottom: 4px;
    font-size: 14px;
    color: var(--color-text_secondary)
}

.orderStateOverview-stackedBars-wrapper {
    background-color: var(--color-background_light);
    padding: 20px;
    border-radius: 6px;
    margin-bottom: 10px;
}

.orderStateOverview-stackedBars {
    width: 100%;
    height: 10px;
    border-radius: 4px;
    display: flex;
    margin-bottom: 10px;
}

.orderStateOverview-stackedBars-bar-wrapper {
    position: relative;
}

.orderStateOverview-stackedBars-bar {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-weight: bold;
    box-sizing: border-box;
    overflow: hidden;
    border-bottom: 3px solid;
    font-size: 14px;
    white-space: nowrap;
    opacity: 0.8;
    border-radius: 10px;
}

.orderStateOverview-stackedBars-bar:hover {
    cursor: pointer;
}

.orderStateOverview-stackedBars-bar-fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
}

.orderStateOverview-stackedBars-bar-content {
    z-index: 2;
}

.orderStateOverview-stackedBars-bar-content-text {
    z-index: 1;
}

.orderStateOverview-list {

}

.orderStateOverview-spotlights {
    display: flex;
    margin-bottom: 10px;
    gap: 10px;
}

.orderStateOverview-spotlight {
    flex: 1;
    text-align: center;
    background-color: var(--color-background_light);
    border-radius: 6px;
    padding: 10px;
}

.orderStateOverview-spotlight-icon {
    width: 22px;
    height: 22px;
    margin-right: 10px;
}

.orderStateOverview-spotlight-icon__warning {
    fill: var(--color-yellow);
}

.orderStateOverview-spotlight-value {
    font-size: var(--size-text_veryLarge);
    display: flex;
    align-items: center;
    justify-content: center;
}

.orderStateOverview-spotlight-value__hoverable:hover {
    cursor: pointer;
}

.orderStateOverview-spotlight-title {
    font-size: var(--size-text_small);
    color: var(--color-text_secondary);
    white-space: nowrap;
}