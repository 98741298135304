.shippingdetails {
}

.shippingdetails-actions {
  display: flex;
  justify-content: flex-end;
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px;
  padding-bottom: 0;
  margin-top: -1px;
}

.shippingdetails-returnAnnouncement-head {
  display: flex;
  align-items: center;
}

.shippingdetails-returnAnnouncement-head-title {
  font-weight: bold;
  margin-right: 20px;
}

.shippingdetails-returnAnnouncement-head-quantity-input {
  width: 60px;
}

.shippingdetails-returnAnnouncement-head-quantity-max {
  margin-left: 10px;
  margin-top: -2px;
}
