.tableCompletionBar {
    display: flex;
    border-radius: 6px 6px 0 0;
    border-bottom: 0;
    background-color: var(--color-background_light);
}

.tableCompletionBar:hover {
    cursor: pointer;
}

.tableCompletionBar-bar {
    width: 100%;
}

.tableCompletionBar-details {
    background-color: var(--color-foreground);
    border-radius: 6px;
    padding: 4px 10px;
    box-shadow: var(--shadow-wide);
    font-size: 14px;
    font-weight: bold;
    border: 1px solid var(--color-border);
}

.tableCompletionBar-details span {
    font-weight: normal;
    color: var(--color-text_secondary);
}