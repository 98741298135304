.newPropertyOption {

}

.newPropertyOption-newOptionPopup-header {
    display: flex;
    justify-content: space-between;
}


.newPropertyOption-newOptionPopup-header-action {
    color: var(--color-blue);
    font-size: 14px;
}

.newPropertyOption-newOptionPopup-header-action:hover {
    cursor: pointer;
}

.newPropertyOption-newOptionPopup-assetId {
    display: flex;
    justify-content: space-between;
    align-items: center;
}