.inputHeader {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}

.inputHeader-title {
    color: var(--color-text_secondary);
    white-space: nowrap;
}

.inputHeader-title_inherited {
    color: var(--color-inherited);
}

.inputHeader-badge {
    margin-left: 10px;
}