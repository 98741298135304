.dateRange {
    position: relative;
    display: flex;
    border: 1px solid var(--color-border);
    border-radius: 6px;
    background-color: var(--color-input_background);
    box-shadow: var(--shadow-minimal);
    box-sizing: border-box;
    height: 40px;
    align-items: center;
}

.dateRange-date {
    padding: 0 10px;
}

.dateRange-date span {
    font-size: 14px;
    color: var(--color-text_secondary);
    padding: 0 6px;
}

.dateRange-date:hover {
    cursor: pointer;
}

.dateRange-pop {
    background-color: var(--color-foreground);
    border: 1px solid var(--color-border);
    border-radius: 6px;
    box-shadow: var(--shadow-wide);
    padding: 10px;
    animation: popFadeIn 0.3s;
}

@media only screen and (max-width: 1200px) {
    .dateRange-pop {
        position: fixed;
        top: 20px;
        left: 20px;
        right: 20px;
    }

    .dateRange-pop-dates {
        overflow: scroll;
    }
}

@keyframes popFadeIn {
    from {
        opacity: 0;
        margin-top: -20px;
    }
    to {
        opacity: 1;
        margin-top: 0;
    }
}

.dateRange-pop-dates {
    display: flex;
}

.dateRange-pop-date {

}

.dateRange-pop-date-title {
    text-align: center;
    padding: 2px;
    background-color: var(--color-background_light);
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
    color: var(--color-text_secondary)
}

.dateRange-pop-dates-divider {
    height: 100%;
    width: 10px;
}

.dateRange-pop-error {
    font-size: 14px;
    color: var(--color-danger);
    background-color: rgba(234, 66, 17, 0.1);
    text-align: center;
    padding: 10px;
    margin-bottom: 10px;
    margin-top: 4px;
    border-radius: 6px;
}

.dateRange-pop-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
}

.dateRange-datepicker {
    flex: 1;
}

.dateRange-datepicker:hover,
.dateRange-datepicker .react-datepicker__input-container input {
    cursor: pointer;
}

.dateRange-datepicker .react-datepicker__input-container input {
    font-size: 16px;
    border: none;
    font-family: 'Sofia Pro', sans-serif;
    width: 100px;
    text-align: center;
    height: 38px;
    background-color: var(--color-input_background);
    color: var(--color-text_primary)
}

.dateRange-datepicker-1 .react-datepicker__input-container input {
    border-radius: 6px 0 0 6px;
    padding: 0;
}

.dateRange-datepicker-2 .react-datepicker__input-container input {
    padding: 0;
}

.dateRange-icon {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-background_light);
    border-radius: 0 4px 4px 0;
    border-left: 1px solid var(--color-border);
}

.dateRange-icon-wrapper {
    width: 50px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dateRange-icon-icon {
    width: 20px;
}
