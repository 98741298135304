.textHighlight {
    position: relative;
    font-weight: bold;
    border-radius: 6px;
    display: inline-block;
    overflow: hidden;
    z-index: 1;
}

.textHighlight-embedded {
    margin-left: 3px;
    margin-right: 3px;
}

.textHighlight-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    z-index: -1;
}

.textHighlight-text {
    padding: 1px 6px;
    z-index: 1;
}