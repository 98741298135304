.clickOutsideContainer {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.clickOutsideContainer-fixedViewportAwareElement-wrapper {
    position: fixed;
    z-index: 9999;
}


.clickOutsideContainer-fixedViewportAwareElement {
    position: absolute;
}