.documentLayout {
    max-width: 840px;
    border-radius: 6px;
    border: 1px solid var(--color-border);
    margin: 0 auto;
    padding: 20px;
}

.documentLayout-logo {
    width: 100%;
    display: flex;
    justify-content: right;
}

.documentLayout-logo-container {
    position: relative;
}

.documentLayout-logo-container-button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.2s;
}

.documentLayout-logo-container:hover .documentLayout-logo-container-button {
    opacity: 1;
}

.documentLayout-logo-img {
    height: 240px;
}

.documentLayout-logo-dropzone {
    width: 300px;
}

.documentLayout-section {
    display: flex;
    margin-top: 40px;
}

.documentLayout-section-1 {
    flex: 1;
}

.documentLayout-section-2 {
    flex: 2;
}

.global-inputGroup:has(.documentLayout-input) {
    margin-bottom: 0;
    width: 100%;
}

.global-inputGroup-input:has(.documentLayout-input) {
    margin-right: 4px;
    width: 100%;
}

.documentLayout-input {
    margin-bottom: 4px !important;
}

.documentLayout-input .input-field {
    height: 30px;
}

.documentLayout-input input {
    font-size: 14px;
}

.documentLayout-section-block {

}

.documentLayout-dummyCustomer {
    font-size: 16px;
    font-weight: normal;
}

.documentLayout-dummyCustomer-small {
    font-size: 14px;
    font-weight: normal;
}

.documentLayout-dummyHeader {
    font-size: 14px;
    width: 100%;
    line-height: 18px;
}

.documentLayout-title {
    font-size: 22px;
    font-weight: bold;
}

.documentLayout-placeholder {
    height: 24px;
    width: 100%;
    background-color: var(--color-background_light);
    border-radius: 6px;
    margin-top: 10px;
}

.documentLayout-bottomTextLines {
    font-size: 14px;
}

.documentLayout-additionalBottomTextLines {
    margin-top: 4px;
}

.documentLayout-footer {
    margin-top: 100px;
    border-top: 2px solid var(--color-text_primary);
    padding-top: 10px;
}

.documentLayout-footer-columns {
    display: flex;
}

.documentLayout-footer-column {
    position: relative;
    display: flex;
}

.documentLayout-footer-column-dragHandle {
    position: absolute;
    right: 0;
    width: 2px;
    height: 100%;
    background-color: var(--color-border);
    box-shadow: var(--shadow-minimal);
    border: 2px solid var(--color-border);
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.1s, width 0.1s, right 0.1s;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.documentLayout-footer-column:hover .documentLayout-footer-column-dragHandle {
    opacity: 1;
    z-index: 999;
    background-color: var(--color-foreground);
}

.documentLayout-footer-column-dragHandle:active {
    cursor: grab;
    opacity: 0.5 !important;
    right: -10px;
}

.documentLayout-footer-column-dragHandle:hover {
    width: 40px;
    right: -20px;
    z-index: 999;
}

.documentLayout-footer-column-dragHandle-icon {
    opacity: 0;
    max-width: 20px;
}

.documentLayout-footer-column-dragHandle:hover .documentLayout-footer-column-dragHandle-icon {
    opacity: 1;
    transition: opacity 0.1s;
}

.documentLayout-footer-actions {
    display: flex;
    margin-top: 20px;
    gap: 10px;
}