.profileOverview {
    display: flex;
    align-items: center;
}

.profileOverview-meta {
    margin-left: 10px;
}

.profileOverview-meta-name {
    font-size: 20px;
    display: flex;
    align-items: center;
}

.profileOverview-meta-name span {
    font-size: 14px;
    background-color: var(--color-background_light);
    padding: 2px 6px;
    border-radius: 6px;
    margin-left: 10px;
}

.profileOverview-meta-email {
    color: var(--color-text_secondary)
}

.profileOverview-meta-roles {
    margin-top: 10px;
}

.profileOverview-meta-role {
    font-size: 14px;
}