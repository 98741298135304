.hint-link {
    color: inherit;
}

.hint-wrapper {
}

.hint {
    position: relative;
    display: inline-flex;
    margin-bottom: 20px;
    border: 1px solid var(--color-border);
    width: auto;
    border-radius: 6px;
}

.hint-icon {
    border-right: 1px solid var(--color-border);
    padding: 4px 10px;
    height: 20px;
    width: 16px;
    background-color: var(--color-background_light);
    border-radius: 6px 0 0 6px;
}

.hint-label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    color: var(--color-text_secondary);
}

.hint-hintBox {
    position: absolute;
    left: calc(100% + 10px);
    top: 0;
    padding: 10px;
    background-color: var(--color-background_light);
    border-radius: 6px;
    font-size: 14px;
    width: 240px;
    box-shadow: var(--shadow-minimal);
    color: var(--color-text_secondary);
}

.hint-hintBox-text {
}

.hint-hintBox-more {
    margin-top: 10px;
}

.hint-small {
    position: relative;
}

.hint-small-icon {
    color: var(--color-blue);
    margin-left: 10px;
    font-size: 12px;
    font-weight: bold;
    background-color: var(--color-hintBackground);
    border-radius: 100%;
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 4px;
}

.hint-small-bubble {
    color: var(--color-blue);
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
    background-color: var(--color-hintBackground);
    border-radius: 100px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 6px;
}

.hint-small-icon:hover,
.hint-small-bubble:hover {
    cursor: pointer;
}

.hint-small-children {
    cursor: pointer;
}

.hint-small-message {
    padding: 14px;
    max-width: 300px;
    border-radius: 6px;
    border: 1px solid var(--color-blue);
    box-shadow: 0 6px 20px rgba(13, 124, 206, 0.3);
    background-color: var(--color-foreground);
    font-size: 14px;
    color: var(--color-hint-text);
    min-width: 300px;
    font-weight: normal;
    z-index: 999;
    white-space: normal;
    text-align: left;
}

.hint-small-message-toolTip {
    font-family: "Sofia Pro", sans-serif;
    text-align: left;
    background-color: var(--color-foreground);
    color: var(--color-hint-text);
    border: 1px solid var(--color-border);
    min-width: 0;
    padding: 4px 10px;
    box-shadow: var(--shadow-wide);
}

.hint-small-message-paragraph {
    margin-bottom: 10px;
}

.hint-small-message-paragraph:last-child {
    margin-bottom: 0;
}