
.navigationBlocker-wrapper {
    position: fixed;
    z-index: 9999999999;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
}

.navigationBlocker {
    background-color: var(--color-foreground);
    border-radius: 6px;
    font-size: 14px;
    max-width: 25vw;
    flex-grow: 0;
    height: fit-content;
    margin-top: 40px;
    box-shadow: 0 6px 80px rgba(255, 126, 31, 0.3);
    border: 2px solid var(--color-warning);
    display: flex;
    animation: blockerPopIn 0.5s;
}

@keyframes blockerPopIn {
    from {
        opacity: 0;
        margin-top: -200px;
    }
    to {
        opacity: 1;
        margin-top: 40px;
    }
}

.navigationBlocker-icon-wrapper {
    background-color: var(--color-warning);
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navigationBlocker-icon {
    fill: white;
    width: 20px;
    height: 20px;
}

.navigationBlocker-inner {
    padding: 10px;
}

.navigationBlocker-text {
    color: var(--color-text_primary);
    width: 100%;
}

.navigationBlocker-actions {
    display: flex;
    margin-top: 10px;
}

.navigationBlocker-action {
    background-color: rgba(0, 0, 0, 0.05);
    color: var(--color-text_primary);
    padding: 4px 10px;
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
    transition: background-color 0.1s;
    font-weight: bold;
}

.navigationBlocker-action:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.navigationBlocker-action:active {
    background-color: rgba(0, 0, 0, 0.1);
}

.navigationBlocker-action__success {
    background-color: var(--color-success);
    color: white;
}

.navigationBlocker-action__success:hover {
    background-color: #1ea24c;
}

.navigationBlocker-action__success:active {
    background-color: #1a9144;
}