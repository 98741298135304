.listControls {
    display: flex;
    border-bottom: 1px solid var(--color-border);
    margin-right: -20px;
    margin-left: -20px;
    padding: 0 20px 20px;
}

.listControls-search {
    margin-right: 10px;
    display: flex;
}

.listControls-search-count {
    margin-left: 10px;
}

.listControls-filters {
    display: flex;
    margin-right: 20px;
    width: 100%;
    flex-wrap: wrap;
    gap: 10px
}

.listControls-settings {

}

.listControls-setting {
    margin-bottom: 10px;
}

.listControls-setting:last-child {
    margin-bottom: 0;
}

.listControls-cursor {
    justify-self: flex-end;
    align-self: flex-end;
    display: flex;
}

.listControls-cursor-position {
    padding: 0 5px;
    display: flex;
    align-items: center;
}

.listControls-cursor-position-number {
    padding: 0 10px;
}

.listControls-itemCount {
    color: var(--color-text_secondary);
    font-size: var(--size-text_small);
    background-color: var(--color-background);
    border-radius: 6px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    padding: 0 10px;
    white-space: nowrap;
}

.listControls-itemCount-number {
    margin-right: 6px;
    text-align: center;
}

.listControls-rightAlignedChildren {
    display: flex;
    align-self: flex-start;
}

.listControls-rightAlignedChildren-more {
    margin-left: 10px;
}

@media only screen and (max-width: 1200px) {

    .listControls {
        flex-direction: column;
    }

    .listControls-search {
        flex-wrap: wrap;
    }

    .listControls-search-count {
        margin-left: 0;
        margin-top: 10px;
    }

    .listControls-filters {
        margin-left: 0;
        margin-right: 0;
        margin-top: 10px;
        display: block;
    }
}
