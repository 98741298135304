.profileImage {
    background-color: rgba(13, 124, 206, 0.1);
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-right: 10px;
    color: var(--color-blue);
    font-weight: bold;
}

.profileImage-default {
    background-color: rgba(244, 130, 130, 0.2);
}

.profileImage-img {
    width: 50%;
    height: 50%;
}