.dailyBestsellers {

}

.dailyBestsellers-chart {
    margin-top: 20px;
}

.dailyBestsellers-tabs {
    margin-left: -20px;
    margin-right: -20px;
}

.dailyBestsellers-tab {
    padding: 20px 20px 0;
}