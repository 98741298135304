.dropzone {
    border-radius: 6px;
    height: 100%;
}

.dropzone-inner {
    position: relative;
    border: 1px dashed var(--color-border);
    border-radius: 6px;
    box-sizing: border-box;
    background-color: var(--color-background_light);
}

.dropzone-inner:hover {
    cursor: pointer;
}

.dropzone-inner-text {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: var(--color-text_tertiary);
    text-align: center;
    padding: 10px;
}

.dropzone-inner-text-link {
    color: var(--color-blue);
}

.dropzone-inner-progress {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: var(--color-text_tertiary);
    text-align: center;
    padding: 10px;
}

.dropzone-inner-progress-bar {
    width: 100%;
    height: 6px;
    border-radius: 100px;
    background-color: var(--color-background);
}

.dropzone-inner-progress-bar-meter {
    height: 100%;
    border-radius: 100px;
    background-color: var(--color-success);
    transition: width 0.2s;
}

.dropzone-inner-progress-text {
    margin-top: 10px;
}

.dropzone-inner-text-icon {
    width: 34px;
}
