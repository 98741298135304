.accountHolder {

}

.accountHolder-form {
    max-height: 70vh;
    overflow-y: auto;
    margin-bottom: 20px;
}

.accountHolder-text {
    max-width: 100%;
}