.paymentMethod {
    display: flex;
    align-items: center;
}

.paymentMethod-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.paymentMethod-icon-klarna {
    width: 40px;
}

.paymentMethod-icon-gpay {
    width: 40px;
}

.paymentMethod-icon-applePay {
    width: 30px;
}

.paymentMethod-name {
    color: var(--color-text_secondary)
}