.warehouses {
}

.warehouses-localeSwitch {
  width: 100px;
  margin-bottom: 20px;
}

.warehouses-adminActions {
  margin-bottom: 20px;
}

.warehouses-dangerPopup-message {
  margin-bottom: 20px;
}

.warehouses-actions {
  margin-top: 20px;
}

.warehouses-groupForm {
  margin-bottom: 20px;
}
