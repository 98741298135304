.dropdown-wrapper {
    position: relative;
    align-self: flex-end;
}

.dropdown-wrapper:hover {
    cursor: pointer;
}

.dropdown {
    position: relative;
    border: 1px solid var(--color-border);
    border-radius: 6px;
    font-family: 'Sofia Pro', sans-serif;
    font-size: 14px;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 30px;
    height: 40px;
    width: 100%;
    box-shadow: var(--shadow-minimal);
    background-color: var(--color-input_background);
    color: var(--color-text_primary)
}

.dropdown-inputEmbedded {
    border-radius: 0 6px 6px 0;
    box-shadow: none;
    border: 1px solid var(--color-border);
    border-right: none;
    width: auto;
    margin-right: -8px;
    height: auto;
}

.dropdown-alert {
    border-color: var(--color-danger);
    color: var(--color-danger);
}

.dropdown-container {
    position: relative;
    height: 100%;
}

.dropdown-title-wrapper {
    display: flex;
    margin-bottom: 10px;
}

.dropdown-title {
    color: var(--color-text_secondary);
}

.dropdown-title-inherited {
    color: var(--color-inherited);
}

.dropdown:hover {
    background-color: var(--color-background_light);
    cursor: pointer;
}

.dropdown:active {
    background-color: var(--color-background);
}

select {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
}

.dropdown-arrow {
    position: absolute;
    top: calc(50% - 2px);
    right: 10px;
    width: 10px;
}

.dropdown:focus {
    outline: none;
}

.dropdown-options {
}

.dropdown-disabled {
    background-color: var(--color-background);
}

.dropdown-inherited {
    border-color: var(--color-inherited);
    color: var(--color-inherited);
}

.check {
}

.check-text {
    margin-left: 10px;
    font-size: 14px;
    color: var(--color-text_secondary)
}

.check-body {
    display: flex;
    height: 100%;
    align-items: center;
}

.tabselector {
    display: flex;
    min-height: 50px;
    align-items: center;
    border-bottom: 1px solid var(--color-border);
    flex-wrap: wrap;
}

.tabselector-spanToEdges {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: -20px;
    margin-right: -20px;
}

.tabselectorCard {
    display: flex;
    min-height: 50px;
    align-items: center;
    flex-wrap: wrap;
    background-color: var(--color-foreground);
    border-radius: 6px;
    padding: 0 20px;
    box-shadow: var(--shadow-minimal);
}

.tabselector-tab {
    height: 50px;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    color: var(--color-gray);
}

.tabselector-tab:hover {
    cursor: pointer;
}

.tabselector-tab-active {
    font-weight: bold;
    color: var(--color-text_primary);
}

.tabselector-tab-active:after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 4px;
    border-radius: 10px 10px 0 0;
    background-color: var(--color-text_primary);
}
