.animalSpeciesAndProductGroup {

}

.animalSpeciesAndProductGroup-section {
    margin-bottom: 20px;
}

.animalSpeciesAndProductGroup-section-dogTags {
    display: flex;
    flex-wrap: wrap;
}

.animalSpeciesAndProductGroup-section-dogTag {
    display: inline-flex;
    background-color: var(--color-background_light);
    padding: 5px 10px;
    border-radius: 4px;
    margin-right: 10px;
}

.animalSpeciesAndProductGroup-section-dogTag_emptyAlert {
    display: inline-flex;
    background-color: rgba(234, 66, 17, 0.10);
    padding: 5px 10px;
    border-radius: 4px;
    margin-right: 10px;
    color: var(--color-danger)
}

.group-loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
