.productTour {

}

.productTour-card {
    background-color: var(--color-hintBackground) !important;
}

.productTour-text {
    margin-top: -10px;
    margin-bottom: 20px;
    color: var(--color-text_secondary)
}