.stocksettings {
  display: flex;
  align-items: center;
}

.stocksettings-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.stocksetting {
  display: flex;
  margin-right: 20px;
}

.stocksettings-text {
  margin-left: 10px;
}
