.badge {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    padding: 0 10px;
    color: var(--color-text_secondary);
    min-width: 14px;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
}

.badge-array {
    display: flex;
    flex-wrap: nowrap;
}

.badge-margin {
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.badge-hint {
    position: absolute;
    z-index: 999;
    padding: 10px;
    border: 1px solid var(--color-border);
    border-radius: 6px;
    box-shadow: var(--shadow-wide);
    background-color: white;
    font-size: 14px;
    color: var(--color-text_secondary);
    min-width: 300px;
    font-weight: normal;
    text-align: left;
}

.badge-background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.2;
}

.badge-icon {
    display: block;
    width: 14px;
    height: 14px;
    margin-right: 8px;
    margin-left: -4px;
}

.badge-value {
}
