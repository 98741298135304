.faq {

}

.faq-entry {
  border-bottom: 1px solid var(--color-border);
  margin-bottom: 20px;
  margin-left: -20px;
  margin-right: -20px;
  padding: 0px 20px 20px 20px;
}

.faq-entry-header {
  display: flex;
  justify-content: space-between;
}

.faq-entry-header-title {
  margin-bottom: 20px;
}

.faq-entry-header-actions {

}

.faq-entry:last-child {
  margin-bottom: 0;
}

.faq-entry-title {
  margin-bottom: 20px;
}

.faq-entry-text {
  
}