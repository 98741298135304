.productunits {
}

.productunits-localeSwitch {
  width: 100px;
  margin-bottom: 20px;
}

.productunits-adminActions {
  margin-bottom: 20px;
}

.productunits-dangerPopup-message {
  margin-bottom: 20px;
}

.productunits-actions {
  margin-top: 20px;
}

.productunits-groupForm {
  margin-bottom: 20px;
}
