.mediaitem {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 5px;
    transition: box-shadow 0.3s;
    border: 1px solid var(--color-border);
    border-radius: 6px;
    box-sizing: border-box;
}

.mediaitem-inherited {
    border-color: var(--color-inherited);
}

.mediaitem-inherited:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    background-color: var(--color-inherited);
    border-radius: 4px;
    z-index: 1;
    opacity: 0.2;
}

.mediaitem-cover:after {
    position: absolute;
    content: 'Cover';
    padding: 2px 4px;
    bottom: 0;
    right: 0;
    font-weight: bold;
    font-size: 14px;
    color: var(--color-primary);
    background-color: #ffe2e2;
    border-radius: 4px 0 4px 0;
}

.mediaitem:hover {
    cursor: pointer;
}

.mediaitem-img-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%;
    border-radius: 6px;
    overflow: hidden;
    box-sizing: border-box;
    background-color: var(--color-foreground);
}

.mediaitem-img-sorting {
    box-shadow: var(--shadow-wide);
    border-radius: 10px;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.mediaitem-icon-wrapper {
    background-color: rgba(244, 130, 130, 0.1);
}

.mediaitem-img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.mediaitem-fileName {
    font-size: 14px;
    max-width: 80%;
    color: var(--color-primary);
    border-radius: 6px;
    padding: 4px 10px;
    margin-top: 10px;
    display: inline-block;
}

.mediaitem-icon {
    width: 40px;
    height: 40px;
}

:root {
    --color-mediaItemActionsBG: rgba(255, 255, 255, 0.5);
}

[data-theme='dark'] {
    --color-mediaItemActionsBG: rgba(0, 0, 0, 0.5);
}

.mediaitem-actions {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: var(--color-mediaItemActionsBG);
    border-radius: 4px;
    z-index: 1;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.mediaitem-actions-delete {
    width: 20px;
    height: 20px;
}

.mediaitem-actions-selector {
    position: absolute;
    top: 10px;
    left: 10px;
}
