.list {
    margin-left: -20px;
    margin-right: -20px;
    overflow-x: auto;
    overflow-y: auto;
    color: var(--color-text_primary);
}


@media only screen and (max-width: 1200px) {
    .list {
        max-height: none !important;
    }
}

.list-actionsBar {
    padding: 20px 0;
    display: flex;
}

.list-actionsBar-check {
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.list-table {
    width: 100%;
    border-collapse: collapse;
}

.list-table-tr-head {
    position: sticky;
    top: 0;
    width: 100%;
    height: 50px;
    z-index: 2;
    box-shadow: 0 1px var(--color-border);
    background-color: var(--color-foreground);
}

.list-table-tr-head-contrast {
    background-color: var(--color-background_light);
}

.list-table-th {
    text-align: left;
    white-space: nowrap;
    padding-right: 20px;
    color: var(--color-text_secondary);
}

.list-table-th-index {
    padding-right: 20px;
}

.list-table-th-check {
}

.list-table-th-content {
    display: flex;
}

.list-table-th-empty {
    width: 20px;
}

.list-table-th-sort {
    display: flex;
    align-items: center;
    margin-left: 6px;
}

.list-table-th-sort-remove {
    width: 10px;
    height: 10px;
    background-color: var(--color-background);
    padding: 6px;
    border-radius: 4px;
    fill: var(--color-text_tertiary);
    margin-left: 6px;
}

.list-table-th-sort-remove:hover {
    cursor: pointer;
}

.list-table-th-sort-indicator {
    width: 9px;
    height: 9px;
    fill: var(--color-text_secondary);
}

.list-table-th-title {
    display: flex;
}

.list-table-th-title:hover {
    cursor: pointer;
}

.list-table-tr {
    position: relative;
    height: var(--size-table_rowHeight);
    border-bottom: 1px solid var(--color-border);
    transition: background-color 0.1s;
}

.list-table-tr-disabled:after {
    content: '';
    background-color: var(--color-background);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    opacity: 0.5;
    margin-bottom: -1px;
}

.list td:first-child,
.list th:first-child {
    padding-left: 30px;
}

.list-table__widthDrag td:first-child,
.list-table__widthDrag th:first-child {
    padding-left: 40px;
}

.list-table-tr__linked:hover {
    cursor: pointer;
    background-color: var(--color-background_light);
}

.list-table-tr__compact {
    height: var(--size-table_rowHeight_compact);
}

.list-table-td a {
    display: block;
    height: 100%;
    text-decoration: none;
    color: var(--color-text_primary);
}

.list-table-td {
    position: relative;
    height: 100%;
    vertical-align: middle;
    white-space: nowrap;
}

.list-table-td-limited {
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-table-td-index {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 30px;
    box-sizing: border-box;
    height: 100%;
    background-color: var(--color-background_light);
    color: var(--color-text_tertiary);
    font-size: 12px;
    font-family: 'Inconsolata', monospace;
}

.list-table__widthDrag .list-table-td-index {
    width: 46px;
}

.list-table-td-index-drag {
    fill: var(--color-text_tertiary);
    width: 8px;
    margin-right: 10px;
}

.list-table-td-index-drag:hover {
    cursor: grab;
}

.list-table-td-flexWrapper {
    display: flex;
}

@media only screen and (max-width: 1200px) {
    td,
    th {
        padding: 10px;
    }
}

.list-table-td-clipboard {
    display: none;
    height: 22px;
    border-radius: 4px;
    padding: 0;
    margin-left: 6px;
}

.list-table-td-flex:hover .list-table-td-clipboard {
    display: inline-flex;
}

.list-table-td-clipboard .button-icon {
    width: 14px;
    height: 14px;
}

.list-table-td-flex {
    display: flex;
    align-items: center;
    padding: 0 20px 0 0;
    white-space: nowrap;
}

.list-table-td-newTabLink {
    display: flex;
    align-items: center;
    padding-right: 20px;
}

.list-table-td-newTabLink-icon {
    width: 20px;
}

.list-table-td-link {
    color: var(--color-blue)
}

.list-table-td-badge {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 6px;
}

.list-table-td-icon {
    width: 18px;
    height: 18px;
    margin-left: 10px;
    vertical-align: middle;
}

.list-table-td-monoSpaced {
    cursor: text;
    display: inline-block;
    font-family: 'Inconsolata', monospace;
    background-color: var(--color-stage);
    padding: 2px 4px;
    border-radius: 4px;
    color: var(--color-text_secondary);
}

.list-table-td-monoSpaced a {
    color: var(--color-text_secondary);
}

.list-table-td-input {
    padding-right: 10px;
    min-width: 160px;
    height: 40px;
}

.list-table-td-selector {
    position: relative;
    height: 100%;
    width: 60px;
    box-sizing: border-box;
}

.list-table-td-img {
    width: var(--size-table_imgSize);
    height: var(--size-table_imgSize);
    vertical-align: middle;
    padding: 10px 10px 10px 0;
}

.list-table-td-img-img {
    display: block;
    width: var(--size-table_imgSize);
    height: var(--size-table_imgSize);
    object-fit: contain;
    border-radius: 6px;
    border: 1px solid var(--color-border);
    animation: imgLazyFadeIn 0.5s;
}

@keyframes imgLazyFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.list-table-td-img-imgPlaceholder {
    display: block;
    width: var(--size-table_imgSize);
    height: var(--size-table_imgSize);
    padding: 10px;
    box-sizing: border-box;
    object-fit: contain;
    border-radius: 6px;
    border: 1px solid var(--color-border);
}

.list-table-tr__compact .list-table-td-img,
.list-table-tr__compact .list-table-td-img-img,
.list-table-tr__compact .list-table-td-img-imgPlaceholder {
    width: var(--size-table_imgSize_compact);
    height: var(--size-table_imgSize_compact);
}

.list-table-tr__compact .list-table-td-img-imgPlaceholder {
    padding: 4px;
}

.list-table-td-status {
    padding-right: 20px;
}

.list-table-td-status-badge {
    display: inline-block;
}

.list-table-td-actions {
    text-align: center;
    padding-right: 20px;
}

.list-table-td-actions-icon {
    width: 16px;
    margin: 0 auto;
    padding: 10px 4px;
    border-radius: 6px;
    transition: background-color 0.125s;
}

.list-table-td-actions-icon:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
}

.list-table-td-actions-icon:active {
    background-color: rgba(0, 0, 0, 0.1);
}

.list-table-td-actions-actionsBox {
    position: fixed;
    background-color: white;
    border-radius: 6px;
    box-shadow: var(--shadow-wide);
    z-index: 999;
    overflow: hidden;
}

.list-table-td-actions-actionsBox-action {
    font-size: 14px;
    padding: 10px 20px;
    border-bottom: 1px solid var(--color-border);
    transition: background-color 0.125s;
}

.list-table-td-actions-actionsBox-action:last-child {
    border: none;
}

.list-table-td-actions-actionsBox-action:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
}

.list-table-td-actions-actionsBox-action:active {
    background-color: rgba(0, 0, 0, 0.1);
}

.list-table-td-percentage {
    display: flex;
    align-items: center;
}

.list-table-td-percentage-value {
    margin-right: 10px;
}

.list-table-td-percentage-bar {
    height: 10px;
    width: 100%;
    border-radius: 10px;
    background-color: var(--color-background_light);
}

.list-table-td-percentage-bar-fill {
    background-color: var(--color-blue);
    height: 100%;
    border-radius: 10px;
}

.list-table-td-na {
    color: var(--color-text_tertiary)
}

.list-footerButton {
    margin: 20px 20px 0 20px;
}

.list-warning {
    width: 20px;
}

.list-orderNumber {
    display: flex;
    align-items: center;
}

.list-orderNumber:hover {
    cursor: pointer;
}

.list-orderNumber-isSplitOrder {
    width: 14px;
    height: 20px;
    margin: 0 10px;
}

.list-orderNumber-references {
    padding: 5px;
    border-radius: 6px;
    background-color: var(--color-foreground);
    border: 1px solid var(--color-border);
    display: flex;
}

.list-orderNumber-references:hover {
    cursor: pointer;
}

.list-orderNumber-references > .list-orderNumber-orderNumber {
    margin: 5px;
}

.list-orderNumber-orderNumber {
    color: var(--color-inherited);
    background-color: rgba(142, 130, 239, 0.15);
}