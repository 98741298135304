.variants {
}

.variants-actions {
  display: flex;
  justify-content: space-between;
  margin-top: -1px;
}

.variants-popup-header {
  display: flex;
}

.variants-popup-header-title {
  font-weight: bold;
  margin-bottom: 20px;
}

.variants-popup-searchbar {
  margin-top: 20px;
  margin-bottom: 20px;
}

.variants-popup-actions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.variants-popup-actions-2 {
  justify-content: space-between;
}

.variants-variantOptions {
  display: flex;
}

.variants-variantOption {
  background-color: var(--color-background);
  border-radius: 4px;
  margin-right: 10px;
  padding: 4px 10px;
}

.variants-bulkActions {
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
