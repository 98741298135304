.loyaltyPointProcessingConfig {
    width: 100%;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid var(--color-border);
    padding: 20px;
    box-shadow: var(--shadow-minimal);
    background-color: var(--color-foreground);
    display: flex;
    align-items: center;
}

.loyaltyPointProcessingConfig-handle {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.loyaltyPointProcessingConfig-handle-button {
    width: 10px;
}

.loyaltyPointProcessingConfig-inputs {
    width: 100%;
}

.loyaltyPointProcessingConfig-toggle {
    align-self: flex-end;
    margin-bottom: 8px;
    margin-left: 10px;
    margin-right: 20px;
}

.loyaltyPointProcessingConfig-delete {
    align-self: flex-end;
}