.dropdownMenu-wrapper {
    position: relative;
    align-self: flex-end;
}

.dropdownMenu-title-wrapper {
    display: flex;
    margin-bottom: 10px;
}

.dropdownMenu {
    position: relative;
    border: 1px solid var(--color-border);
    border-radius: 6px;
    font-family: 'Sofia Pro', sans-serif;
    font-size: 14px;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 30px;
    height: 40px;
    box-shadow: var(--shadow-minimal);
    background-color: var(--color-input_background);
    color: var(--color-text_primary);
    display: flex;
    align-items: center;
    white-space: nowrap;
    box-sizing: border-box;
}

.dropdownMenu:hover {
    background-color: var(--color-background_light);
    cursor: pointer;
}

.dropdownMenu:active {
    background-color: var(--color-background);
}

.dropdownMenu-value {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.dropdownMenu-icon {
    position: absolute;
    top: calc(50% - 2px);
    right: 10px;
    width: 10px;
}

.dropdownMenu-alert {
    border-color: var(--color-danger);
    color: var(--color-danger);
}

.dropdownMenu-inputEmbedded {
    border-radius: 0 6px 6px 0;
    box-shadow: none;
    border: 1px solid var(--color-border);
    border-right: none;
    width: auto;
    margin-right: -8px;
    height: auto;
}

.dropdownMenu-disabled {
    background-color: var(--color-background);
}

.dropdownMenu-inherited {
    border-color: var(--color-inherited);
    color: var(--color-inherited);
}

.dropdownMenu-menu {
    background-color: var(--color-foreground);
    border-radius: 6px;
    padding: 5px;
    border: 1px solid var(--color-border);
    box-shadow: var(--shadow-wide);
    animation: fadeIn 0.3s;
    max-width: 320px;
    box-sizing: border-box;
}

@keyframes fadeIn {
    from {
        margin-top: 20px;
        opacity: 0;
    }
    to {
        margin-top: 0px;
        opacity: 1;
    }
}

.dropdownMenu-menu-search {
    margin: 5px;
}

.dropdownMenu-menu-items {
    max-height: 300px;
    overflow: scroll;
}

.dropdownMenu-menu-item {
    background-color: var(--color-background_light);
    display: inline-flex;
    padding: 6px 10px;
    border-radius: 4px;
    margin: 5px;
    transition: background-color 0.1s;
    color: var(--color-text_secondary);
    white-space: nowrap;
}

.dropdownMenu-menu-item:hover {
    background-color: var(--color-background);
    cursor: pointer;
}

.dropdownMenu-menu-item__highlighted {
    background-color: rgba(13, 124, 206, 0.1);
    color: var(--color-blue);
}

.dropdownMenu-menu-item__highlighted:hover {
    background-color: rgba(13, 124, 206, 0.2);
}

.dropdownMenu-menu-item__selected {
    background-color: rgba(35, 178, 85, 0.1);
    color: var(--color-success);
}

.dropdownMenu-menu-item__selected:hover {
    background-color: rgba(35, 178, 85, 0.2);
}