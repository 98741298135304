.salesOverviewElement {
    flex: 1;
    text-align: center;
}

.salesOverviewSubElement-icon {
    width: 20px;
    fill: var(--color-text_tertiary);
}

.salesOverviewElement-value {
    font-size: var(--size-text_veryLarge);
    display: flex;
    align-items: center;
    justify-content: center;
}

.salesOverviewElement-sub {
    font-size: var(--size-text_small);
    color: var(--color-text_secondary);
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
}

.salesOverviewElement-sub-ratio {
    margin-right: 10px;
}