.downloads {
  margin-top: 20px;
}

.downloads-dropzone {
  margin-top: 20px;
}

.downloads-actions {
  margin-top: 20px;
  border-top: 1px solid var(--color-border);
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px;
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
}

.downloads-assets {
  margin-top: 20px;
}

.downloads-asset-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.downloads-asset {
  display: inline-flex;
  align-items: center;
  border: 1px solid var(--color-border);
  border-radius: 6px;
  background-color: var(--color-background_light);
  padding: 10px;
  margin-right: 10px;
}

.downloads-asset-icon {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.downloads-asset-icon-img {
  width: 20px;
  height: 20px;
} 

.downloads-asset-title {
}

.downloads-asset-info {
  margin-left: 20px;
  font-size: 14px;
  color: var(--color-text_secondary)
}